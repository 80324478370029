import React, { useState, useEffect } from "react";
import SectionHeader from 'views/med/SectionHeader';
import script_model from "models/script_model";
import { Button, Form, Icon, List } from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";
import currency from 'currency.js'
import { ExclamationIcon, InformationCircleIcon } from "@heroicons/react/outline";
import Alert from "views/NUI/Alert";
import { Link, useLocation, useParams } from "react-router-dom";
import gtm from "../../../xAppLib/providers/gtm";
import WaitTimeConsultBook from '../../public/waittime/components/consult_book'
import med_model from "../../../models/med_model";
import cart_model from "../../../models/cart_model";
import { useProfile } from "../../../xAppLib/Hooks";
import SIcon from "../../NUI/StreamlineIcons/SIcon";
import { promo } from "../../icann/cbls/cbls_data";
import { content as guidelines } from "../../../xAppLib/AHPRA/BoardGuidelines";
import CopayPopup from "../MHCPCopayPopup";
import user_model from "../../../models/user_model";
import onepass_model from "../../../models/onepass_model";
import { TRIPLE_ZERO } from "../../../strings";

function DocConsCost({ med, cart, onepassEligible }) {
	const [pathoCart, setPathoCart] = useState(null);
	const location = useLocation();

	const patho_id = med?.need_patho;
	useEffect(() => {
		patho_id && med_model.load_med(patho_id).then(data => {
			const patho_cart = new cart_model({...cart, script_type: 'pathoreq'});
			patho_cart.add_med(data);
			setPathoCart(patho_cart);
		})
	}, [patho_id]);

	const med_cost = cart.content()?.total?.value || 0;

	if (pathoCart) {
		const patho_cost = pathoCart.content()?.total?.value || 0;
		const total = currency(med_cost + patho_cost).value || 0;

		return (<>
			<p>
				The total cost of this consultation is ${total}.{" "}
				This includes an initial blood test for ${patho_cost} and then a long consultation with a doctor for ${med_cost}.
			<br/>
			Each fee will appear as ‘pending’ on your credit card and only be charged once completed.</p>
		</>);
	}

	if (med.req_tp?.startsWith("dcs_")) {

		const docConsLink = (<>If you have additional requests, you should request a <Link to="/med/DocConsCov">General Consultation</Link>.</>)
		
		const MAIN = {
			dcs_cert: `This is a request for a consultation with a doctor for a multi-day medical or carer's certificate.`,
			dcs_scr: 'This is a request for a prescription for a single type of medication that you have previously taken.',
			dcs_refr: 'This is a request for a doctor consultation for a specialist referral.',
			dcs_patho: 'This is a request for a doctor consultation for a pathology referral.',
		}
		const DISCLAIMER = {
			dcs_cert: <>
				The doctor can only discuss requests for multi-day medical certificates or carer's certificates during this consultation and cannot backdate certificates.
				<br />
				<br />
				{docConsLink}
			</>,
			dcs_scr: <>
				{docConsLink}
				<br />
				<br />
				Prescriptions will only be issued if deemed appropriate by our doctors. Our doctors are unable to prescribe strong pain relief medication, sedation medication or medications of addiction.
			</>,
			dcs_refr: <>
				The doctor can only discuss a single specialist referral and cannot issue prescriptions during this consultation.
				<br />
				<br />
				{docConsLink}
			</>,
			dcs_patho: <>
				The doctor can only discuss a single pathology referral and cannot issue prescriptions during this consultation.
				<br />
				<br />
				{docConsLink}
			</>,
		}
		return (
			<>
				<p>{
					MAIN[med.req_tp] ||
						'This is a request for a telehealth consultation with an Australian-registered doctor.'
				}</p>
				<p>The consultation cost {onepassEligible && onepass_model.isValidOnepassItem(med) ? `for OnePass members ` : ''}is ${med_cost} and will appear as ‘pending’ on your card. It will only be charged once your consultation is complete.</p>
				<p>{DISCLAIMER[med.req_tp]}</p>
				<p>We treat all patients with respect, and ask that you treat our team with respect in return.</p>
			</>
		)
	}

	return (<>
		<p>{ 
			location.state?.c === 'med-cert-multiple' ? 'Multiple day medical certificate requests require a telehealth consultation with a doctor.' 
			: `This is a request for a telehealth consultation with an Australian-registered doctor${med.req_tp==='dc_fertility' ? ' for a referral to Adora Fertility Clinics': ''}.` 
		}
		<br/>The consultation cost {onepassEligible && onepass_model.isValidOnepassItem(med) ? `for OnePass members ` : ''}is ${med_cost} and will appear as ‘pending’ on your card. It will only be charged once your consultation is complete.</p>
	</>);
}

export function WithInfoIcon({children, color = 'blue'}) {
	return (<div data-testid="text-content" className="flex">
			<Icon name='info circle' color={color} style={{marginRight: '.75rem', height: 'fit-content'}}/>
			<div>{children}</div>
		</div>
	)
}

export function RestrictedMedsPrompt(){
	return <Alert warning>
		<p>InstantScripts is a responsible prescriber and does not prescribe controlled, addictive or Schedule 8 medications such as Diazepam, Endone, Lyrica, Stilnox, Zolpidem, Codeine painkillers (including Panadeine Forte), Benzodiazepine (including Valium / Diazepam, Temazepam, Oxazepam, Alprazolam), Duromine, Tramadol, Gabapentin, Pregabalin, Seroquel, Steroids, Vyvanse, Modafinil, Dexamphetamine, Ritalin, Concerta or Sofradex ear drops</p>
	</Alert>
}

const HDR = (props) => {
	const {
		med_data, script_type, req_type, store, cart, free_consult,
		PARTN_PHARM, is_prep, home_delivery_available, visible, is60day,
		medbuy_only, enable_treatment_plan, formData, enable_qualcons, is_qualcons, is_spon, onepassEligible
	} = props
	const [profile, profiles] = useProfile();
	const activeProfile = profiles[formData?.profile || 0] || profile;

	const location = useLocation();
	const hdr_warning = med_data?.med_warn || location?.state?.hdr_warn

	const days_limit = Number(med_data && med_data.days_limit)

	const the_pharm = PARTN_PHARM && `your pharmacy - ${store.send_to_pha?.nm ||''}, ${store.send_to_pha?.adr ||''}` || 'a pharmacy of your choice'

	const med_id = props && props.med_data && props.med_data.m
	const patho_data = med_id && props.dataProp && props.dataProp.cat_show_list && props.dataProp.cat_show_list[med_id] && props.dataProp.cat_show_list[med_id]
	const patho_description = patho_data && patho_data.d, patho_additional = patho_data && patho_data.x
	const selected_items_keys = props?.formData?.selected_items && Object.keys(props.formData.selected_items).filter(e => props.formData.selected_items[e]) || []
	const additional_instructions = props?.med_data?.items?.filter(e => e && e.instructions && selected_items_keys.includes(e.key)).map(e => e.instructions).join('. ')
	const carer_cert = med_data?.key === "med-carer";

	const copay = !!med_data.xtra?.copay

	const loc = useLocation()
	const hide_content = loc.pathname.includes('/m/pharm/online/');

	if (!visible)
		return null


	const headings = [],
		alerts = [],
		content = [],
		info = [],
		postcontent = [];
	let contentHeading = '';
	
	if (app.runtime.mode == 'icann' && ['DocConsCov', 'DocConsFU', 'DocConsWL', 'DocConsRef'].includes(med_id)) {
		alerts.push(<Alert key={'warn1'} header={"Warning: This is not a Benicura Consultation"} warning   className='mb-8 mt-8'
					content={<>
						<p>You are about to request a medical consultation with a doctor, not a specialist Benicura consultation.</p>
						<Button as={Link} primary to='/med/DocConsICann'>Request a Benicura consultation</Button>
						</>}
					/> );
	}

	if (['medcons', 'medbuy', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick'].includes(script_type) && !['medcert'].includes(req_type)) {
		postcontent.push(
			<Alert warning className='mt-4' key={'ahpra-med-warning'}>
				<p>This online prescription service is intended for patients who are unable to access their regular GP.</p>
			</Alert>
		);
	}

	if (med_data.IL) {
		info.push(<Alert key={'in0'} ext
						header = {<a href={med_data.IL} target="_blank" className="flex justify-start text-black">
						Learn more about {med_data.itm_tp === 'subcons' ? 'Treatment Plans' : med_data.name}</a>}
					/>)
	}

	if(app.settings.is_cbls) {
		alerts.push(<Alert key={'promo'} className='mb-8' content={promo}/> );
	}

	if (script_type === 'subcons') 
	{
		info.push(
			<Alert info key={'treatment-plan'} header="Regular Medication Delivery Straight to Your Home">
				<p>{req_type==='doccons_fu'?'Follow-Up':'Telehealth'} Consultations for Treatment Plans cost ${med_data.mc_pr || 49}.</p>

				{req_type!=='doccons_fu' && <p>A Treatment Plan provides regular medication delivery to your home for up to 6 months.</p>}

				<p>Medication and delivery costs are additional and will vary depending on the medication and your shipping address.</p>
				<p>Your consultation fee will appear as ‘pending’ on your credit card and only be charged once your consultation is complete.</p>
				<p>Medication will only be issued if deemed appropriate by our doctors. Complete the digital consultation below to request a Treatment Plan.</p>
			</Alert>
		);
	}

	// if (med_data.l=="-LJlvQo2RunU9oi9abk5") {
	// 	info.push(<div key={'bpm'} className="bg-blue-50 p-4 rounded shadow mb-4">
	// 		<h4>It's important to monitor your blood pressure.  <a href='products/bpm' target="_blank" className="font-normal -flex -justify-start text-blue-800"> Purchase blood pressure monitor now. <Icon name='external alternate' style={{fontSize: '0.8em', marginRight:'0.5em'}} /> </a></h4></div>)
	// }


	if (app.settings.iscr && script_type == 'doccons') {
		if (['mhcp', 'mhcprev', 'docconswl', 'docconswli', 'docconswlo', 'icann', 'doccons_fu'].includes(req_type)) {
			info.push(<Alert key={'in01'} wait header="Average call back time is within 24 hours."/>);
		} else if (app.site_status.wr_stat?.use_new) {
			info.push(<WaitTimeConsultBook key={'in01'} basic/>);
		} else if (['icann', 'ihair', 'docconssc', 'docconswl', 'docconswli', 'docconswlo', 'dc_av', 'doccons_fu', 'docord'].includes(req_type)) {
			// Nothing. Before predictive wait times, these types didn't show a wait time. If we've hit this point,
			// predictive wait times are off for some reason, so we'll just show nothing for these types.
		} else if (app.site_status.WR_wait_msg?.length > 1) {
			info.push(<Alert key={'in01'} wait header={app.site_status.WR_wait_msg}/>);
		} else if (app.site_status.WR_wait_max_hrs > 0) {
			info.push( <Alert key={'in01'} 
							  header="Request a phone consultation with a Doctor now." 
							  content={<>Current doctor call back time is within the
								next {app.site_status.WR_wait_max_hrs} hour{app.site_status.WR_wait_max_hrs > 1 && 's' || ''}
								{app.site_status.WR_wait_avg_mins > 0 &&
									<small> (typically within {app.site_status.WR_wait_avg_mins} minutes)</small>}.
							  </>}
						/>
			);
		}
	}





	{/* if (app_use && !props.free_consult && script_type!='doccons') {
		content.push(<div>
			<br />
			<Link to='/med/DocConsCov'>To get your script with a bulk billed service, please click here to speak to one of our doctors over the phone.</Link>
			<br />
		</div>)
	}
	*/}


	if (script_type=='doccons') {

		if(cart.content()?.total?.value>0 && !copay){
			content.push(<DocConsCost key={'cndc2'} med={med_data} cart={cart} onepassEligible={onepassEligible} />);
			['docconscov', 'dcs_scr'].includes(req_type) && postcontent.push(<RestrictedMedsPrompt key='restricted-meds-prompt' />);
		}


		if (app.runtime.app=='iscr' && ['mhcp', 'mhcprev'].includes(req_type) ) {
				if (copay) {
					content.push(
						<React.Fragment key="mhcp">
							<p>This consultation is for a Mental Health Care Plan or Mental Health Care Plan Review. The doctor will determine which is right for you during your call.</p>
							<p>These consultations are eligible for Medicare rebates with an out-of-pocket cost of {currency(med_data.xtra.copay).format()}. <CopayPopup config={med_data.xtra} content={<span className='underline'>See how our pricing works here.</span>}/></p>
							<p>If eligible our doctors can refer you to our partners My Mirror for a telehealth psychologist consultation.</p>
							<p>However, if you would prefer a face-to-face consult with a psychologist, please ensure that you have the name and email address of the clinic or psychologist handy when the doctor calls so we can add it to the referral.</p>
						</React.Fragment>
				);
				} else 
					content.push(
						<React.Fragment key="mhcp">
							<p>This consultation is for a Mental Health Care Plan. Medicare requires that it be done via video consultation.</p>
							<p>Our doctors are unable to prescribe any medication during this consultation. If you require medication, please request a standard telehealth consultation.<br/>
							If eligible our doctors can refer you to our partners My Mirror for a telehealth psychologist consultation. However, if you would prefer a face-to-face consult with a psychologist, please ensure that you have the name and email address of the clinic or psychologist handy when the doctor calls.</p>
						</React.Fragment>
				);

		}

	}

	if (app.acl.app_use && script_type!='doccons' && app.runtime.mode!='caia')
	{
		!free_consult && cart.MEDCONS_PRICE>0 && !is_qualcons && content.push(
			<p key={'cn51'}>
				The cost of this {
				req_type=='medcert' && (carer_cert ? "carer’s certificate" : "medical certificate")
				|| script_type=='docrefr' && `specialist referral`
				|| script_type=='pathoreq' && `pathology referral`
				|| ['exprcons', 'exprbuy', 'exprclick', 'qualbuy', 'qualclick'].includes(script_type) && 'short consultation'
				|| `single prescription`
			} {
				onepassEligible && onepass_model.isValidOnepassItem(med_data)
					? `for OnePass members is ${onepass_model.discountedPriceString(cart.MEDCONS_PRICE)}`
					: `is $${(cart.MEDCONS_PRICE * 1).toFixed(0)}`
			}.
				{is60day && <>{" "}This medication is available as a 60-day prescription. This means you receive twice the medication for the cost of a single prescription.</>}
			</p>
		);

		if (enable_qualcons) {
			content.push(
				<React.Fragment key="qualcons-content">
					<p>{guidelines}</p>
					This is a request for a single prescription. You can choose to have your prescription:
					<ul style={{ listStyleType: 'disc', paddingLeft: 30 }}>
						<li>Sent as an eScript to your phone and email, or</li>
						<li>Delivered as medication to you</li>
					</ul>
					<p>
						An Australian-registered doctor will review your request.<br/>
						Prescriptions will only be issued if deemed appropriate by our doctors.<br/>
						We treat all patients with respect, and ask that you treat our team with respect in return.
					</p>
				</React.Fragment>
			);
		}


		(req_type=='medcert' || ['docrefr'].includes(script_type)) && content.push(
			<p key={'cn5'}>If approved by a doctor, {
				req_type=='medcert' && `your medical certificate will be sent to your email`
				|| script_type=='docrefr' && 'we will email your specialist referral to you'
				|| `we will email your prescription directly to the pharmacy`
			}.</p>
		);

		["pathoreq"].includes(script_type) &&
            content.push(
                <React.Fragment key={"cn5"}>
                    <p>If your pathology referral is approved by a doctor:</p>
                    <List ordered>
                        <List.Item>
                            You will receive an SMS with a link. The link will
                            take you to a webpage that contains a barcode.
                        </List.Item>
                        <List.Item>
                            Please show the barcode at any partner collection
                            centre.&nbsp;
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    gtm.log("", "outbound", {
                                        link: e.target.href,
                                    });
                                    window
                                        .open(e.target.href, "_blank")
                                        .focus();
                                }}
                                href="https://pathologylocations.com.au/locations/"
                                className="underline"
                                target="patholab"
                            >
                                Find a partner collection centre near you.
                            </a>
                        </List.Item>
                        <List.Item>
                            When your pathology results are ready, they can be
                            viewed in your InstantScripts account or a doctor
                            will request a follow-up consultation if necessary.
                        </List.Item>
                    </List>
                    <p>
                        If you would prefer to use another pathology provider,
                        you will need to download or print your pathology
                        request form and bring it to the collection centre of
                        your choice.
                    </p>
                    <p>
                        We will notify you of your results when they are
                        received and processed.
                    </p>
                </React.Fragment>
            );

		['medcons', 'medbuy', 'exprcons', 'exprbuy'].includes(script_type) && !['medcert', 'qualcons', 'qualbuy'].includes(req_type) &&
			content.push(<span key={'cn3'}>
				{ medbuy_only &&
					<>
						Your requested medication will be delivered directly to your shipping address
					</>
					|| home_delivery_available &&
					<>
						You can choose to have your prescription:
						<ul style={{listStyleType:'disc', paddingLeft: 30}}><li>Sent as an eScript to your phone and email for dispensing at any pharmacy, or</li>
						<li>Delivered as an order for medication directly to your shipping address (if available)</li></ul>
						{/*
						Choose <b>Home Delivery with express shipping</b> from {PARTN_PHARM && 'the' || 'our partner'} pharmacy
						{!medbuy_only && <>, or have an <b>eScript sent to your phone</b> for fulfilment at any pharmacy. If an eScript is unavailable, we will email your prescription directly to {the_pharm}.</>}.
						*/}
					</>
					|| app.runtime.partn_pharm_detmsg &&
						<><b>{app.runtime.partn_pharm_detmsg}</b></>
					|| ['medcert'].includes(req_type) &&
						<>If approved by a doctor, your medical certificate will be sent to your email.</>
					|| <>Your prescription will be sent as an eScript to your phone and email for dispensing at any pharmacy.</>
					// || <>Your script will be sent for <b>Collection in {the_pharm}</b>.</>
				}
			</span>)

		if (req_type=='medcert')
			content.push(<React.Fragment key={'cn6'}>
				<p>{carer_cert ? <>The certificate will be issued for today only and cannot be backdated. If you require a carer’s certificate for multiple days, you will require a <Link className='link' to='/med/ShortConsMC'>telehealth consultation</Link>.</> : <>The medical certificate will be issued for today only and cannot be backdated. If you require a medical certificate for multiple days, you will require a <Link className='link' to='/med/ShortConsMC'>multi-day medical certificate telehealth consultation</Link>.</>}</p>
			</React.Fragment>)
			
		if (script_type=='pathoreq' && patho_additional)
			content.push(<React.Fragment key={'cn6'}><span>{patho_additional}</span><br/></React.Fragment>)

		if (script_type=='pathoreq' && additional_instructions)
			content.push(<React.Fragment key={'cn7'}><span>{additional_instructions}</span><br/></React.Fragment>)
			
	}

	if (script_type=='pathoreq' && activeProfile?.full_address?.state === 'SA')
		content.push(
			<React.Fragment key={'cn8'}>
				<span>
					SA Pathology is not currently bulk billing our pathology forms, we recommend patients in South Australia use an alternative pathology lab such as Clinpath, ACL or 4cyte.
				</span>
				<br/>
			</React.Fragment>
		);

	if (['medbuy', 'medclick','exprbuy', 'exprclick','qualbuy', 'qualclick'].includes(script_type) && app.runtime.mode!='caia' && formData.medication) {
		content.push(<div key={'cn10'}>
		<p><strong>There is a fee of {currency(cart.MEDBUY_PRICE).format()} for {cart.MEDCONS_PRICE==0 && !is_prep && app.runtime.app!='icann' && 'the consultation, and' || ''} the {cart.want_generic && 'generic' || ''} medication{cart.SHIPPING_PRICE==0 && ', including fast delivery' || ''}. </strong></p>
		
		{cart.has_generic && !cart.want_generic &&
			<>You can request Brand Substitution for a better price.<br/></>
			|| ''
		}
		{!cart.has_concession &&
			<>Concession card holders who have updated their details in My Profile may receive a concession price for medication.<br/></>
			|| ''
		}

			{/*or ${currency(med_data.medbuy_conc_price).format()} for a valid concession card holders)*/}
		</div>)
	}

	// if (script_type!='medbuy' && home_delivery_available && app.runtime.mode!='caia') {
	// 	content.push(<div>
	// 	<p><strong>Choose Home Delivery to get this medication delivered quickly to your shipping address.</strong></p>
	// 	</div>)
	// }

	['medcons', 'medbuy', 'exprcons'].includes(script_type) && !['medcert', 'qualcons'].includes(req_type) && app.runtime.app!='icann' &&
		content.push(
				// <><span key={'cn33'}>For any variations on a prescription's dosage or amount, including repeats, you will require a <Link className='link' to='/med/DocConsCov'>telehealth consultation</Link> with one of our doctors.</span><br/></>,
				<div key={'cn34'}>Prescriptions will only be issued if deemed appropriate by our doctors.</div>
			);

	if (['medcons', 'medbuy', 'medclick', 'exprcons', 'exprbuy', 'exprclick', 'qualcons', 'qualbuy', 'qualclick', 'doccons'].includes(script_type) && !['medcert'].includes(req_type) && !cart.has_medicare) {
		content.push(<React.Fragment key={'cn35'}><p>You require a Medicare card to use this service. Please enter your name details exactly as they appear on your card.</p></React.Fragment>)
		}

	if (['medcons', 'medbuy', 'medclick'].includes(script_type) && app.runtime.app!='icann' && !['medcert'].includes(req_type)) {
		content.push(
			<span key={'cn36'}>If you need to speak to a doctor about the right treatment for you or a child (under the age of 18), please request a <Link className='link' to='/med/DocConsCov'>Telehealth Consultation</Link>.</span>
		)
	}

	if (app.runtime.mode=='caia') {
		content.push(<div key={'cn11'}>
			<h3>Please complete the form below to order your medication. Upon placing your order, if approved by a doctor, your order will either automatically be sent to the pharmacy of your choice or delivered to your address.</h3>
			Please note: there is a $19 fee charged for this service alongside the cost of your medication.
		</div>)
	}

	if (med_data.tp_mid && !enable_treatment_plan && !is_spon) {
		const treatmentPlanConsultProps = {
			to: `/med/${med_data.tp_mid}`,
			state: {
				cons_req_med: { n: `${med_data.name} ${med_data.size || ''}`.trim(), m: med_data.m },
			}
		};
		postcontent.push(
			<Alert key={'treatment-plan'}
				   success
				   header="Do you want to receive this medication regularly?"
				   className='mt-4'
				   content={<div className='text-gray-700'>
					   <p>A Treatment Plan provides regular medication delivery to your home for up to 6 months.</p>
					   <p>No more running out of medication or worrying about repeats.</p>
					   <p>Request a <Link className='link' {...treatmentPlanConsultProps}>Treatment Plan</Link> to take the hassle out of healthcare.</p>
				   </div>}
			/>);
	}

	if (hdr_warning)
		alerts.push(<Alert key={'warn4'} header={hdr_warning} warning icon={ExclamationIcon} className='mb-4'/>)

	if (med_data.instructions)
		alerts.push(<Alert key={'warn5'} header={med_data.instructions} success icon={ExclamationIcon} className='mb-4'/>)


	return (<div className='text-gray-700'>
		{info}
		{headings}
		{alerts}
		{content.length > 0 && !hide_content && (
			<Alert info header={contentHeading}>
				{content}
				<p>{carer_cert ?  TRIPLE_ZERO.replace('If you are', 'If the person you are caring for is') : (req_type!='medcert' && TRIPLE_ZERO)}</p>
			</Alert>
		)}
		{postcontent}
		{hide_content && <Alert info><p>{TRIPLE_ZERO}</p></Alert>}

	</div>)

};

export default HDR;
