import React, { useState, useEffect, useMemo } from "react";

import { Icon, Label, Dropdown } from 'semantic-ui-react'

import moment from 'moment'

import API_service from 'xAppLib/providers/API_service'

import phonecall_model from 'models/phonecall_model'
import Alert from "../../views/NUI/Alert";
import { AsyncButton } from "../../views/UIelems/AsyncButton";
import { useAsync } from "../Hooks/useAsync";
const phone_mask = '0x xxxx x';
// consistent number format with that on the backend, so we can tell which participants are on the call
const country_code_prefix = (ph_num) => ph_num.replace(/^0([23478])/, '+61$1').replace(/^13/, '+6113').replace(/[^\d+]/g, '');

function HangUp({pcd, pcid}) {
	const events = Object.values(pcd?.es || {});

	const on_call = Object.fromEntries(
		events
			.filter(e => e.et === 'call_progress_event' && e.data?.To)
			.map(e => [e.data.To, ['initiated', 'ringing', 'in-progress'].includes(e.data.CallStatus)])
	);

	const anyone_on_call = Object.values(on_call).some(x => x);

	if (!anyone_on_call) return null;

	return <AsyncButton onClick={async () => {
							const consent = !pcd.conf || await app.confirm(
								`Confirm end conference call`,
								`This will end the conference for ALL participants. Are you sure?`
							).then(consent => consent === 'yes');
							return consent && API_service.load_data('phonecall/end_call', {pcid})
						}}
						icon="stop circle outline"
						content={`End ${pcd.conf ? 'Conference' : 'Call'}`}
						color="red"
						basic
	/>;
}
export function ConferenceCall({ pcid, pcd, config }) {
	const {fn: dial, loading: dialling } = useAsync({
		immediate: false,
		fn: (pcid, toNum) => API_service.api_promise('/phonecall/add_to_conference', {pcid, toNum}),
	});

	const events = Object.values(pcd?.es || {});

	const on_call = Object.fromEntries(
		events
			.filter(e => e.et === 'call_progress_event' && e.data?.To)
			.map(e => [e.data.To, ['initiated', 'ringing', 'in-progress'].includes(e.data.CallStatus)])
	);

	const [custom, setCustom] = useState(null);
	const [participant, setParticipant] = useState(null);
	const participants = [
		{value: '', text: '-- select --'},
		...(config.numbers || []),
		...(custom ? [{value: custom, text: 'Other'}] : []),
	].map(opt => ({
		...opt,
		key: opt.value,
		disabled: on_call[opt.value],
		value: country_code_prefix(opt.value),
		text: opt.value ? `${opt.text} (${opt.value})` : opt.text,
	}));

	// no events?
	if (events.length === 0) return null

	// call is completed
	if (pcd?.ccd) return null

	// dr has left the call or hasn't joined yet
	if (!on_call[pcd.frNum]) return null;

	// call seems to be in progress, allow adding additional participants
	return <div className="flex-inline my-2">
		<Dropdown
			placeholder='Add to call'
			clearable
			selection
			disabled={dialling}
			loading={dialling}
			options={participants}
			value={participant}
			onChange={(_, d) => setParticipant(d.value)}
			onAddItem={(_, d) => {
				setCustom(d.value);
				setParticipant(d.value);
			}}
			allowAdditions
			search
		/>
		<AsyncButton icon disabled={!participant} onClick={async () => {
			const consent = await app.confirm(
				`Confirm patient consent`,
				`Are you sure you have the patient's consent to add ${participant} to this call?`
			);
			if (consent === 'yes') {
				if (app.settings.is_local) {
					alert('Not dialling in local mode');
				} else {
					dial(pcid, participant).then(() => setParticipant(null))
				}
			}
		}}>
			<Icon name="phone" /> Dial into call
		</AsyncButton>
	</div>

}

function TwilioError({ event }) {
	const code = event?.ErrorCode;
	const message = event?.ErrorMessage;
	const nice_message = (() => {
		switch (code) {
			// https://www.twilio.com/docs/api/errors
			case '13224':
				return "Invalid phone number";
			case '32205':
			case '13227':
				return "Geo Permission configuration is not permitting call";
			case '20500':
				return "Internal Server Error";
			default:
				return message || "Unknown error";
		}
	})();

	return (
		<Alert error header="Dialler error">
			<p>{!!code && <>(Code {code}) </>}{nice_message}</p>
			{app.acl.is_admin && nice_message !== message && (
				<p className="whitespace-normal">
					<strong>Twilio error</strong><br/>
					{message}
				</p>
			)}
		</Alert>
	);
}

const PhoneCall = props => {
	const [pcid, setPcid] = useState(props?.match?.params?.pcid || props?.pcid);
	const [view, setView] = useState(props?.match?.params?.view || props?.view);
	const [pcd, setPcd] = useState();

	const isConferenceCall = Object.keys(pcd?.who || {}).length > 2;

	const {type, sid, snum, toNum, frNum, mask_pts_phone, partnRef} = props?.match?.params || props

	//	------------------------	------------------------	------------------------

	useEffect(() => {
		if (pcid) {
			return phonecall_model.watch_record(pcid, d => setPcd(d));
		}
	}, [pcid]);

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	const render_call_dets = _ => {
		const error = Object.values(pcd?.es ||{}).find(ev => ev?.data?.ErrorMessage || ev?.data?.ErrorCode) ?? (pcd?.err?.err?.code && {
			data: {
				ErrorCode: String(pcd.err.err.code),
				ErrorMessage: pcd.err.err.moreInfo,
			}
		});

		return <>
					{/*<h2 className='head' >
						{pcd.type} to:{pcd.toNum} from:{pcd.frNum} started {moment(pcd.tm).tz("Australia/Melbourne").format('DD/MM/YY HH:mm:ss')} by {pcd.by?.n}
					</h2>*/}

					<Label as='a' color={!pcd.ccd && 'yellow' || pcd.ccd?.data?.duration > 50 && 'green' || 'red'}  onClick={ _=> setView( view=='compact' && 'full' || 'compact' ) } >
						{isConferenceCall && <Icon name="users" />}
						Call {moment(pcd.tm).tz("Australia/Melbourne").format('DD/MM/YY HH:mm:ss')} by {pcd.by?.n} <br/>
						<Label.Detail>Answ by: {pcd.ccd?.data?.answeredBy}, Dur: {pcd.ccd?.data?.duration} sec</Label.Detail>
						&nbsp; &nbsp; <Icon name={view=='compact' && 'toggle right' || 'toggle down'} />
					</Label>

			{error && <TwilioError event={error.data} />}

			{view!='compact' && pcid && !pcd?.ccd && pcd.by?.u === app.user.uid && (
				<div className="my-2"><HangUp pcid={pcid} pcd={pcd} /></div>
			)}

				{view!='compact' &&
					<ul>
						{Object.values(pcd?.es || {}).filter(ev => ev.data?.To).map(ev => {
							const ph_num = ev.data?.To;
							const who = pcd.who?.[ph_num] || null;
							const icon = { pt: 'user', dr: 'doctor', other: 'code branch' }[who];

							return <li key={ev.tm}>
								{icon && <Icon name={icon} />}
								<b>{moment(ev.tm).tz("Australia/Melbourne").format('HH:mm:ss')}</b> &nbsp;
								{
									ev.et=='call_progress_event' && ev.data.CallStatus=='in-progress' && 'call answered'
									|| ev.et=='call_progress_event' && ev.data.CallStatus=='initiated' && `call initiated (${ev.data.To})`
									|| ev.et=='call_progress_event' && ev.data.CallStatus=='completed' && `call completed dur:${ev.data.Duration} calldur:${ev.data.CallDuration}`
									|| ev.et=='call_progress_event' && ('call '+ev.data.CallStatus)
									|| ev.et=='conference_progress_event' && (<></>)
									|| <>
										{ev.et=='call_answer_event' && 'answered ' || ev.et}
										{`by ${ev.data.AnsweredBy} ${ev.data.RecordingDuration && ('dur: '+ev.data.RecordingDuration) || ''}`}
									</>
								}
							</li>
						})}
					</ul>
				}

				{view!='compact' &&
					pcd.ccd &&
						<b>
							<ul>
								<b>Completed</b>
								<li>Answered by: {pcd.ccd.data.answeredBy}</li>
								<li>Duration: {pcd.ccd.data.duration}</li>
								<li>Started: {moment(pcd.ccd.data.dateCreated).tz("Australia/Melbourne").format('DD/MM/YY HH:mm:ss')}</li>
								<li>Ended: {moment(pcd.ccd.data.endTime).tz("Australia/Melbourne").format('DD/MM/YY HH:mm:ss')}</li>
								{!isNaN(pcd.ccd.data.price) && <li>Cost: USD {-1*pcd.ccd.data.price}</li>}
							</ul>
						</b>
				}

					{/*<pre>{JSON.stringify(pcd.ccd.data, null, 4)}</pre>*/}
				</>
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	return <div className="mb-3" style={{width:'100%', minWidth:'310px'}}>

			{sid && toNum && frNum && (!pcd || pcd.ccd) &&
				<AsyncButton
					basic 
					color='black'
					data-testid='button-phone-call'
					onClick={ async _=> {
						if (partnRef === 'healthengine') {
							await API_service.load_data(
								'partn', 
								{ type: 'he-upd-doc', sid }
							)
						}

												const res = await API_service.load_data(
																'phonecall/start_call', 
																{type: 'doccons_doc_call', sid, snum, toNum, frNum}
														);
												res.res=='ok' && setPcid(res.pcid)
										} }
					>
					<Icon name='call' />
					 Make Call {type} to:{mask_pts_phone ? phone_mask+String(toNum).slice(-3) : toNum} from:{frNum}
				</AsyncButton>
			||
			sid && toNum && frNum && 
				<h4>
					<Icon name='call' />
					 Making Call {type} to:{mask_pts_phone ? phone_mask+String(toNum).slice(-3) : toNum} from:{frNum}
				</h4>
			}

			{sid && toNum && frNum && pcd && <br />}

			{props.conference && <div><ConferenceCall pcid={pcid} pcd={pcd} config={props.conference} /></div>}

			{pcd && render_call_dets()}

		</div>

};

export default PhoneCall;


