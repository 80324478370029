import db_lib from 'xAppLib/libs/db_lib';

const _FRDB_LOC = 'orgs';


const _DET_FIELDS = [
		{
			name: 'name',
			label: 'Org Name',
		},
		{
			name: 'type',
			label: 'Type',
		},
		{
			name: 'verif',
			label: 'Verified',
			type: 'bool',
		},
		{
			name: 'oid',
			label: 'Org ID',
			read_only: true,
		},
		{
			name: 'onum',
			label: 'Org Number',
			read_only: true,
		},
		{
			name: 'desc',
			label: 'Desc',
			valid_not_required: true,
		},
		
		{
			name: 'par_onum',
			label: 'Parent Org',
			type: 'onum',
		},
		{
			name: 'cont_uid',
			label: 'Contact User UID',
			type: 'uid',
			read_only: true,
		},
		{
			name: 'address',
			label: 'Address',
			type: 'address_new',
			mode: 'components',
			components:"full_address",	
		},
		{
			name: 'phone',
			label: 'Phone number',
		},
		{
			name: 'contact',
			label: 'Contact Name',
		},
		{
			name: 'email',
			label: 'Contact email',
		},
		{
			name: 'cre_tm',
			label: 'Created',
			read_only: true,
		},
		{
			name: 'active',
			label: 'Active',
			type: 'bool',
		},
		{
			name: 'notes',
			label: 'Notes',
			type:'textarea',
			valid_not_required: true,
		},
		{
			name: 'xtra',
			label: 'Extra Fields',
			type: 'json',
			valid_not_required: true,
		},
		{
			name: 'conf',
			label: 'Config',
			type: 'json',
			valid_not_required: true,
		},
		{
			name: 'geo',
			label: 'Geo',
			type: 'json',
			read_only: true,
			view_path: 'formatted_address',
		},
		{
			name: 'geo',
			label: 'gMap Link',
			type: 'link',
			read_only: true,
			view_path: 'place_id',
			url_pre: 'https://www.google.com/maps/place/?q=place_id:',
		},
		{
			name: 'hist',
			label: 'Edits history',
			type: 'show_hist',
			read_only: true,
			edit_hide: true,
		},
		// {
		// 	name: 'hist',
		// 	label: 'Edits history',
		// 	type: 'json',
		// 	read_only: true,
		// },

		{
			name: 'raw',
			label: 'raw obj data',
			type: 'popup_json_obj',
			read_only: true,
			edit_hide: true,
		},

	]


const _DET_FIELDS_VIEW = {
			name: 'Org Name',
			desc: 'Desc',
			type: "Org Type",
			// meds: "Org Meds",
			// docs: "Org Docs",
			oid: 'OID',
			onum: 'oNum',
			par_onum: 'Parent oNum',
			cont_uid: 'Contact User UID',
			address: 'Address',
			phone: 'Phone number',
			contact: 'Contact Name',
			email: 'Contact email',
			cre_tm: 'Created',
			active: 'Active',
			notes : 'Notes',
			xtra : 'Extra Fields',
			conf : 'Config',
			
		}

const _DET_FIELDS_EDIT = {
			name: 'Org Name',
			desc: 'Desc',
			type: "Org Type",
			// meds: "Org Meds",
			// docs: "Org Docs",
			par_onum: 'Parent oNum',
			cont_uid: 'Contact User UID',
			address: 'Address',
			contact: 'Contact Name',
			email: 'Contact email',
			phone: 'Phone number',
			active: 'Active',
			notes : 'Notes',
			conf : 'Config',
		}

const _SIGNUP_FIELDS = {
	name: {
		label:'Business Name',
		placeholder: "Your Business Name",
	},
	address: {
		name: "address",
		type: "address_new",
		label:'Business Address', 
		placeholder: "Business Address",
		mode: 'components',
		components:"full_address",
		title: 'Location based on your primary address',
	},
	contact: {
		label:'Contact Name',
		placeholder:"Owner or Manager"
	},
	phone: {
		label:'Phone Number',
		placeholder:"Preferred number"
	},
	
	email: {
		label:'Dispensary Email',
		type:'email',
		placeholder:"Preferred email"
	},
	user_name: 'Name',
	user_details_required: {
		label:'Use Contact Name and Dispensary Email',
		type:'checkbox',
		valid_not_required:true,
	},
	user_email: {
		label:'Email',
		type:'email',
		placeholder:'Email to log in to InstantScripts'
	},
	user_password: {
		label:'Password',
		type:'password',
		placeholder:'Account Password',
		with_validator: true
	},
	recap: {
		name: 'recaptcha'
	}
	// ileg_agree:{
	// 	name: 'ileg_agree',
	// }
	
}

const _MODEL_NAME = 'org_model';

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  

export default class org_model extends db_lib {

	static get FRDB_LOC() { return _FRDB_LOC; }
	static get DET_FIELDS() { return _DET_FIELDS; }
	static get DET_FIELDS_VIEW() { return _DET_FIELDS_VIEW; }
	static get DET_FIELDS_EDIT() { return _DET_FIELDS_EDIT; }
	static get SIGNUP_FIELDS() { return _SIGNUP_FIELDS; }
	static get MODEL_NAME() { return _MODEL_NAME; }

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	static org_conf_itm(o, k) {

		return o?.conf?.[k] 
					&& ( Array.isArray(o.conf[k]) ? o.conf[k] [o.conf[k].length-1] : o.conf[k] )

	}

	//	------------------------	------------------------	------------------------

	static org_conf_arr(o, k) {

		return o?.conf?.[k] 
					&& ( Array.isArray(o.conf[k]) ? o.conf[k] : [o.conf[k]] )
					|| []

	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

}
