import React from 'react';
import { cls as clsn } from './utils';
import { Button } from 'semantic-ui-react';
import SIcon from './StreamlineIcons/SIcon';
import { Link } from 'react-router-dom';
import gtm from '../../xAppLib/providers/gtm';

const ConsultMessage = ({ type, header, content, extra, note, list, consult_btn, id, cls, script_btn, req_scr}) => {
    
    if(!header && !content) return null;

    return (
        <div className={clsn("bg-white p-4 shadow rounded-lg", cls)} data-testid={id}>
            <div className="flex items-center mb-4 gap-4">
                {type != 'basic' && <SIcon 
                            name={type === 'success' ? "check-circle-bold" : "alert-circle"} 
                            cls={type === 'success' ? "text-is-green" : "text-is-red"} size="s" bold 
                        />}
                <h4 className={clsn("mt-0", type === "basic" && 'text-center w-full')}>{header}</h4>
            </div>

            {note && <div className="bg-is-gray-10 rounded-lg p-4 font-bold mb-4">
                        <p>{note}</p>
                    </div>
            }
            
            <p>{content}</p>

            {list && <ul className="mb-4 list-disc list-inside">
                        {list.map((m, i) => { return <li key={i}>{m && m.message || m}</li> }) }
                    </ul>
            }

            <p className="text-sm">{extra}</p>

            {type != 'basic' && 
                <div className="flex gap-4">
                   <Button color="blue" as={Link} to='/' type="button" primary={type != 'success' || !consult_btn} basic={type === 'success' || consult_btn} fluid content="Back to Home" />
                    {type === 'success' && (
                        script_btn ? <Button fluid primary type="button" onClick={() => req_scr()} content={script_btn}/>
                        : <Button fluid primary as={Link} to='/cat' type="button" content="Request another Script" onClick={_ => gtm.event('new-script-order', 'click', {})} />)
                    }
                    {consult_btn && <Button fluid primary type="button" as={Link} to='/med/DocConsCov' content='Request a Consultation' />}
                </div>
            }
    </div>);
}

export default ConsultMessage;