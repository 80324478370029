import React, { useState, useEffect, useRef } from "react";
import SectionHeader from 'views/med/SectionHeader';
import script_model from "models/script_model";
import { Form, Button } from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";
import org_model from "models/org_model";
import SignaturePad from 'react-signature-pad-wrapper'


const Signature = (props) => {
	const { med_data, store, script_type, docs_data_db, visible, Section } = props
	const [signaturePadRef,setSignaturePadRef] = useState()

	useEffect(()=>{
		if (store.sign && visible)
			signaturePadRef?.fromDataURL(store.sign)
	},[visible])

	useEffect(() => {
		signaturePadRef?.instance.addEventListener('endStroke',
			()=>{signaturePadRef?.props.uf_this.handleInputChange({target:{name:"sign", value:signaturePadRef.toDataURL("image/png")}})})
	}, [signaturePadRef])

	const dr = (script_type!='cosm') && docs_data_db?.filter( d => d.m==org_model.org_conf_itm(app.runtime.org, 'doc') )[0]?.name
				|| store && store.cosm_doc && store.cosm_doc!='No' && 
					docs_data_db?.filter( d => d.m==store.cosm_doc )?.[0]?.name
				|| 'our Doctor'
				// || 'Dr Asher Freilich'

	return (
		<UniFormMed
			{...props}
			section="sign"
			fields={script_model.COMM_SIGN_FIELDS}
		>
			{(values, valids, uf_this, fields) => {

				return <Section>
					<Section.Header small>Patient declaration (signature required)</Section.Header>
					<hr className="h-px bg-is-gray-50 border-0 mb-4 -mx-4"></hr>

					<div className="bg-white">

						<p className='text-sm text-is-black-50'>
							Your answers will be reviewed by a doctor. All information provided is stored in a secure and encrypted environment and remains confidential.
							{/* script_type=='doccons' &&
								`Your phone consultation will be conducted today by an InstantScripts doctor. The Doctor will review your request and discuss it with you. All your answers are stored in a secure and encrypted environment and are kept completely confidential. Please acknowledge you are happy for an InstantScripts Doctor to review any information provided by signing below.`
							|| values['cado_want'] && 
								`Your phone consultation will be conducted today by ${dr} who will review your digital answers and will discuss it with you. All your answers are stored in a secure and encrypted environment and are kept completely confidential. Please acknowledge you are happy for ${dr} to review any information provided by signing below.`
							|| !values['cado_want'] && 
								`Your digital consultation will be conducted today by ${dr} who will review your digital answers and may contact you or the pharmacy directly should he require further clarification. All your answers are stored in a secure and encrypted environment and are kept completely confidential. Please acknowledge you are happy for ${dr} to review any information provided by signing below.`
							*/}
						</p>

						{/*<p
							style={{fontSize:'14px',textDecoration:'underline', textAlign: 'right', cursor: 'pointer'}}
							onClick={ _=> app.runtime.mode=='caia' && window.open('https://caia-app-web-content.webflow.io/caia-app/app-privacy-policy', 'tnc') 
											|| app.trigger(app.events.SHOW_POPUP, { pt:"cx_txt",txt:'Privacy Policy',pn:'privacy-policy' }) 
									}
							>Click here if you would like to see our Privacy Policy</p>*/}
						<div className={'required field '+(valids && !valids['sign'] ? "error" : "")}>
							{/*<label>Patient Signature (use your finger or mouse to sign)</label>*/}
							<p className='text-sm text-is-black-50'><i>By signing below, you confirm that the information you have provided is accurate and complete in all respects.</i></p>

							<div className='signature-wrap signature-border standout sign-here field-custom' style={{padding:0,position:'relative'}}>
								<SignaturePad 
										ref={setSignaturePadRef} 
										height={200}
										options={{
											backgroundColor:'transparent',
										}}
										uf_this={uf_this}
									/>

								<Button
										content='Start again'
										basic
										onClick={()=>{
											uf_this.handleInputChange({target:{name:"sign", value:false}})
											signaturePadRef.clear()
										}}
										type="button"
										compact
										style={{right: 0, top:0, position: 'absolute', margin:0,borderTopLeftRadius:0,borderBottomRightRadius:0, borderRight:0,borderTop:0}}
									/>
							</div>
						</div>
					</div>
				</Section>;
			}}
		</UniFormMed>
	);
};


export default Signature;
