import currency from 'currency.js'
import moment from 'moment'

const DEBUG = false

export default class discount_engine {
	
	
	static apply_discount({discount, items,subtotal,med,script_type}) {

		DEBUG && console.log("apply_discount()",{items,subtotal,med}, discount);
		let value = currency(0)
		if (discount) {
			if (discount.expiry_date && moment(discount.expiry_date).isBefore(moment()))
				throw new Error("Discount is expired.")
			if (discount.max_use!==null) {
				if (discount.max_use_user && discount.userUsed >= discount.max_use)
					throw new Error(`This discount can only be used ${discount.max_use} time${discount.max_use!=1?'s':''}.`)
				else if (!discount.max_use_user && (discount.used||0) >= discount.max_use)
					throw new Error(`This discount can only be used ${discount.max_use} time${discount.max_use!=1?'s':''}.`)
			} 
			if (discount.conf.new_user && !discount.isNewUser)
				throw new Error("Discount is only valid for new users.")
			if (!discount.isValidAge)
				throw new Error("Discount is not valid for your profile.")
			if (discount.orgs?.oid && app.runtime.partn_oid && discount.orgs.oid !== app.runtime.partn_oid)
				throw new Error("Discount is not applicable to this organisation.")
			if (!discount.isValidUser)
				throw new Error("Discount is not applicable.")
			if(["friend_referral", "reward_referral"].includes(discount?.type) && ['DocConsMHCP','DocConsMHCPCopay'].includes(med?.key))
				throw new Error("The discount is not applicable to this script.")

			const { conf } = discount
			DEBUG && console.log("checking rule",conf.rule,conf);

			const checkForCatDisounts = (id) => conf[med?.itm_tp?.toUpperCase()] && ['MEDBUY', 'MEDCONS'].includes(id);
			const checkForReviewConsults = (id) => ["qualcons", "qualbuy", "qualclick"].includes(script_type) && ['MEDBUY', 'MEDCONS'].includes(id) && conf.QUALCONS;

			// verify given discount is within the limits of the providing org even if no med was specified
			let restrictMeds = discount.meds?.include;
			if (!restrictMeds?.length && discount.orgs) {
				restrictMeds = app.runtime.org.conf?.disc_allow_meds;
			}
			if (restrictMeds && med?.m && !restrictMeds?.includes(med?.m))
				throw new Error("The discount is not applicable to this script.")

			switch (conf.rule) {
				case "free": {
					const applicable = items.reduce((v,i)=>{
						if (
							conf[i.id] || 
							checkForCatDisounts(i.id) ||
							checkForReviewConsults(i.id)
						) {
							i.discounted = i.price.multiply(-1)
							return v.subtract(i.price)
						}
						return v
					},currency(0))

					if (value.value === applicable.value)
						throw new Error("The discount is not applicable to this script.")
					value = applicable;	
					break;
				}
				case "dollar": {
					let amount = currency(conf.amount)
					const applicable = items.reduce((v,i)=>{
						if ((
								conf[i.id] ||  
								checkForCatDisounts(i.id) ||
								checkForReviewConsults(i.id)
							) && amount.intValue > 0
						) {
							const applicable = Math.min(amount.value,i.price.value)
							i.discounted = currency(applicable).multiply(-1)
							amount = amount.add(i.discounted)
							return v.add(i.discounted)
						}
						return v
					},currency(0))

					if (value.value === applicable.value)
						throw new Error("The discount is not applicable to this script.")
					value = applicable;
					break;
				}
				case "percentage": {
					const applicable = items.reduce((v,i)=>{
						if (
							conf[i.id] || 
							checkForCatDisounts(i.id) ||
							checkForReviewConsults(i.id)
						) {
							i.discounted = i.price.multiply(-conf.amount/100)
							return v.add(i.discounted)
						}
						return v
					},currency(0))

					if (value.value === applicable.value && discount.code!=='op')
						throw new Error("The discount is not applicable to this script.")
					value = applicable;
					break;
				}
			}
		}
		DEBUG && console.log("Final Discount", {value,items});
		return value
	}
	
	
}
