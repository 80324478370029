/* eslint-env node */
//	------------------------	------------------------	------------------------
//	Description: JSON functions. Clone, difference
//  Version: 1.0.0
//  Updates: 
//	------------------------	------------------------	------------------------

//	------------------------	------------------------	------------------------
//	Imports
//	------------------------	------------------------	------------------------

const stopwatch = require('./stopwatch')

//	------------------------	------------------------	------------------------
//	Constants
//	------------------------	------------------------	------------------------

const node_js_env =                 false // Set this is the js environment is node.js (true for BE, false for FE)

//	------------------------	------------------------	------------------------
//	Functions
//	------------------------	------------------------	------------------------

/**
 * Clones an object. Returned as result.clone
 * @param   {Object}        data         data: Data to be cloned
 * */

function clone_object(data) {

    const s_w = new stopwatch()

    let result = { res: 'ok' }

    try {
        //Code in here
        result.original = data
        result.clone = clone_object_raw(data)

    } catch (e) {
        result = {...result, res: 'err', err: `Unknown error running clone_object: ${e}`, e}
    }

    result = {...result, ms: s_w.duration(), stopwatch: s_w.all_data}
    return result
    
}

//	------------------------	------------------------	------------------------

/**
 * Clones an object, no error handling.
 * @param   {Object}        data         data: Data to be cloned
 * */

function clone_object_raw(data) {

    // if (node_js_env){

    //     const v8 = require(node_js_env ? 'v8' : undefined)
    //     return v8.deserialize(v8.serialize(data))       // This will work with circular objects

    // } else {

        return JSON.parse(JSON.stringify(data))         // This will fail with circular objects

    // }   
    
}

//	------------------------	------------------------	------------------------

/**
 * Bad cloning of an object. Returned as result.clone. For performance testing
 * @param   {Object}        data         data: Data to be cloned
 * */

function clone_object_bad(data) {

    const s_w = new stopwatch()

    let result = { res: 'ok' }

    try {
        //Code in here
        result.original = data
        result.clone = clone_object_bad_raw(data)

    } catch (e) {
        result = {...result, res: 'err', err: `Unknown error running clone_object_bad: ${e}`, e}
    }

    result = {...result, ms: s_w.duration(), stopwatch: s_w.all_data}
    return result
    
}

//	------------------------	------------------------	------------------------

/**
 * Clones an object, no error handling.
 * @param   {Object}        data         data: Data to be cloned
 * */

function clone_object_bad_raw(data) {

    return JSON.parse(JSON.stringify(data))
    
}

//	------------------------	------------------------	------------------------

/**
 * Checks if two objects are equal, returns true or false
 * @param   {Object}        obj1         obj1: First object to compare to the second object
 * @param   {Object}        obj2         obj2: Second object object to compare the the first
 * */

function is_equal(obj1, obj2, case_sensitive = false) {

    //return JSON.stringify(obj1) == JSON.stringify(obj2) // This is bad. The key order can changes, especially when saved\retrieved from db
    if (typeof obj1 == 'object' && typeof obj2 == 'object' && obj1 != null && obj2 != null){

        if (Object.keys(obj1).length != Object.keys(obj2).length) return false

        for (let i = 0; i < Object.keys(obj1).length; i++){
            if (!is_equal(obj1[Object.keys(obj1)[i]], obj2[Object.keys(obj1)[i]], case_sensitive)) return false
        }

    } else { // base type

        if (!case_sensitive && typeof obj1 == 'string' && typeof obj2 == 'string' && (obj1.toUpperCase() == obj2.toUpperCase())) {/* All good */}
        else if (obj1 != obj2) return false

    }

    return true
    
}

//	------------------------	------------------------	------------------------

/**
 * Returns the differences between the two objects
 * @param   {Object}        obj1         obj1: First object to compare to the second object
 * @param   {Object}        obj2         obj2: Second object object to compare the the first
 * */

function differences(obj1, obj2, case_sensitive = false, path = [], dif = []) {

    if (typeof obj1 == 'object' && typeof obj2 == 'object' && obj1 != null && obj2 != null){

        const unique_keys = {}
        Object.keys(obj1).forEach(k=>unique_keys[k]=true)
        Object.keys(obj2).forEach(k=>unique_keys[k]=true)

        for (let i = 0; i < Object.keys(unique_keys).length; i++) differences(obj1[Object.keys(unique_keys)[i]], obj2[Object.keys(unique_keys)[i]], case_sensitive, [...path, Object.keys(unique_keys)[i]], dif)

    } else { // base type

        if (!case_sensitive && typeof obj1 == 'string' && typeof obj2 == 'string' && (obj1.toUpperCase() == obj2.toUpperCase())) {/* All good */}
        else if (obj1 != obj2) dif.push({path, obj1, obj2})

    }

    return dif
    
}

//	------------------------	------------------------	------------------------

/**
 * Returns the differences between the two objects, only checking obj1
 * @param   {Object}        obj1         obj1: First object to compare to the second object
 * @param   {Object}        obj2         obj2: Second object object to compare the the first
 * */

function differences_in(obj1, obj2, case_sensitive = false, path = [], dif = []) {

    if (typeof obj1 == 'object' && typeof obj2 == 'object' && obj1 != null && obj2 != null){

        for (let i = 0; i < Object.keys(obj1).length; i++) differences_in(obj1[Object.keys(obj1)[i]], obj2[Object.keys(obj1)[i]], case_sensitive, [...path, Object.keys(obj1)[i]], dif)

    } else { // base type

        if (!case_sensitive && typeof obj1 == 'string' && typeof obj2 == 'string' && (obj1.toUpperCase() == obj2.toUpperCase())) {/* All good */}
        else if (obj1 != obj2) dif.push({path, obj1, obj2})

    }

    return dif
    
}

//	------------------------	------------------------	------------------------

/**
 * Like is_equal, but checks if obj1 is contained in obj2
 * @param   {Object}        obj1         obj1: Object being tested to be contained in obj2
 * @param   {Object}        obj2         obj2: Object being tested to be containing obj1
 * */

function contained_in(obj1, obj2, case_sensitive = false) {

    //return JSON.stringify(obj1) == JSON.stringify(obj2) // This is bad. The key order can changes, especially when saved\retrieved from db
    //return JSON.stringify(JSON.parse(JSON.stringify(obj1))) == JSON.stringify(JSON.parse(JSON.stringify(obj2))) // Might work well? Probs not
    if (typeof obj1 == 'object' && typeof obj2 == 'object' && obj1 != null && obj2 != null){

        if (Object.keys(obj1).length > Object.keys(obj2).length) return false

        for (let i = 0; i < Object.keys(obj1).length; i++) if (!is_equal(obj1[Object.keys(obj1)[i]], obj2[Object.keys(obj1)[i]], case_sensitive)) return false

    } else { // base type

        if (!case_sensitive && typeof obj1 == 'string' && typeof obj2 == 'string' && (obj1.toUpperCase() == obj2.toUpperCase())) {/* All good */}
        else if (obj1 != obj2) return false

    }

    return true
    
}

//	------------------------	------------------------	------------------------

/**
 * Tests whether an object contains a key of the path. eg. to test if obj {a: {b: 1}} for obj.a.b, do contains(obj, ['a', 'b']). Should work with arrays too
 * @param   {Object}        obj         obj1: Object being tested
 * @param   {[String]}      key_path    key_path: Array of object keys
 * */

function object_contains_key_path(obj, key_path) {

    // Recursive function converted to loop

    let depth = 0
    if (typeof key_path == 'undefined') return false
    if (!Array.isArray(key_path)) key_path = [key_path]
    while (depth < key_path.length){
        if (typeof obj != 'object' || typeof obj[key_path[depth]] == 'undefined') return false
        obj = obj[key_path[depth]]
        depth += 1
    }
    return true

}

//	------------------------	------------------------	------------------------

/**
 * Tests whether an object contains a key of the path. eg. to test if obj {a: {b: 1}} for obj.a.b, do contains(obj, ['a', 'b']). Should work with arrays too
 * @param   {Object}        obj         obj1: Object being tested
 * @param   {[String]}      key_path    key_path: Array of object keys
 * */

function object_value_key_path(obj, key_path) {

    let depth = 0
    if (typeof key_path == 'undefined') return undefined
    if (!Array.isArray(key_path)) key_path = [key_path]
    while (depth < key_path.length){
        if (typeof obj != 'object' || obj == null || (typeof key_path[depth] != 'string' && typeof key_path[depth] != 'number') || typeof obj[key_path[depth]] == 'undefined') return undefined
        obj = obj[key_path[depth]]
        depth += 1
    }
    return obj
    
}

//	------------------------	------------------------	------------------------

/**
 * Set the value of an object based on the key path. Will make objects as required
 * @param   {Object}        obj         obj1: Object being tested
 * @param   {Object}        value       value: Value to set
 * @param   {[String]}      key_path    key_path: Array of object keys
 * */

function set_value_key_path(obj, value, key_path) {
    let depth = 0
    if (typeof key_path == 'undefined') return undefined
    if (!Array.isArray(key_path)) key_path = [key_path]
    while (depth < key_path.length - 1){
        if (typeof obj != 'object' || obj == null || (typeof key_path[depth] != 'string' && typeof key_path[depth] != 'number')) return undefined
        if (typeof obj[key_path[depth]] == 'undefined') obj[key_path[depth]] = {}
        obj = obj[key_path[depth]]
        depth += 1
    }
    obj[key_path[depth]] = value
    return obj[key_path[depth]]
}

//	------------------------	------------------------	------------------------

/**
 * Returns all the values within an object that are of the key path name can have undefined head. Kind of like xpath. Will return [{full_path: [], value: ...}]
 * @param   {Object}            obj             obj: The based object where the values will be extraced
 * @param   {[String]}          key_path        keys: Name of the keys who's value(s will be returned)
 * */

function object_values_headless_key_path(obj, key_path, c_path = [], values = [], checked = []) {

    // Updated to not get stuck in circular JSON objects
    if (checked.includes(obj)) return values
    checked.push(obj)

    if (typeof obj == 'object' && obj != null){
        const c_obj_value = object_value_key_path(obj, key_path)
        if (typeof c_obj_value != 'undefined'){ values.push({full_path: [...c_path, ...key_path], value: c_obj_value}) }
        Object.keys(obj).forEach(k => object_values_headless_key_path(obj[k], key_path, [...c_path, k], values, checked) )
    }
    return values
        
}

//	------------------------	------------------------	------------------------

/**
 * Returns a more readable JSON
 * @param   {Object}            obj             obj: The based object
 * */

function trim_JSON(obj){

	return JSON.stringify(obj).replace(/"/g,'').replace(/,/g,', ').replace(/({|})/g,'').replace(/:/g,': ')

}

//	------------------------	------------------------	------------------------

/**
 * Returns the reverse of an array. Note that .reverse alters the original array
 * @param   {Array}            arr             arr: The source array to make a new reversed array from
 * */

function reverse_array(arr) {
	let ret = Array.isArray(arr) && new Array(arr.length) || []
	for (let i = 0; i < arr.length; i++) ret[i] = arr[arr.length - 1 - i]
	return ret
}

//	------------------------	------------------------	------------------------

/**
 * Truncates a text to the specified length 
 * @param   {string}    text            text: The text to truncate
 * @param   {number}    max_len         max_len: Maximum length of the text
 * @param   {string}    trunc_txt       trunc_txt: When text is truncated, will add '...' or what ever is specified here
 * */

function truncate_text(text, max_len, trunc_txt = '...') {
	if (typeof text == 'string'){
        if (text.length > max_len){
            if (typeof trunc_txt == 'string' && trunc_txt.length < max_len){
                return text.slice(0, max_len - trunc_txt.length) + trunc_txt
            } else {
                return text.slice(0, max_len)
            }
        }
    }
    return text
}


/**
 * Return a new object with only the specified keys from the source.
 * @param {Object} src
 * @param {string[]} keys
 * @return {Object}
 * @example
 * const bcs = { title: 'Better Call Saul', year: 2015, genre: ['Drama', 'Crime'] };
 * const genre_year = pick(bcs, ['genre', 'year']);
 * // { year: 2015, genre: ['Drama', 'Crime'] }
 */
function pick(src, keys) {
    const target = {};

    for (const key of keys) {
        if (key in src) {
            target[key] = src[key];
        }
    }

    return target;
}

//	------------------------	------------------------	------------------------

function download_file(file_nm, data){
    const link = document.createElement('a');
    link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(data));
    link.setAttribute('download', file_nm);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function isValidJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

//	------------------------	------------------------	------------------------
//	Exports
//	------------------------	------------------------	------------------------

module.exports = {
    
    is_equal,
    contained_in,
    differences,
    differences_in,
    object_contains_key_path,
    object_value_key_path,
    set_value_key_path,
    object_values_headless_key_path,
    clone_object,
    clone_object_bad,
    clone_object_raw,
    clone_object_bad_raw,
    trim_JSON,
    run_test,
    reverse_array,
    truncate_text,
    pick,
    download_file,

    //Shorthand
    obj_val: object_value_key_path,
    set_val: set_value_key_path,
    clone: clone_object_raw,
    isValidJSON
}

//	------------------------	------------------------	------------------------
//	Testing
//	------------------------	------------------------	------------------------

//run_test()
//performance_test()

function run_test() {
    
    // Make some data
    const original = {data1: 'test', data2: 123}

    // Make a circular reference
    original.circular_ref = original

    // Make the clones
    const clone_res = clone_object(original)
    const clone_bad_res = clone_object_bad(original)

    // Modify the clones
    if (clone_res.clone){
        clone_res.clone.circular_ref.data3 = 'works without modifying original'
        clone_res.clone.data4 = 789
    }
    if (clone_bad_res.clone){
        clone_bad_res.clone.circular_ref.data3 = 'works without modifying original' // Wont ever work becuase JSON.stringify fails
        clone_bad_res.clone.data4 = 789
    }

    // Modify the original
    original.data4 = 'Should not be in clones'
    original.data2 = 456

    // Output the results
    console.log('original')
    console.log(original)

    console.log('clone_res')
    console.log(clone_res)

    console.log('clone_bad_res')
    console.log(clone_bad_res)

}

function performance_test() {
    const data_elements = [1, 10, 100, 1000, 10000, 100000, 1000000]

    data_elements.forEach((c, j) => {

        let obj = {}

        for (let i = 0; i < c; i++){
            obj[`key_${i}`] = 0
        }
    
        const c1 = clone_object(obj)
        const c2 = clone_object_bad(obj)

        console.log(`Test ${j+1} with ${c} elements. Clone good time: ${c1.ms} ms, clone bad time: ${c2.ms} ms.`)

    })
}