import currency from "currency.js";
import React from "react";

import {
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHeader,
	TableHeaderCell,
	TableRow,
} from "semantic-ui-react";

const CopayPopup = ({ content, config = {} }) => {


	const copay = currency(config?.copay || 49)
	const mhcp = currency(config?.mhcp || 96.25)
	const mhcprev = currency(config?.mhcprev || 75.8)


	return (
		<Modal
			closeIcon
			trigger={content}
			header="Mental Health Care Plan Pricing Model"
			actions={["Ok"]}
			content={
				<Modal.Content>
					<p>
						<strong>What do we offer?</strong>
					</p>

					<p>
						Mental Health Care Plans allow eligible patients to
						access up to 10 psychology sessions in a 12 month period
						via Medicare.{" "}
					</p>

					<p>
						We offer two types of Mental Health Care Plan (MHCP)
						consultations:
					</p>

					<ul class="list-disc pl-4">
						<li>
							Mental Health Care Plan: For patients who have{" "}
							<span className="font-bold">not</span> had an MHCP
							in the last 12 months
						</li>
						<li>
							Mental Health Care Plan Review: For patients who{" "}
							<span className="font-bold">have</span> had a MHCP
							and need it reviewed. Patients are required to have
							a Review after they have accessed 6 out of the 10
							psychology sessions available.
						</li>
					</ul>
					<br />

					<p>
						<strong>How does pricing work?</strong>
					</p>

					<Table>
						<TableHeader>
							<TableRow>
								<TableHeaderCell>
									Consultation type
								</TableHeaderCell>
								<TableHeaderCell>Total amount</TableHeaderCell>
								<TableHeaderCell>
									Medicare Rebate
								</TableHeaderCell>
								<TableHeaderCell>
									Out-of-pocket cost to you
								</TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							<TableRow>
								<TableCell>
									<strong>Mental Health Care Plan</strong>
								</TableCell>
								<TableCell>{mhcp.add(copay).format()}</TableCell>
								<TableCell>{mhcp.format()}</TableCell>
								<TableCell>{copay.format()}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<strong>
										Mental Health Care Plan Review
									</strong>
								</TableCell>
								<TableCell>{mhcprev.add(copay).format()}</TableCell>
								<TableCell>{mhcprev.format()}</TableCell>
								<TableCell>{copay.format()}</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					<p>
						You will see a pending charge for the full amount of
						$145.45 on your card after requesting an MHCP
						consultation. The doctor will then determine which
						consultation is right for you.{" "}
					</p>
					<p>
						You will only be charged for the appropriate
						consultation once you have spoken to the doctor. You
						will then receive the Medicare rebate. Please note: The
						Medicare rebate can take up to 5 business days.
					</p>
					<p>
						If you have any questions, you can contact our support
						team.
					</p>
				</Modal.Content>
			}
		/>
	);
};

export default CopayPopup;
