import React from "react";
import { Button, Grid } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

function byName(left, right) {
	return left.name?.localeCompare(right.name) ?? 0;
}

function bySize(left, right) {
	if (left.size && right.size) {
		// Some meds have multiple active ingredients, so we have sizes like 10/40/20. Big assumption the
		// sizes are in the same order, but it's the best we can do.
		const lSize = left.size.split(/[^\d.]/).map(x => parseFloat(x));
		const rSize = right.size.split(/[^\d.]/).map(x => parseFloat(x));
		const len = Math.min(lSize.length, rSize.length);

		for (let i = 0; i < len; i++) {
			const cmp = lSize[i] - rSize[i];
			if (Number.isFinite(cmp) && cmp !== 0) {
				return cmp;
			}
		}
	}

	return 0;
}

function byQuantity(left, right) {
	if (left.qnty && right.qnty) {
		const lQuantity = parseFloat(left.qnty);
		const rQuantity = parseFloat(right.qnty);

		const cmp = lQuantity - rQuantity;
		if (Number.isFinite(cmp)) {
			return cmp;
		}
	}

	return 0;
}

function sortResults(left, right) {
	return (
		byName(left, right)
		|| bySize(left, right)
		|| byQuantity(left, right)
		|| 0
	);
}

export function Results({results, medsearchExp=false, srch}) {
	const navigate = useNavigate();
	const base = window.location.pathname.replace(/\/prescription\/?$/,'');
	const sorted = results.slice().sort(sortResults);

	const onClick = ({m, t, hdr_warn, price, name}) => {
		
		if (medsearchExp &&  (t === 'con'  || m === "/m/pharm/DocCons/DocConsCov")) {
			return app.trigger(app.events.SHOW_POPUP, { pt: "cx_txt", txt: 'Medication consultation required', pn: 'med-consult', noCloseBtn: true, m, cost: (price || 'dc_std'), srch: name, hdr_warn })
		}

		if (t === 'l') {
			return navigate(m, {state: {hdr_warn}})
		}

		return navigate(`${base}/${m}`);
	};

	return ( <div className={`${medsearchExp ? "mt-4 rounded-lg border border-[#B0B0B0] divide-y divide-[#B0B0B0] my-4 text-black" : "ui vertically divided grid"}`}>
			{sorted.map((result, k) => (
				medsearchExp ? 
				<button type="button" data-testid="button-select" key={k} className="w-full p-3 flex flex-col lg:flex-row items-start lg:items-center lg:justify-between cursor-pointer text-left" onClick={() => onClick(result)}>
					<p className="!mb-0 text-[#494E53] lg:text-[1.125rem]" >{result.name} {result?.alt_name && `(${result?.alt_name})`}</p> 
					<p className="!mb-0 text-[#494E53] lg:text-[1.125rem]" >{result?.size} {result?.qnty && `/ ${result?.qnty}`}</p>
				</button>
				:
				<Grid.Row key={k} style={{paddingBottom: 0, ...(k > 0 && {paddingTop: 0})}}>
					<Grid.Column tablet={10} computer={10} mobile={11} textAlign="left">
						<strong>{result.name} {result.size}{result.qnty && ` (x${result.qnty})`}</strong><br/>
						{result.alt_name && `Generic name: ${result.alt_name}`}
					</Grid.Column>
					<Grid.Column tablet={6} computer={6} mobile={5} textAlign="right">
						<Button compact basic type="button" data-testid="button-select" onClick={() => onClick(result)}>
							Select
						</Button>
					</Grid.Column>
				</Grid.Row>
			))}
		</div>
	)
}
