import React, {Component} from 'react'

import { Input, Radio, Checkbox, Select, Form, Icon, Grid, Popup } from 'semantic-ui-react'


import MaskedInput from 'react-text-mask';

import moment from 'moment';
import  JsonEditor  from '../UIelems/JsonEditor'; 

import SelectOrg from '../Orgs/SelectOrg';
import ClaimsEditor from '../Users/ClaimsEditor';
import AddressSearchNew from '../UIelems/AddressSearchNew';
import AddressSearch from "../UIelems/AddressSearch";
import ShowTextObj from '../UIelems/ShowTextObj';
import ShowUserName from '../UIelems/ShowUserName';

import SelectOption from 'views/UIelems/SelectOption';

import AppointmentTimes from "views/booking/appointment/AppointmentTimes";

import TermsAndPrivacy, {TermsAndPrivacyBlurb} from '../UIelems/TermsAndPrivacy';
import PasswordWithValidator from './PasswordWithValidator';

const DEBUG = false

export default class UniFieldSUI extends Component {

	constructor (props) {
		super(props)

		this.update_appointment = this.update_appointment.bind(this);
	}

	render () {

		const { fl, values, valids, uf_this, show_label=true, size, valid_not_required, cust_fields, display_errors } = this.props

		// console.log('UniFieldSUI::render', values, fl);

		if (!fl)
			return <div>no fl</div>

		const val = (fl.view_path && values?.[fl.name]?.[fl.view_path])
							?? values?.[fl.name] 
							?? fl.value
							?? ''

		let error = valids && !valids[fl.name]
		if (error && display_errors) 
			error = {
		        content: typeof fl.validation_message === "function"
					? fl.validation_message(val, values)
					: fl.validation_message || 'Field is missing or incorrect',
		        pointing: 'above',
						style: fl.error_style
			}
			
		let pattern = fl.pattern?.toString()
		pattern = pattern?.substring(2,pattern.length-2)	

		let params = { show_label: true, ...this.props } // fl will come from here
		params.val = val
		params.error = error
		params.pattern = pattern

		const label = fl.label && <><label style={fl.label_style}>{fl.label} {fl.note && <Popup
			trigger={<Icon name='question circle outline'  />}
			content={fl.note}
		  />}</label></>


		const Component = fl.container_style ? 'div' : React.Fragment
		const style = fl.container_style ? {style: fl.container_style} : {};
		return <Component {...style}>
			{DEBUG && <hr />}
			{DEBUG && <ShowTextObj	lbl='fl'	cont={fl} />}
			{DEBUG && <ShowTextObj	lbl='val'	cont={val || '-'} />}
			{DEBUG && <ShowTextObj	lbl='values'	cont={values || '-'} />}

			{
				fl.read_only && fl.type == 'uid' &&
					<ShowUserName	uid = {val}	/>

				|| 
				fl.read_only &&
					<div className='required field'>
						{show_label && label }
						<ShowTextObj 	cont={val} />
					</div>
				|| (typeof fl.type == 'function' || (typeof fl.type == 'object' && '$$typeof' in fl.type)) && <fl.type value={val} 
					{...fl.params} fl={fl} error={error} show_label={show_label}
					onChange={uf_this.handleInputChange}
				 />
				|| (fl.type=='password' && fl.with_validator) &&
					<PasswordWithValidator 
					 		label={show_label && label}
							required={!valid_not_required && !fl.valid_not_required}
							name={fl.name}
							value={val}
							onChange={ uf_this.handleInputChange }
							placeholder={fl.placeholder} 
							error = {error} 
							disabled={fl.disabled} 
							fluid size={size} key={"UniForm-field-"+fl.name} /> 
				|| (!fl.type || ['text', 'email', 'number', 'date', 'password'].includes(fl.type)) &&
					<>
					{/*{!app.runtime?.login_type=='popup' && fl.name == 'password' && <h5>Create Password</h5>}*/}

					<Form.Input 
							label={show_label && label}
							required={!valid_not_required && !fl.valid_not_required}
							name={fl.name}
							value={ fl.type=='date' && moment(val).format("YYYY-MM-DD") || val}
							onChange={ uf_this.handleInputChange }

							type={fl.type} 
							placeholder={fl.placeholder} 
							pattern={pattern}
							error = {error} 

							disabled={fl.disabled} 
							icon={fl.icon} 
							iconPosition={fl.icon && 'left'} 
							fluid 
							size={size}
							control = {Input}
							key={"UniForm-field-"+fl.name}
							data-testid={`input-${fl.name}`}
							style={fl.input_style}
							maxLength={fl.max_length}
					 /> 
					</> 

				 ||
				 fl.type == 'dob_date'  && 
					 <Form.Input
							 label={show_label && label}
							 required={!valid_not_required && !fl.valid_not_required}
							 error = {error} 
							 disabled={fl.disabled} 
							 size={size}
							 fluid 
							 key={"UniForm-field-"+fl.name}
							 iconPosition={fl.icon && 'left'} 
							 >
							 <MaskedInput
										 mask={[ /\d/, /\d/, '/', /\d/, /\d/, '/',/\d/, /\d/, /\d/, /\d/, ]}
										 placeholder={'DD/MM/YYYY'} 
										 required={!fl.valid_not_required}
			 							 onChange={ e => {
											 	const value = e?.target?.value
 			 								 	uf_this.handleInputChange({target:{name:fl.name,value:value && value.split('/').reverse().join('-')||''}})
			 								}}
										 value={val && val.split('-').reverse().join('/')}
										 name = {fl.name}
										 type = 'text'
									 />
								 {fl.icon && <Icon name={fl.icon} />}
						 </Form.Input>
				||
				(fl.type === 'address' || fl.type === 'address_new') &&
					<Form.Input 
							label={show_label && <label>{fl.label}{this.props.show_val && val && val!='' && <small className='text grey'> – {val}</small> }</label>}
							required={!valid_not_required && !fl.valid_not_required}
							name={fl.name}
							value={fl.mode=='components' && typeof val == 'object' && val || fl.components && values[fl.components] || val}
							onChange={ (value) => {
								if (!fl.mode || fl.mode == 'simple') {
									uf_this.handleInputChange({target:{name:fl.name, value}})
								} else /*if (fl.mode == "components")*/ {
									if (fl.components) {
										//target components mode
										uf_this.handleInputChange({target:{name:fl.name, value:value.formatted}})
										if (Array.isArray(fl.components)) {
											// TODO is that still a thing we need? 🤔
											// save each separate
											fl.components.forEach(c=>{
												uf_this.handleInputChange({target:{name:c.target, value:value[c.source]}})
											})
										} else if (typeof fl.components == 'string') {
											// save as single object
											uf_this.handleInputChange({target:{name:fl.components, value}})
										}
									} else {
										// save as is
										uf_this.handleInputChange({target:{name:fl.name, value}})
									}									
								} 
							} }
							type={'text'} 
							placeholder={fl.placeholder} 
							error = {error} 
							disabled={fl.disabled} 
							icon={fl.icon} 
							//iconPosition={fl.icon && 'left'}
							activeProfInd={fl.activeProfInd}
							mode={fl.mode} 
							fluid 
							size={size}
							control = {fl.type === 'address_new' ? AddressSearchNew : AddressSearch}
							key={"UniForm-field-"+fl.name}
							data-testid="input-address"
							isPrimaryAddress={this.props.isPrimaryAddress}
							setIsPrimaryAddress={this.props.setIsPrimaryAddress}
							field={fl}
					 />
				||	
				fl.type == 'masked' &&
					<Form.Input
							label={show_label && label}
							required={!valid_not_required && !fl.valid_not_required}
							error = {error} 
							disabled={fl.disabled} 
							size={size}
							fluid 
							key={"UniForm-field-"+fl.name}
							iconPosition={fl.icon && 'left'} 
							>
							
							<MaskedInput
										mask={fl.mask}
										placeholder={fl.placeholder} 
										required={!fl.valid_not_required}
										
										value = {val}
										name = {fl.name}
										onChange = {uf_this.handleInputChange}
										type = 'text'
									/>
								{fl.icon && <Icon name={fl.icon} />}
					</Form.Input>
				|| 
				fl.type == 'textarea' &&
					<Form.TextArea 
							label={show_label && label}
							required={!valid_not_required && !fl.valid_not_required}
							name={fl.name}
							value={val}
							onChange={uf_this.handleInputChange}
							placeholder={fl.placeholder} 
							error = {error} 
							rows = {fl.rows}

							key={"UniForm-field-"+fl.name}
					 /> 

				|| 
				fl.type == 'select' &&
					<Form.Field key={"UniForm-field-"+fl.name}
							error = {valids && !valids[fl.name]} 
							required={!valid_not_required && !fl.valid_not_required}
					>
						{show_label && label}
						<Select 
							placeholder={fl.placeholder} 
							name={fl.name}
							value={val}
							fluid
							onChange={uf_this.handleInputChange}
							options={fl.options}
							clearable={!!fl.clearable}
						 />
					</Form.Field>

				|| 
				(fl.type == 'radio' || fl.type == 'checkbox') &&
					<Form.Field 
								// negative={valids && !valids[fl.name]} 
								key={"UniForm-field-"+fl.name}
								required={!valid_not_required && !fl.valid_not_required}
							>
						{show_label && label}
						{
							fl.options && fl.options.map( (op, oi) => <Form.Field 
									control={fl.type=="radio"&&Radio || fl.type=="checkbox"&&Checkbox}
									label={op.n} 
									name={fl.name}
									data-testid={fl.name}
									value={op.c}
									checked={val == op.c}
									onChange={()=>uf_this.handleInputChange({target:{name:fl.name, value:fl.type=="checkbox"&&val==op.c ? null : op.c}})}
									error={valids && !valids[fl.name]}
									key={"UniForm-field-"+fl.name+oi}
									style={fl.checkbox_style}
								 />
							 )
						}
					</Form.Field>
				|| fl.type === 'checkbox-multiple' && (
					<Form.Field key={"UniForm-field-"+fl.name} required={!valid_not_required && !fl.valid_not_required} >
						{show_label && label}
						{fl.options && fl.options.map( (op, oi) => <Form.Field 
								control={Checkbox}
								label={op.n} 
								name={fl.name}
								data-testid={fl.name}
								value={op.c}
								checked={val.includes(op.c)}
								onChange={()=>uf_this.handleInputChange({target:{name:fl.name, value:val.includes(op.c) ? val.filter(v=>v!==op.c) : [...val, op.c]}})}
								error={valids && !valids[fl.name]}
								key={"UniForm-field-"+fl.name+oi}
								style={fl.checkbox_style}
							 />
						 )
						}
					</Form.Field>
				)
				|| 
				fl.type == 'bool' && 
					<div style={{display:'flex',alignItems:'center'}}>
						{/*itm_data[f.name]!=undefined&&itm_data[f.name].toString()*/}
						
						<Checkbox	
							defaultChecked = {val || fl.default || false}
							onChange={ (e, d) => uf_this.handleInputChange({target:{name:fl.name, value:d.checked}}) }
							// onChange = { (e, d) => onSave_dets_fld(f.name, d.checked) }
							// onChange = { (e, d) => console.log(d.checked) }
							toggle 
							style = {{padding:0,alignSelf:'center',marginRight:'10px'}} 

						/>
						{val && "Yes" || "No"}
					</div>

				|| 
				fl.type == 'json' &&
					<JsonEditor
							value={val && {...fl.default, ...val} || fl.default || {}}
							search={false}
							onChange={ value => uf_this.handleInputChange(null, {name:fl.name, value}) }
				        />

				||
				fl.type == 'jsonarray' &&
					<JsonEditor
							value={val || fl.default || []}
							search={false}
							onChange={ value => uf_this.handleInputChange(null, {name:fl.name, value}) }
				        />

				||
				fl.type == 'onum' &&
					<React.Fragment>
						<SelectOrg
							leafOnly={false}
							onum={val}
							onSelect={ org => uf_this.handleInputChange(null, {name:fl.name, value:org?.onum}) }
							// onSelect={ org => uf_this.handleInputChange({target:{name:fl.name, value:org.onum}}) }
							placeholder='Set parent org'
							setBtn={true}
						/>
					</React.Fragment>

				||
				fl.type == 'org' &&
					<Form.Field key={"UniForm-field-"+fl.name}
							error = {valids && !valids[fl.name]} 
							required={!valid_not_required && !fl.valid_not_required}
					>
						<SelectOrg 
							leafOnly={false}
							oid={val}
							onSelect={ org => uf_this.handleInputChange({target:{name:fl.name, value:org?.oid}}) }
							placeholder='Set org'
							setBtn={true}/>
					</Form.Field>

				||
				fl.type == 'role' &&
					<Form.Field key={"UniForm-field-"+fl.name}
							error = {valids && !valids[fl.name]} 
							required={!valid_not_required && !fl.valid_not_required}
					>
						<ClaimsEditor 
							inline
							claims={val}
							onSetClaims={ claims =>	uf_this.handleInputChange({target:{name:fl.name, value:claims}}) }
							/>
					</Form.Field>

				||
				fl.type == 'sel_doc' &&	
					<SelectOption
							editButton
							value={val}
							placeholder='Select doctor'
							// name={`user ${r.displayName || r.last_name} (${r.email}) - set doctor `}

							onSelect={ value => uf_this.handleInputChange({target:{name:fl.name, value:value}}) }
							// onClear={ _=> this.del_usr_doc(r.uid) }

							noConfirm={true}

							options={ app.app_data.ws_data.docs_sel_list }
						/>

				||
				fl.type == 'separator' &&	<hr />

				||
				fl.type == 'hidden' &&	<React.Fragment></React.Fragment>

				||
				(fl.type == 'custom'
					&& cust_fields
					&& fl.cust_fld
					&& (typeof cust_fields[fl.cust_fld] === 'function'
						? cust_fields[fl.cust_fld](fl)
						: cust_fields[fl.cust_fld]
					)
				)
				||
				fl.type == 'dva' && this.render_dva(params)
				||
				fl.type == 'privacy' && this.render_privacy(params)
				|| 
				fl.type == 'appointment' && (
					<div key={`UniForm-field-${fl.name}`}>
						{!fl.basic && (
							<div
								className={`field${!valid_not_required && !fl.valid_not_required
										? ' required' : ''}${valids && !valids[fl.name]
										? ' error' : ''
									}
								`}
							>
								<label>Appointment time</label>
							</div>
						)}
						<AppointmentTimes
							booking_type={fl.booking_type}
							onChangeTime={this.update_appointment}
							basic={!!fl.basic}
							onInitialAppointmentsEmpty={fl.onInitialAppointmentsEmpty}
						/>
					</div>
				)

				||
					<div>Unknown Field Object {JSON.stringify(fl)}</div>
			}
			
			
			
		</Component>

	}

	update_appointment(value) {
		const { uf_this } = this.props;
		uf_this.handleInputChange(
			null,
			{ name: "appointment", value }
		);
	}

	render_dva(params) {
		const reset_key = `reset__sel__`

		let handle_input = (e, d) => {
			let new_val = JSON.parse(JSON.stringify(params.val)) || {number: '', colour: ''}
			if (d.field_name) {
				new_val[d.field_name] = d.value
				// if (new_val.number == '' && new_val.colour == '') new_val = null // Don't change to null here. Otherwise it is completely removed in Uniform
				params.uf_this.handleInputChange({ target: { name: params.fl.name, value: new_val } })
			}
		}

		let dva_number_error = params.val && (params.val.number || params.val.colour) && !params.fl.validate_dva_number(params.val.number) && { content: 'Field is missing or incorrect', pointing: 'above' } || null
		let dva_colour_error = params.val && (params.val.number || params.val.colour) && !params.fl.validate_dva_colour(params.val.colour) && { content: 'Field is missing or incorrect', pointing: 'above' } || null
		params.error = dva_number_error || dva_colour_error// && { content: 'Field is missing or incorrect', pointing: 'above' } || null
		if (params.valids) params.valids[params.fl.name] = !params.error

		return (
			<div className='dva equal width fields'>
				<div className='field'>
				{params.show_label && <label>{ params.fl.label}</label>}
					<Grid>
						<Grid.Row>

							<Grid.Column mobile={16} tablet={10} computer={10} largeScreen={10} widescreen={10} verticalAlign="top">
								<Form.Input
									label={params.show_label && 'Card number'}
									required={!params.valid_not_required && !params.fl.valid_not_required}
									//name={params.fl.name+'_number'}
									value={params.val && params.val.number}
									onChange={(e, d) => { d.field_name = 'number'; handle_input(e, d) }}

									type={params.fl.type}
									placeholder={params.fl.dva_number_placeholder}
									//pattern={params.fl.pattern}
									maxLength={9}
									error={dva_number_error}

									disabled={params.fl.disabled}
									icon={params.fl.dva_number_icon}
									iconPosition={params.fl.dva_number_icon && 'left'}
									fluid
									size={params.size}
									control={Input}
									key={"UniForm-field-" + params.fl.name + "-number"}
									data-testid='input-dva'
								/>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={6} computer={6} largeScreen={6} widescreen={6} verticalAlign="top">
								<Form.Select
									label={params.show_label && 'Card colour'}
									required={!params.valid_not_required && !params.fl.valid_not_required}
									//name={params.fl.name+'_colour'}
									value={params.val && params.val.colour}
									onChange={(e, d) => { if (d.value == reset_key) d.value = ""; d.field_name = 'colour'; handle_input(e, d) }}
									options={[...params.fl.dva_colour_options, { key: reset_key, value: reset_key, text: '-- Clear --' }]}

									placeholder={'Colour'}

									error={dva_colour_error}

									disabled={params.fl.disabled}
									//icon={params.fl.icon} // Icon seems broken
									//iconPosition={params.fl.icon && 'left'}
									fluid
									size={params.size}
									control={Select}
									key={"UniForm-field-" + params.fl.name + "-select"}
									data-testid='dropdown-colour'
								/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					</div>
			</div>
		)
	}

	//	------------------------	------------------------	------------------------

	render_privacy({fl,valids,val,uf_this}) { // Conformance MA-3 - consent, MA-5 - T&C, Privacy, MA-6 - Use of data, 
		const has_error = valids && !valids[fl.name]
		return (
			<React.Fragment>
				{fl.auto && <p><small><TermsAndPrivacyBlurb/></small></p> ||
					<>
					{(fl.show_label || has_error) && <Form.Field label={fl.show_label&&'Privacy Policy'} error={has_error && { content: 'You must accept to create an account', pointing: 'below' }} />}
					<Form.Checkbox
						defaultChecked={val || false}
						data-testid='checkbox-privacy'
						onChange={(e, d) => uf_this.handleInputChange({ target: { name: fl.name, value: d.checked } })}
						//error={{ content: 'Field is missing or incorrect', pointing: 'above' }}
						error={valids && !valids[fl.name] && "You must agree to the terms and conditions to proceed."}
						label={<TermsAndPrivacy />}
					/>
				
				</>
			}
			</React.Fragment>
		)

	}
}
