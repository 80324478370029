import { useSiteStatus } from "xAppLib/Hooks"

function can_approve(approve_wr = {}) {
	if (!(app.acl.is_admin || app.acl.is_doc_gp || app.acl.is_supp))
		return false
	if (Array.isArray(approve_wr.always) && (approve_wr.always.includes(app.user.claims?.doc_id) || approve_wr.always.includes(app.user.uid)))
		return true
	if (!approve_wr.active)
		return false
	return Array.isArray(approve_wr.access) ? approve_wr.access.includes(app.user.claims?.doc_id) || approve_wr.access.includes(app.user.uid) : true
}

export function useCanApprove() {
	const approve_wr = useSiteStatus('approve_wr') || {}    
    return can_approve(approve_wr)
}