import React from "react";
import { Button, Container} from "semantic-ui-react";
import { Link } from "react-router-dom";
import MainTiles from "../../xAppLib/UIelems/MainTiles";
import FAQ from "../../xAppLib/UIelems/FAQ";
import { cls } from 'views/NUI/utils';

const op_img_path = "https://storage.googleapis.com/instant-med-public/OnePass";

const pricing_terms = <>
                        *Exclusions, T&Cs apply. Express click & collect already available to all customers at Officeworks & Target. Alternative Flybuys offer for Catch.<br/>
                        †Savings compared to separately purchasing OnePass monthly memberships.<br/>
                    </>

const Hero = ({usr}) => {

    return  <div className={cls("h-auto relative flex flex-wrap", usr ? "bg-is-blue md:h-[417px]" : "bg-is-gray-20 md:bg-white")}>
                <img src={`${op_img_path}/woman_using_app_sm.jpg`} className={cls("w-full block md:hidden", usr ? 'order-1': 'order-2')} alt="woman using IS app"/>
                <div className={cls("absolute top-0 bottom-0 left-1/2 right-0 bg-cover bg-no-repeat md:block hidden rounded-bl-[270px]", usr ? "md:left-[50%] xl:left-[60%]" : "bg-center md:left-[50%]" )} style={{backgroundImage: `url(${op_img_path}/woman_using_IS_app.jpg)`}}></div>
                    <Container className={cls(usr ? "order-2 md:order-1" : "order-1")}>
                        {usr ? 
                            <>
                                <div className={cls("pt-12 md:pt-0 flex flex-wrap w-full md:w-1/2 items-center justify-center md:justify-start gap-2", usr && "md:mt-[100px] lg:mt-[152px]")}>
                                    <img src={`${op_img_path}/Is_logo_white_wide.svg`} alt="InstantScripts"/>
                                    <div className="border-r-[0.5px] border-white opacity-50 h-[53px] mx-4 hidden lg:block"></div>
                                    <img src={`${op_img_path}/onepass_purple_bg.svg`} className="px-8 sm:px-0" alt="OnePass"/>
                                </div>
                                <h2 className="text-white mt-4 text-center md:text-left pb-11 md:pb-0 w-full">Start enjoying your<br className="block xl:hidden"/> OnePass benefits</h2>
                            </>
                        :  
                            <>
                                <div className="md:w-[40%] xl:w-[417px] pt-10 md:py-[60px]">
                                    <img src={`${op_img_path}/onepass_icon_purple.svg`} className="h-[44px] mb-8 md:mb-12" alt="OnePass"/>
                                    <h2 className="text-is-black font-bold text-[2.25rem] mb-6">Enjoy more value<br/> & benefits</h2>
                                    <div className="bg-white md:bg-none rounded-md md:rounded-none py-6 px-4 md:p-0 mb-4 md:mb-0">
                                        <p className="text-is-black-50 font-bold mb-6">At InstantScripts you can enjoy 5% off eligible InstantScripts services<sup className="text-[10px]">1</sup> and free standard delivery on prescription medication in Australia*.</p>
                                        <Button className="!text-[1.3rem] !md:text-[1.5rem]" primary as="a" href="https://my.onepass.com.au/auth/signup?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_signup" style={{background: '#9900F1', padding:'1rem 2rem'}}>Start your free trial</Button>
                                        <p className="text-sm text-is-black-50 mt-6 font-bold">Already a member? <a className="text-is-blue cursor-pointer" onClick={() => app.trigger(app.events['REQUEST_LOGIN'])}>Log in to your account</a></p>
                                        <p className="text-sm text-is-black-50 mt-6">By activating OnePass you agree that InstantScripts and OnePass will confirm you have an account with the other to enable your accounts to be linked and you to receive your benefits and communications. Your personal information will be handled in accordance with the InstantScripts Privacy Policy.</p>
                                        <p className="text-sm font-bold mt-4 md:mt-6 mb-0">Try it for free, cancel anytime.</p>
                                        <p className="text-[12px] mb-0 text-is-black-50 leading-[18px]">After your free trial, OnePass is only $4/month or $40/year.</p>
                                        <p className="text-[12px] pt-5 md:pt-12 text-is-gray-25 leading-[18px]">
                                            <sup>1</sup>Limited to Online Prescription, Telehealth Consultations, Medical Certificates, Blood Test Requests and Specialist Referrals.<br/><br/>
                                            *T&Cs, Exclusions and eligibility criteria apply. <a href="#terms">Click here to view</a>. Delivery is available for prescription medicines obtained through the online prescriptions service and treatment plans only.
                                        </p>
                                    </div>
                                </div>
                            </>
                        }
                    </Container>

                    {!usr && <div className="md:hidden text-center order-last bg-white p-6 w-full border-b-[3px] border-op-gray">
                        <p><span className="font-bold">Already a OnePass member?</span><br/>Continue to activate your benefits now.</p>
                        <Button basic fluid as="a" href="https://my.onepass.com.au/login?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_login"><span className="flex justify-center items-center">Continue with <img src={`${op_img_path}/onepass_icon_purple.svg`} alt="OnePass" className="h-[14px] ml-2"/></span></Button>
                    </div>}
                </div>
};

const Intro = ({usr}) => {
    const boxes = [
       {
        icon: 'increase_decrease',
        title: '5% off eligible InstantScripts services*',
        desc: '5% off eligible InstantScripts services (limited to Online Prescription Consultations, Medical Certificates, Doctor Consultations, Blood Test Requests, Specialist Referrals)'
       },
       {
        icon: 'delivery_pill_icon',
        title: 'Free standard delivery on prescription medication in Australia*',
        desc: 'Free standard delivery on prescription medication in Australia prescribed by an InstantScripts doctor'
       },
      
    ]
    return <div className={cls("py-10 md:py-20", !usr && "md:bg-is-gray-20")}>
        <Container>
            <h1 className={cls("!text-is-black-80 text-center flex items-center justify-center mx-auto mb-6", usr ? "md:text-[2.5rem]" : "text-lg md:text-[2.5rem] font-bold md:font-normal")}>{usr ? <>Your InstantScripts +<br className="block md:hidden"/> OnePass benefits</> : <>What you get at InstantScripts</>}</h1>
            <div className="grid md:grid-cols-2 gap-4">
                {boxes.map((el, i) => {
                    return <div key={i} className="flex flex-col items-center md:items-start bg-white md:rounded-md md:shadow px-8 py-6 text-left">
                        <div>
                            <img src={`${op_img_path}/${el.icon}.svg`} className="mx-auto md:mx-0" alt={el.icon} />
                            <h3 className="font-bold text-center md:text-left md:min-h-[64px]">{el.title}</h3>
                        </div>
                        <p className="text-is-black-50 mt-4 text-center md:text-left">{el.desc}</p>
                    </div>
                })}
            </div>

            <div className="pt-6 w-fit mx-auto text-center">
                {usr ?
                    <p className="text-sm text-is-gray-25">*Terms and conditions, exclusions and eligibility criteria apply.<br/>
                      To view our full OnePass Terms and Conditions <a href="#terms" className="text-is-blue underline">click here</a>.
                    </p>
                : <p className="text-sm text-is-gray-25">*T&Cs, Exclusions apply and eligibility criteria apply. <a href="#terms" className="font-bold">Click here to view.</a> Standard delivery only, express delivery not eligible for free delivery.</p>}
            </div>
        </Container>
    </div>
}

const Benefits = () => {

    return (
        <div className="bg-is-blue">
            <MainTiles
                show_pr={true}
                excl={["/c/pharm/subscr"]}
                colour="white"
                title={
                    <h2 className="text-white text-center mb-12">
                        Start enjoying the benefits*
                    </h2>
                }
                size="md"
            />
            <p className="text-white text-center mt-0 pb-[96px]">
                *Prices listed above may vary and are subject to change.
            </p>
        </div>
    );
}

const Other = ({usr}) => {
    const other_boxes = [
        {
            title: 'Free Delivery on eligible items or orders',
            icon: 'free_delivery'
        },
        {
            title: '5x Flybuys points in-store',
            icon: 'flybuys'
        },
        {
            title: '365 day change of mind returns',
            icon: '365_day_returns'
        },
        {
            title: 'Express Click & Collect',
            icon: 'express_click_collect'
        },
        {
            title: 'Member Exclusives',
            icon: 'exclusive'
        },
    ]

    const brands = ['Kmart', 'Target', 'Catch', 'Bunnings', 'Officeworks', 'IS', 'Priceline']
    const partners = ['Flybuys']

    return <div className={cls("py-10 md:py-20 text-center bg-is-gray-20", !usr && "md:bg-white")}>
        <Container>
            {usr && <h2>Unlock more benefits from other OnePass brands</h2>}

            <div className="flex flex-wrap items-center justify-center gap-4 pb-6 md:py-8">
                <div className="flex flex-wrap md:flex-nowrap justify-center gap-2">
                    {brands.map((el, i) => { return <img src={`${op_img_path}/${el}.svg`} className="h-6 md:h-9 mx-2.5" key={i}/>})}
                </div>
                <div className="border-r-[0.5px] border-black opacity-50 h-9 mx-2 hidden md:block"></div>
                <div className="flex flex-wrap md:flex-nowrap items-center justify-center border-t-[.5px] border-op-purple md:border-none pt-4 md:pt-0 w-full md:w-auto">    
                    <h5 className="text-op-purple text-sm mb-0 mx-2.5">OnePass<br className="hidden md:block"/> Partners</h5> 
                    {partners.map((el, i) => {return <img src={`${op_img_path}/${el}.svg`} className="h-6 md:h-9 mx-2.5" key={i}/>})}
                </div>
            </div>

            {!usr && <h2 className="mt-0 mb-5">Get more value from your favourite brands.</h2>}

            <div className="w-full grid grid-cols-2 md:grid-cols-5 gap-2 md:gap-3 max-w-[1040px] mx-auto">
                {other_boxes.map((el, i) => {
                    return <div className="grid-col-2 bg-white rounded-md shadow p-4 flex flex-col items-start self-stretch text-left gap-1 min-h-[162px]" key={i}>
                        <img src={`${op_img_path}/${el.icon}.png`} className="h-12"/>
                        <p>{el.title}</p>
                    </div>
                })}
            </div>
            <p className="py-6 mb-0 font-bold md:font-normal"><a href="https://onepass.com.au/partners?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_partners" target="_blank" className="text-op-purple">Learn more</a> on what you get at OnePass brands</p>
            {usr ? <p className="text-sm text-is-gray-25">*<a href="#terms">T&Cs</a>, Exclusions apply. Express click & collect already available to all customers at Officeworks & Target. Alternative Flybuys offer for Catch.</p>
            : <p className="text-sm text-is-gray-25"><a className="font-bold" href="#terms">T&Cs</a>, Exclusions and eligibility criteria apply. Not all participating brands offer all member benefits. <a className="font-bold" href="https://onepass.com.au/partners?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_partners" target="_blank">Click here for benefits and exclusions for each participating brand.</a></p>}
        </Container>
    </div>
}

const Plan = () => {
    const benefits = ["Free delivery on eligible items or orders", "5x Flybuys points in-store", "365 day change of mind returns", "Express Click & Collect"]
            
    const tiles = [
        {
            intro: "Supercharge your shop with great benefits from our participating brands.",
            cost: <>$40<span className='text-lg'>/yr</span></>,
            trial: "30",
            trial_info: "30 day free trial",
            benefits: benefits,
            info:<>OnePass benefits*:</>,
            extra_title: "Annual plan benefits",
            extra: ["Get 12 months for the price of 10!"],
            btn:"https://my.onepass.com.au/auth/signup?onramp=onepass_annual?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_signup",
            save:<>Save $8 a year†</>
        },
        {
            intro: "Supercharge your shop with great benefits from our participating brands.",
            cost: <>$4<span className='text-lg'>/mo</span></>,
            trial: "30",
            trial_info:"30 day free trial",
            benefits: benefits,
            info:<>OnePass benefits*:</>,
            btn:"https://my.onepass.com.au/auth/signup?onramp=onepass_monthly?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_signup",
        }
    ]
    return <div className="py-16 bg-white md:bg-is-gray-20">
        <Container>
            <h2 className="text-center font-bold">Supercharge your shop with a plan for you</h2>
            <p className="text-center mb-0">Are you ready for more? Start your OnePass plan and get more of what you want, <br/>when you want it. With awesome value every step of the way.</p>
        
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-12 lg:w-2/3 mx-auto">
                {tiles.map((el, i) => {
                    return <div key={i} className="grid-col-1 relative flex flex-col h-full items-start justify-between rounded-2xl shadow-lg border border-is-gray-85 bg-white py-8 px-6">

                        {el?.save && <div className="bg-[#C3F7E6] px-2.5 py-1.5 rounded absolute -top-4 right-8 text-sm font-bold text-[#3A006A]">{el.save}</div>}
                        <div className="flex flex-col">
                            <div className="flex items-center h-12">
                                <img src={`${op_img_path}/onepass_icon_purple.svg`} className="h-6" alt="OnePass"/>
                                {el?.disney && <img src={`${op_img_path}/Disney.svg`} className="border-l border-solid border-black pl-5 ml-5 h-[60px]" alt="Disney"/>}
                            </div>
                            
                            <p className="py-6 border-b border-is-gray-85 mb-0">{el.intro}</p>

                            <div className="py-6 border-b border-is-gray-85 w-full">
                                <h2 className="font-bold my-0 text-[2.5rem]">{el.cost}</h2>
                                <h4 className="mt-2">{el.trial_info}</h4>
                            </div>

                            <div className="py-6">
                                {el.extra && <div className="pb-6">
                                    <h4 className="font-bold my-0">{el.extra_title}</h4>
                                    <ul className="py-2">
                                        {el?.extra?.map((info, i) => {
                                            return  <li className="flex pb-4 items-start" key={i}><img src={`${op_img_path}/check-circle_icon.svg`} className="mr-4" alt="tick"/> {info}</li>
                                        })}
                                    </ul>
                                </div>}
                                
                                <h4 className="font-bold my-0">{el.info}</h4>
                                <ul className="pt-2">
                                    {el?.benefits.map((info, i) => {
                                        return  <li className="flex pb-4 items-start" key={i}><img src={`${op_img_path}/check-circle_icon.svg`} className="mr-4" alt="tick"/> {info}</li>
                                    })}
                                </ul>
                                {el?.learn_more && <a className="font-bold underline flex items-center gap-1 hover:text-op-purple text-sm" target="_blank" href={el.learn_more}>{el?.lm_text} <img className="h-4 w-4" src={`${op_img_path}/external-link.svg`} alt="external"/></a>}
                            </div>
                        </div>

                        <Button className="!rounded-[8rem]" primary fluid as='a' href={el.btn} style={{background: '#9900F1'}}>Start {el.trial} day free trial</Button>
                    </div>
                })}
            </div>

            <p className="text-sm text-is-gray-90">
                {pricing_terms} See <a href="#terms">T&Cs</a> for full details.
            </p>
        </Container>
    </div>
}

const Supercharge = () => {
    return <div className="text-center text-white" style={{background: 'linear-gradient(352deg, #9900F1 93.87%, #6911B3 151.58%)'}}>
            <Container>
                <div className="flex flex-wrap md:flex-nowrap justify-between w-fit mx-auto pt-10 text-center px-4 md:px-0">
                    <div className="w-full md:w-[328px] md:py-10 lg:mr-[104px]">
                        <h2 className="font-bold mb-1"><span className="text-[#B0F4DE]">SUPERCHARGE</span><br/><span className="text-white">YOUR SHOP FOR $4/MONTH</span></h2>
                        <p className="mb-6">Your membership to more from Australia's favourite brands.</p>
                        <Button as="a" href="https://my.onepass.com.au/auth/signup?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_signup" fluid basic className="!text-base !py-4">Start your free trial</Button>
                        <p className="mt-6">Try it free, cancel anytime.</p>
                    </div>
                    <div className="flex overflow-hidden justify-center relative w-[95%] md:w-[371px] h-[200px] md:h-auto pt-4 md:pt-0 mx-auto md:mx-0">
                        <img src={`${op_img_path}/price_icon.svg`} className="block md:hidden h-[106px] absolute left-4 bottom-4" alt="Become a member $4 a month"/>
                        <img src={`${op_img_path}/free_trial_bird.png`} className="md:w-[371px] h-auto mx-auto md:mx-0 absolute -right-12" alt="OnePass bird"/>
                    </div>
                </div>
            </Container>
    </div>
}

const Terms = ({usr}) => {
    return <div className={cls(!usr && "hidden md:block", "bg-is-gray-10 py-24")} id="terms">
        <Container>
            <h5>Terms & Conditions</h5>
            {!usr &&
                <p className="text-sm mb-0 text-is-gray-90">
                    {pricing_terms}
                </p>
            }
            <>
                <p className="text-sm mb-0 text-is-gray-90">
                    For full OnePass terms and conditions, please refer to the <a href="https://onepass.com.au?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_terms" target="_blank" className="text-is-blue">OnePass Website.</a><br/><br/>
                </p>
                <p className="text-sm font-bold mb-0 text-black mb-4">InstantScripts and OnePass offer - terms and conditions</p>
                <ul className="text-sm list-disc list-inside text-is-gray-90">
                    <li>Free standard delivery applies to medication in Australia. Express delivery is not included in the offer.</li>
                    <li>5% off applies to InstantScripts online medical services including Online Prescriptions, Medical Certificates, Doctor Consultations, Blood Test Requests and Specialist Referrals.</li>
                    <li>Discount does not apply to the price of any medication, treatment plans, Mental Health Care Plan Consultations or Mental Health Care Plan reviews.</li>
                    <li>InstantScripts reserves the right to suspend, vary, alter or amend all or any part of these Terms and Conditions and the Offer from time to time.</li>
                    <li>The offer is only available to individuals that hold a Australian Government Individual Healthcare Identifier and situated in Australia at the time the services are provided.</li>
                    <li>InstantScripts may send marketing material to its members, who they have permission to do so, to tell them about the affiliation and offers.</li>
                    <li>OnePass may send marketing material to its members, who they have permission to do so, to tell them about the affiliation and offers.</li>
                    <li>The offer cannot be used in conjunction with any other discount offer.</li>
					<li>Delivery is available for prescription medicines obtained through the online prescriptions service and treatment plans only.</li>
                </ul>
            </>
        </Container>
    </div>
}

const OnePass = () => {

    const user_in = app?.user?.prof?.onepass
    
    const cust_supp = <>Contact our customer support team via the chat icon on our website or contact <a href="mailto:support@instantscripts.com.au">support@instantscripts.com.au</a>.</>

    const faq =
    [
        {
            title: 'What are the benefits of joining InstantScripts with OnePass?',
            content: <>OnePass members, including members who are on a free trial, are eligible for the following benefits at InstantScripts:
            <li>Free standard delivery<sup>#</sup> on prescription medication in Australia prescribed by an InstantScripts doctor</li>
            <li>5% discount on Eligible InstantScripts Services*</li><br/>
            <sup>#</sup>Standard delivery only, express delivery not eligible for free delivery.<br/><br/>
            *Eligible InstantScripts Services is limited to Online Prescription Consultations, Medical Certificates, Doctor Consultations, Blood Test Requests, Specialist Referrals.<br/><br/>
            To see full terms and conditions of the benefits <a href="#terms">click here</a>.</>
        },
        {
            title: 'How can I access the InstantScripts and OnePass Offer?',
            content: 'You will need to have an active OnePass membership or be currently on a free trial and link your OnePass and InstantScripts accounts in order to access your OnePass InstantScripts benefits. You can then login either via OnePass or InstantScripts.'
        },
        {
            title: 'Once I’ve signed up to a OnePass membership, how long does it take before I can access the Offer?',
            content: 'Benefits will be available at InstantScripts immediately upon linking your OnePass and InstantScripts account.'
        },
        {
            title: 'I cannot access my InstantScripts account using my OnePass email address and password. What should I do?',
            content: cust_supp
        },
        {
            title: 'My InstantScripts account failed to link to my OnePass from the OnePass website. What should I do?',
            content: <>Contact OnePass customer support team via 1300 026 710, or <a href="https://support.onepass.com.au/s/customer-support?utm_source=instantscripts&utm_medium=referral-web&utm_campaign=op-landingpage_support">visit</a> the OnePass contact us page for other contact options.</>
        },
        {
            title: 'Where can I get help?',
            content: cust_supp
        },
        {
            title: 'I was charged a fee for delivery on an item that was eligible for free delivery. What should I do?',
            content: cust_supp
        },
        {
            title: 'What locations does free delivery cover?',
            content: <>Prescription medications can be delivered Australia wide via the Australia Post network. If you are being charged a fee for delivery and believe you should have received free delivery contact <a href="mailto:support@instantscripts.com.au">support@instantscripts.com.au.</a></>
        },
        {
            title: 'What products are eligible for the 5% discount?',
            content: 'Products eligible for the 5% discount are limited to Online Prescription Consultations, Medical Certificates, Doctor Consultations, Blood Test Requests and Specialist Referrals. The discount does not apply to the price of prescription medicines, treatment plans, Mental Health Care Plan Consultations or Mental Health Care Plan reviews.'
        },
        {
            title: 'The discount was not applied to services that are eligible to receive the discount. What should I do?',
            content: cust_supp
        },
        {
            title: 'How is my personal information collected and shared?',
            content: <>OnePass and InstantScripts take your privacy seriously. When you link your InstantScripts and OnePass accounts, InstantScripts and OnePass will
            confirm you have an account with the other to enable your accounts to be linked and to enable you
            to receive your benefits and communications (e.g. free delivery and discounts). We don’t share the type or details of any doctor consultations, medical certificates, test results, referrals or script history.<br/><br/>
            Your personal information will be collected and handled in accordance with the InstantScripts <Link to="/privacy">Privacy Policy</Link>.</>
        },
        {
            title: 'Now that the OnePass Disney+ Premium Bundle is no longer available, what happens to my subscription?',
            content: <>The OnePass | Disney+ Premium Bundle will no longer available to existing subscribers from the 29th June 2024. To view all FAQs related to the Bundle no longer being available please refer to <a target="_blank" href="https://support.onepass.com.au/s">support.onepass.com.au/s</a>.</>
        },
    ]

	return  <div className='one-pass bg-white'>
            
            <Hero usr={user_in}/>

            <Intro usr={user_in} />
	        
            {user_in && <Benefits/> }

            {!user_in && <>
                <Other usr={user_in}/>
                <Plan />
            </>}

            <FAQ data={faq} readmore={false} clsn={user_in ? "bg-white" : "bg-is-gray-20 md:bg-is-gray-10"} faq_cls="bg-white"/>

            {user_in && <Other usr={user_in}/>}

            {!user_in && <Supercharge /> }

            <Terms usr={user_in}/>
	  
	        </div>
};

export default OnePass;
