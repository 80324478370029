import React, { useState } from 'react';
import {Popup, Button, Icon} from 'semantic-ui-react'
import EditScript from 'views/admin/scripts/EditScript'
import DocCons_req from 'views/doc/DocCons_req'
import script_model from 'models/script_model';


const Edit = ({row, onUpdate}) => {
	
	const [show, setShow] = useState(false)
	const [showCons, setShowCons] = useState(false)

	const canEditConsultDetails = ['noans_doccall', 'wrong_number', 'await_doccall'].includes(row.status) && (
		['subcons', 'doccons'].includes(row.script_type)
		|| script_model.is_express_consult(row)
		|| script_model.is_review_consult(row)
	);
	
	return <>
				<Popup
						trigger={<Button 
								icon='edit'
								onClick={ _=> setShow({edit_win:!show}) }
								color={show&&'red'||undefined}
							/>}
						open = {!!show}
						position='left center'
						// on='click'
						style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll'}}
				>
					<Popup.Header>
						<Icon 
								link 
								name='close' 
								style={{float:'right'}} 
								onClick={_=>setShow(false)} 
							/>
					</Popup.Header>
					<EditScript 
							scr_data={row} 
							onChange={onUpdate}
						/>
				</Popup>

			{canEditConsultDetails &&
				<>
				<br />
				<br />

				<Popup
						trigger={<Button 
								icon='doctor'
								onClick={ _=> setShowCons({edit_win:!showCons}) }
								color={'blue'}
							/>}
						open = {!!showCons}
						position='left center'
						// on='click'
						style={{height: '80%', width: '80%', minWidth: 700, overflow: 'scroll'}}
				>
					<Popup.Header>
						<Icon 
								link 
								name='close' 
								style={{float:'right'}} 
								onClick={_=>setShowCons(false)} 
							/>
					</Popup.Header>
					<DocCons_req
							embedded
							sid={row.sid} 
							mobilehash={row.spd_hash__mobile}
						/>
				</Popup>
				</>
			}
		</>
};

export default Edit
