import React, {useState, useEffect, useCallback} from "react";
import script_model from "models/script_model";
import {Button, Icon, Label, Message, Modal} from "semantic-ui-react";
import UniFormMed from "views/med/UniFormMed";
import UniFieldSUI from "xAppLib/UniForm/UniField-SUI";
import logger from "../../../../xAppLib/libs/logger";
import org_model from 'models/org_model';
import localstorage_database from "../../../../xAppLib/providers/localstorage_database";
import {Tel} from "../../../UIelems/Tel";
import {useCurrent} from "../../../../xAppLib/Hooks/useCurrent";

const InAppVideoNotice = () => (
	<>
		<p>Video calls to our doctors are now integrated into InstantScripts for cosmetic prescriptions.</p>
		<p>
			<a href="https://docs.google.com/document/d/1zyfycZUeqVD32Tu_Eh_343ijz8yVgXFk1lqCBsf9M8g"
			   target='_blank' rel='noopener noreferrer' className="underline text-blue-600">
				Read more about the new process and ensure your device is correctly set up.
				{' '}<Icon name="external alternate" size="small"/>
			</a>
		</p>
		<p>
			If you need to talk to a doctor, please click 'Yes" below.<br/>
			When you submit your request, InstantScripts will connect you to the first available
			doctor using our integrated video call service.</p>
	</>
);
const SkypeFallbackNotice = () => (
	<p>Skype links to on-duty doctors will be available after submitting your treatment request.</p>
);
const UpcomingNonConsReviewNotice = () => (
	<>
		<p>
			In the coming weeks <em>all</em> cosmetic scripts will need to be reviewed and signed by a doctor before
			they can proceed, even if you have selected not to speak to a doctor.
		</p>
		<p>
			You will be advised when this change comes in to effect, and from that time must wait for doctor approval
			before proceeding.
		</p>
	</>
);
const NonConsReviewNotice = () => (
	<>
		<p>
			Please be aware <em>all</em> cosmetic scripts must now be reviewed and signed by a doctor before they can
			proceed, even if you have elected not to speak to a doctor.
		</p>
	</>
);

const CosmDoc = (props) => {
	const { Section } = props

	useEffect(() => {
		if (app.settings.is_COSM_INSTCONS_NEED) {
			localstorage_database.delete_record('last_skype_doc')
		}
	}, []);

	return (<>
		<UniFormMed
			{...props}
			fields={org_model.org_conf_itm(app.user.org, 'cosm_allw_nodoc') ? script_model.COMM_COSM_INSTCONS_FIELDS : script_model.COMM_COSM_INSTCONS_NONODOC_FIELDS}
			section="cosmdoc"
		>
			{(values, valids, uf_this, fields) => {
				const args = {values, valids, uf_this}
				const cosm_email = "assist@instantcosmetics.com.au";
				return <Section>
					<Section.Header ><Label color='green'>NEW</Label> Need to talk to a doctor?</Section.Header>
					<div className='py-4 pl-4 mb-4 bg-is-blue-20  text-xl flex'>
						<Icon name='info circle' color="blue" style={{fontSize: '0.8em', marginRight:'1rem'}} />
						<div>
							{(() => {
								return false;
								if (app.settings.is_COSM_NONCONS_REVIEW_UPCOMING)
									return <UpcomingNonConsReviewNotice/>;
								if (app.settings.is_COSM_NONCONS_REVIEW)
									return <NonConsReviewNotice/>;
								if (app.settings.is_COSM_INSTCONS_ENABLED)
									return <InAppVideoNotice/>;
								return <SkypeFallbackNotice/>
							})()}
							<p>PLEASE NOTE: As of 1st July there is an AHPRA requirement for each of your patients to have a BDD Consultation. You will not be able to script your patient if they have not completed this.</p>
							<p>If you have any questions, please call our Customer Support team on <Tel value={app.site_status.cosm_supp_ph} /> - <a className="underline" href={`mailto:${cosm_email}`}>{cosm_email}</a>.</p>
						</div>
					</div>
					<Section.Content>
						<UniFieldSUI fl={fields.cosm_doc} {...args} />
					</Section.Content>
				</Section>;
			}}
		</UniFormMed>
		<br/>

		<ConfirmationModal
			enabled={props.formData?.cosm_doc === 'InstCons' && app.settings.is_COSM_INSTCONS_NEED}
			onConfirmed={() => {
				logger.usg_log(
					'CosmDoc', 'action', 'confirmInstCons',
					{ strategy : app.settings.is_COSM_WEBRTC_ENABLED ? 'webrtc': 'skype'}
				);
			}}>
			<p>Doctors must now approve treatment requests before the treatment can proceed.</p>
			{app.settings.is_COSM_WEBRTC_ENABLED
				? <>
					<p>
						You will be connected to the first available doctor for approval <em>after</em> filling out
						and submitting this form.
					</p>
					<Message warning size="small">
						<Icon name="exclamation triangle"/>
						Please keep the application open and screen unlocked until a doctor responds.
					</Message>
				</>
				: <p>
					Skype links to on-duty doctors will be available <em>after</em> submitting your treatment for
					review.
				</p>
			}
		</ConfirmationModal>

		<ConfirmationModal
			enabled={props.formData?.cosm_doc === 'No' && app.settings.is_COSM_NONCONS_REVIEW}
			onConfirmed={() => {
				logger.usg_log('CosmDoc', 'action', 'confirmNonConsReview');
			}}>
			<p>Doctors must now approve treatment requests before the treatment can proceed.</p>
			<p>
				The first available doctor will review your treatment <em>after</em> filling out and submitting this
				form.
			</p>
			<Message warning size="small">
				<Icon name="exclamation triangle"/>
				Please wait for doctor approval before continuing with the treatment
			</Message>
		</ConfirmationModal>
	</>);
};


function ConfirmationModal({
							   enabled,
							   onConfirmed,
							   title = 'Cosmetic treatment approval process',
							   children
}) {
	const [confirmed, setConfirmed] = useState(false);
	const onConfirmedRef = useCurrent(onConfirmed);
	const handleConfirmed = useCallback(() => {
		setConfirmed(true);
		onConfirmedRef.current?.();
	}, []);

	if (!enabled) {
		return null;
	}

	return (
		<Modal open={!confirmed}>
			<Modal.Header>{title}</Modal.Header>
			<Modal.Content>
				{children}
			</Modal.Content>
			<Modal.Actions>
				<Button
					content="I understand"
					labelPosition='right'
					icon='checkmark'
					onClick={handleConfirmed}
					positive
				/>
			</Modal.Actions>
		</Modal>
	);
}

export default CosmDoc;
