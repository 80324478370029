import { useHistory } from "xAppLib/Hooks";
import { useUser } from "xAppLib/Hooks";

export function useCannStatus() {
    const target = app.settings.is_cbls && '-cbls' || app.settings.is_cmg && '-cmg' || "";
    const [history] = useHistory()
    const user = useUser()
    const doc_req = history.find( s=> s.med_db_data.m == `DocConsICann${target}` && ["await_doccall","done_doccall","in_doccall",'noans_doccall','wrong_number'].includes(s.status))
	const has_doc = !!doc_req; 
	const pending_doc = ["await_doccall", "in_doccall", "noans_doccall",'wrong_number'].includes(doc_req?.status)
    const done_doc = doc_req?.status=="done_doccall"
	const prescs = app.user?.prof?.pts_prescs?.filter( p => p.type=="presc_icann" )
	const has_presc = prescs?.length > 0
    const has_ordered_med = history.find(s=>s.req_type=="icann" && ["medbuy","exprbuy","qualbuy"].includes(s.script_type))
    const user_in = user.user_in
	const step = [user_in,has_doc,has_presc,has_ordered_med].filter(Boolean).length + 1

    return {
        user_in,
        has_doc,
        pending_doc,
        done_doc,
        prescs,
        has_presc,
        has_ordered_med,
        step,
		can_proceed: app.settings.icann_avail || step > 2
	}
}
