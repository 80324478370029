import ga4 from "react-ga4";

import firebase_database from '../providers/firebase_database.js';
import API_service from '../providers/API_service'
import log_sentry from '../providers/log_sentry';
// import wicked_reports from '../providers/wicked_reports';
import moment from 'moment';
import UAParser from 'ua-parser-js';
const DEBUG = false;

let loclisten;

export default class logger {

// ---- --------------------------------------------  --------------------------------------------  
// ---- --------------------------------------------  --------------------------------------------  


// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	

	static init() {

		ga4.initialize(app.app_data.ws_conf.conf.ga4_mid);

		
		// const loclisten = browserHistory.listen( loc => this.usg_log('pageview', loc.pathname) )

		// console.log(this.context.router.location.pathname)
		// console.log(this.props.location.pathname)

		app.on(app.events.USER_UPD, this.user_updated.bind(this))

		// if (app.settings.is_dev && app.runtime.partn_type=="HE" && typeof _satellite !== 'undefined')
		// 	_satellite.setDebug(true);

	}
	

	static site_start() {
		// console.log('site_start', location.pathname)
		this.usg_log('site_start', location.pathname, {ld_tm: app.runtime?.perf?.ld_tm, init_tm: app.runtime?.perf?.init_tm, refr:document.referrer, ni:true})
	}

// ----	--------------------------------------------	--------------------------------------------	

// ----	--------------------------------------------	--------------------------------------------	

// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	

	static user_updated(v=null, s=null, p=null) {

		// console.log("logger :: user_updated ", v, s, p, app.user.uid );

		['login', 'check_user_state', 'AuthStateChanged'].includes(v) && this.usg_log('user', v, app.user.uid);

		if(['AuthStateChanged'].includes(v)){
			if(app.signup){
				ga4.set({ user_id: app.user.uid });
				// Sign ups
				// 
				// wicked_reports.send_user();
				// 
				this.log_ga4('sign_up');
				// 
				// Releasing memory
				app.signup = null;
			}
			else if(app.user.user_in){
				// Login
				ga4.set({ user_id: app.user.uid });
				this.log_ga4('login');
			}
			else if(!app.user.user_in){
				// Log out
				ga4.set({user_id: null});
			}
		}

		try {
			app.user.user_in && typeof ap3c !== "undefined" && ap3c?.track?.({v:0, ei:app.user.uid, email:app.user.prof.email || app.user.user_det.email, first:app.user.prof.first_name, last:app.user.prof.last_name});
		} catch (e) { console.warn('CATCH: in user_updated : ap3c'); }

		if (['AuthStateChanged'].includes(v) && app.runtime.partn_type=="HE" && typeof _satellite !== 'undefined')
			_satellite.track("logged-in-state-change", {loggedInState: app.user.user_in && "Logged In" || "Logged Out"})

	}

// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	

// ----	--------------------------------------------	--------------------------------------------	
// ----	--------------------------------------------	--------------------------------------------	

	static usg_log(a, pg=null, pr=null, d={}) {

		DEBUG && console.log("usg_log", a, pg, pr, d);

		this.log_ga4(a, d, pg, pr);
		// if (app.app_data.ws_conf.acts.frdb_lg && app.app_data.ws_conf.acts.frdb_lg.type=='fb_db') 
		// 	this.log_db(a, pg, pr);


		// if (app.app_data.ws_conf.acts.api_lg && app.app_data.ws_conf.acts.api_lg.type=='api_cl' || app.app_data.ws_conf.loc.SCRIPT__api_lg) 
			this.log_api(a, pg, pr, d);


	}

	static make_ga4_cart(items){
		let value = 0, cart = [];
		for (let k = 0; k < items.length; k++) {
			const j = items[k];
			value += j.price * j.quantity - (j.metric1 || 0);
			cart.push({
				item_id: j.id,
				item_name: j.name,
				currency: 'AUD',
				price: j.price,
				quantity: j.quantity,
				discount: j.metric1 || 0,
				coupon: j.coupon || '',
				item_brand: j.brand,
				item_category: j.category,
				script_type: j.dimension2, // script_type
				req_type: j.dimension3, // req_type
				item_category2: j.dimension2, // script_type
				item_category3: j.dimension3, // req_type
				item_category4: j.dimension4,
				item_list_id: j.brand,
				item_list_name: j.list
			});
		}
		return {
			currency: 'AUD',
			value: value,
			items: cart
		}
	}

	static log_ga4(event, data, route, ...props){
		try{
			if( ['user', 'ga.send_before-sending-error'].includes(event) || (event.includes('error') && (data.i === 'ga4' || !data?.res?.err_type)) )
				return

			let events = [], payload = {};

			if(event === 'ga.query'){
				events = ['view_search_results'];
				payload = {
					input: data.catSearchInput,
					r_num: data.products // Number of results returned
				}
			}
			else if(event === 'ga.impressions'){
				events = ['view_item_list'];
				payload = {
					items: data.ecommerce.impressions.map(j => {
						return {
							item_id: j.id,
							item_name: j.name,
							index: j.position
						}
					})
				};
			}
			else if(event === 'ga.details'){
				events = ['view_item', 'begin_checkout'];
				payload = this.make_ga4_cart([data.ecommerce.detail.products]);
			}
			else if(event === 'ga.add_to_cart'){
				events = ['add_to_cart', 'add_shipping_info'];
				payload = this.make_ga4_cart([data.ecommerce.add.products]);
			}
			else if(event === 'ga.remove_from_cart'){
				events = ['remove_from_cart'];
				payload = this.make_ga4_cart([data.ecommerce.remove.products]);
			}
			else if(event.includes('ga.apply_discount_fail_')){
				events = ['apply_discount_fail'];
				payload = {coupon: event.split('apply_discount_fail_')[1]}
			}
			else if(event === 'ga.purchase'){
				events = ['purchase'];
				payload = this.make_ga4_cart(data.ecommerce.purchase.products);
				payload.iscrsid = data.ecommerce.purchase.actionField.id;
			}
			else if(event === 'login'){
				if(app.user){
					events = ['login'];
					payload = {provider: app.user.claims.firebase.sign_in_provider, acc_age: moment().diff(moment(app.user.prof.cre_tm), 'days'), sex: app.user.prof.sex, user_age: moment().diff(app.user.prof.dob, 'years')};
				}
			}
			else if(event.includes('error')){
				events = [event.split('error')[0].trim() || `${ data?.res?.err_type }_err`];
				let err_type = event === 'error' ? data?.res?.err_type || '' : event.includes('payment') || (data?.res?.err_type || '').includes('paym_err') ? 'paym_err' : event.includes('validation') ? 'val_err' : event.includes('unsuit_err') ? 'unsuit_err' : data?.res?.err_type === 'repeat' || data?.res?.err_type === 'flagged' ? `${data?.res?.err_type}_err` : '';
				payload = {err_type: err_type, err_msg: event === 'error' ? data?.res?.err_type || '' : event === 'error' ? data?.res?.err_type || '' : err_type === 'paym_err' ? event.includes('error - ') ? event.split('error - ')[1].trim() : data.res.desc : err_type === 'val_err' ? 'Incomplete form submission' : err_type === 'unsuit_err' ? 'Med unsuitable for user' : err_type === 'repeat_err' || err_type === 'flagged_err' ? data?.res?.err_type || '' : ''};
			}
			else if(event === 'MedScrReq' && `${props}` === 'form_errors'){
				events = ['checkout_form_error'];
				payload = {err_type: 'form_err', err_msg: 'Not suitable based on answers'}
			}
			else{
				events = [event];
				payload = data;
			}
			// event scoped params
			payload = {...payload, ...{partner: app.runtime.partn_oid, app_mode: app.runtime.app, route: window.location.pathname}}
			app.user.user_in && (payload = {...payload, ...{txn_count: app.user.prof.hist.length, acc_age_ev: moment().diff(moment(app.user.prof.cre_tm), 'days')}});
			
			events.length > 0 && events.map( ev => !ev.includes('undefined') && ga4.event(ev, payload) );
		}
		catch(e){
			data.i !== 'ga4' && this.report_error('GA4 Error', 'error', {e, i: 'ga4', data: data, event: event});
		}
		
	}

	//	------------------------	------------------------	------------------------

	static log_db(a, pg=null, p=null) {

		firebase_database.add_record(
			app.app_data.ws_conf.acts.frdb_lg.tb, 
			{
				a, pg, p,
				userAgent: window.navigator.userAgent,
				// mob: window.navigator.userAgent && window.navigator.userAgent.match(/mobi/i),
				// mob: window.navigator.userAgent.match(/mobi/i) || false,
				mob: window.navigator.userAgent.toLowerCase().includes("mobi"),
			},
			{
				timestamp: true
			}
		);

	}

	//	------------------------	------------------------	------------------------

	static log_api(act, pg=null, lb=null, d={}) {
		const p = (new UAParser(window.navigator.userAgent)).getResult();
		API_service.call_api(
						app.app_data.ws_conf.loc.API__lg.u, 
						// 'post_form_cred', 
						{
							a: act,
							p: pg,
							d: lb||'',
							data: d||{},
							e: {
									di: app.dvc&&app.dvc.dvcid || '',
									// org: app.dvc.org || '',
									doi: app.dvc.org && app.dvc.org.oid || '',
									don: app.dvc.org && app.dvc.org.name || '',
									dt: app.dvc.org && app.dvc.org.type || '',

									// pi: app.dvc&&app.dvc.data && app.dvc.data.pharm_id || '',
									// pn: app.dvc&&app.dvc.pharm_db&&app.dvc.pharm_db.name || '',

									ui: app.user.user_in&&app.user.user_det.uid || '',
									un: app.user.user_in && app.user.user_det.displayName || '',
									ue: app.user.user_in && app.user.user_det.email || '',

									uoi: app.user?.org?.oid,
									uon: app.user?.org?.name,

									roi: app.runtime?.org?.oid,
									ron: app.runtime?.org?.name,

									ab: __BUILD__,
								},
								_dvc: {...app.dvc, platform: p.os, browser: p.browser }, 
								_usr: { dva: Boolean(app.user?.prof?.spd_data?.dva), medi: Boolean(app.user?.prof?.spd_data?.medicare), conc: Boolean(app.user?.prof?.spd_data?.conc_card), atsi: app.user?.prof?.atsi, cc: app.user?.prof?.chro_cond, addr: {state: app.user?.prof?.full_address?.state, suburb: app.user?.prof?.full_address?.suburb, postcode: app.user?.prof?.full_address?.postcode}, ihi_verif: app.user?.prof?.ihi_ihirecordstatus, sex: app.user?.prof?.sex, provider: app.user?.user_det?.provider }
						},
						// r => console.log('log_api call res', r)
					)
	}

	//	------------------------	------------------------	------------------------
	//	------------------------	------------------------	------------------------

	static report_error(msg, lvl, xtr) {
		try{
			!['paym_errors', 'mcvr_errors', 'repeat', 'ihivr_errors'].includes(xtr?.res?.err_type) && !(xtr?.err || '').toLowerCase?.().includes('opening hours') && xtr?.name!=="ChunkLoadError" && log_sentry.report_msg(msg, lvl, xtr);
		}
		catch(e){
			log_sentry.report_msg('Logger report error logger:320', lvl, { msg: msg, xtr: xtr, e: e });
		}

		this.usg_log('error', msg, lvl, xtr);

	}

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

	static report_except(err, xtr) {

		log_sentry.report_exc(err, xtr);

		this.usg_log('catch', err.code||err.message||err, 'warning', xtr);

	}

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

	static log_med_req_suc(p) {

		// wicked_reports.send_order(p.sid, p.price, `${p.script_type}-${p.req_type}`)

		if (typeof pdst !== 'undefined')
			pdst('purchase', 
				{
					value: p.price,
					currency: "AUD",
					order_id: p.sid,
				});

		if (app.runtime.partn_type=="HE" && typeof _satellite !== 'undefined')
			_satellite.track("form-complete", {formName: `InstantScripts HealthEngine – ${p.script_type}-${p.req_type} - ${p.med_nm}`, formStepName: "Complete", formStepNo:"5", cost:p.price})

	}

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

	static log_MedReq_form_step(p) {

		const step_num = p.step=='enter'&&"1" || p.step=='leave'&&"0" || p.step=='form'&&"2" || p.step=='confirm'&&"3" || p.step=='submit'&&"4"

		app.runtime.partn_type=="HE" && typeof _satellite !== 'undefined' && console.log('log_MedReq_form_step', step_num, p.step, p);

		if (app.runtime.partn_type=="HE" && typeof _satellite !== 'undefined' /* && ['confirm', 'submit'].includes(p.step)*/)
			_satellite.track("form-trackStep", {formName: `InstantScripts HealthEngine – ${p.script_type}-${p.req_type} - ${p.med_nm}`, formStepName: "MedReq form "+p.step, formStepNo:step_num})

	}

	// ---- --------------------------------------------  --------------------------------------------  
	// ---- --------------------------------------------  --------------------------------------------  

}
