import React from "react";
import moment from "moment";
import { Header, Icon, List, Popup } from "semantic-ui-react";
import med_model from "../../models/med_model";
import group_by_val_obj from "../../xAppLib/helpers/group_by_val_obj";
import { useAsync } from "../../xAppLib/Hooks/useAsync";
import CopyField from "../../xAppLib/UIelems/CopyField";

/** @type {Record<Status, string>} */
const COLOURS = {
	current: "green",
	med_only: "orange",
	expired: "red",
	cons_only: "red",
}

/** @type {Record<Status, string>} */
const STATUS = {
	current: "Current",
	med_only: "Med Only",
	expired: "Expired",
	cons_only: "Async Declined",
}

export function MedName({med_id}) {
	const {data} = useAsync({
		immediate: med_id && med_id !== '*',
		fn: () => med_model.load_med(med_id)
	}, [med_id]);

	if (med_id === '*') {
		return <><b>All Meds</b></>;
	}

	if (data) {
		return <><b>{data.name}</b> <small>{data.size} {data.qnty}</small></>;
	}

	return med_id || 'Unknown Med'
}

function MedEnrollments({ qualcons }) {
	// We're only interested in showing meds the pt is currently enrolled to, and only the enrolment
	// for the most recent consult. But we want to display the enrolled meds in order and grouped by
	// the consults themselves. So first group by med and take the first consult (which are assumed
	// to be sorted newest to oldest), then flatten back and group by consult.
	const enrolments = qualcons.filter(x => x.qualification_status === 'current');
	const byMed = group_by_val_obj(enrolments, 'enrolled_med') || {};
	const mostRecentConsults = Object.values(byMed).map(consult => consult[0]);
	const byConsult = group_by_val_obj(mostRecentConsults, 'snum') || {};

	if (Object.keys(byConsult).length === 0) {
		return <p>No enrolled meds</p>;
	}

	return (
		<List divided relaxed style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
			{Object.entries(byConsult).sort(([x_snum], [y_snum]) => x_snum - y_snum).map(([snum, consults]) => (
				<List.Item key={snum}>
					<List.Header>
						<small className="font-normal">{moment(consults[0].tm).format('D/M/YY')}</small>
					</List.Header>

					{consults.sort((x, y) => x.enrolled_med.localeCompare(y.enrolled_med)).map(consult => (
						<List.Content key={consult.enrolled_med}>
							<List.Description>
								<MedName med_id={consult.enrolled_med} />
								{consult.legacy && <> <Icon name="pills" color="grey"/></>}
							</List.Description>
						</List.Content>
					))}

					<List.Description><CopyField val={consults[0].snum || consults[0].sid} /></List.Description>
				</List.Item>
			))}
		</List>
	);
}
/**
 *
 * @param {QualificationStatusIndicatorProps} props
 * @returns {JSX.Element}
 */
export function QualificationStatusIndicator({ qualcons }) {
	const byStatus = group_by_val_obj(qualcons, 'qualification_status') || {};
	const colour = byStatus.current ? COLOURS.current
		: byStatus.cons_only ? COLOURS.cons_only
		: byStatus.med_only ? COLOURS.med_only
		: COLOURS.expired;

	return (
		<Popup trigger={<Icon name="certificate" color={colour} />}
			   hoverable
			   content={
					<>
						<Header as={'h4'}>App Script Enrolment</Header>
						<MedEnrollments qualcons={qualcons} />
					</>
			   }
		/>
	);
}

/**
 * @typedef {Object} QualificationStatusIndicatorProps
 * @property {QualificationStatus} qualcons
 *
 * @typedef {Object} QualificationStatus
 * @property {string} sid - sid of the qualifying consult
 * @property {number=} snum - snum of the qualifying consult (optional for legacy reasons)
 * @property {Status} qualification_status - qualification status of the consult (only current means the pt can access asynchronous scripts)
 * @property {string} tm - ISO formatted date string of the consult
 */

/**
 * @typedef {"current" | "med_only" | "expired" | "cons_only"} Status
 */
