import React from 'react';
import { Icon, List } from "semantic-ui-react";
import { AnswerFlag } from "./AnswerFlag";

export function AnswersList({ answs, highlightAnswer = [], relaxed = false, ...listProps }) {
	const val = Object.keys(answs||{}).map(k => answs[k].a && (
		<List.Item key={k}><div>
			<span>{answs[k].q}</span>{" "}

			<b className="whitespace-nowrap" style={{ ...(highlightAnswer?.length > 0 && highlightAnswer.includes(answs[k].a) ? {background:'red',padding:'3px',color:'#fff'} : {}) }}>
				{answs[k].a}
				{['p', 'f'].includes(answs[k].r) && <> <Icon color={answs[k].r == 'p' && 'green' || 'red'} name={answs[k].r == 'p' && 'check' || 'close'}/> </>}
			</b>{" "}

			{!!answs[k].x && <><blockquote className="ml-4 my-2">{answs[k].x}</blockquote></>}

			{answs[k].f && <div className="mb-2 ml-2"><AnswerFlag flg_id={answs[k].f.f} /></div>}
		</div></List.Item>
	));

	return <List style={{maxHeight: 300, overflowY: 'auto'}} {...listProps} relaxed={relaxed} items={val} data-testid='list-answers'/>;
}
