import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import cat_model from 'models/cat_model';
import med_model from 'models/med_model';
import patho_model from 'models/patho_model';
import org_model from 'models/org_model';
import { useProfile } from 'xAppLib/Hooks';
import { calculate_types } from './utils';
import logger from 'xAppLib/libs/logger';
import { obj_map } from "../../xAppLib/helpers/obj_map";

const HOME_DELIVERY_ENABLED = true

const MedData = ({alt_cat, children, med_code, req_type : prop_req_type, script_type : prop_script_type, medbuy_only, free_consult, allow_set_usr, data}) => {
    const params = useParams()
    const [med,setMed] = useState(null)
    const [med_error,setMedError] = useState(null)
    const [patho_request,setPathoRequest] = useState(null)
    med_code ??= params.med_code
    medbuy_only ??= app.runtime.medbuy_only || med?.xtra?.medbuy_only
    free_consult ??= app.runtime.free_consult
    allow_set_usr ??= app.runtime.allow_set_usr
    const cat_nm = alt_cat?.replace(/^_/, '') || params.cat_nm || 'pharm';
    const cat_route = params.cat_route
    const sid = params.sid 

    let med_mb = app.app_data.ws_data?.cat_data?.['pharm']?.meds_mb_list?.[med?.m] || {}
    
    const set_data = useCallback(async  (d) => {

		// console.log('set_data', d, JSON.stringify(d.q['-LOIwYxLXa2mghaGtH2j']));
		// console.log('set_data', app.dvc.org.conf.extra_spec_Qs);
      
			if (!d) {
				setMedError(true)
				logger.report_error('ERROR Cannot load med', 'error', {med_code, cat_nm, cat_route});
				return
			}
            d.key = med_code
            if (d.items) {
                d = {...d,items: await med_model.items(d)}
            }
			if (!d.q) {
				// we now have consults with no questionnaire... but we'll still have to inject some common ones here
				d = {...d, q: {}}
			}

            //TODO from cart code, tidy up
            med_mb = app.app_data.ws_data?.cat_data?.['pharm']?.meds_mb_list?.[d.m] || {}
            d.can_delivery = cat_model.can_deliver(med_mb);
                                
        	const PARTN_PHARM = app.runtime.partn_pharm_oid || false

            const home_delivery_available = app.acl.app_use 
                                            && (!PARTN_PHARM || org_model.org_conf_itm(app.runtime.org, 'is_mb_disp')) 
                                            && HOME_DELIVERY_ENABLED 
                                            && d.can_delivery 
                                            && org_model.org_conf_itm(app.runtime.org, 'is_mb') 
                                            && !app.runtime.phapick_only 
                                            && !org_model.org_conf_itm(app.runtime.org, 'phapick_only')

            const [req_type, script_type] = calculate_types({values:{}, med_code, med_data:d, cat_nm, cat_route, prop_req_type, prop_script_type, PARTN_PHARM, home_delivery_available, medbuy_only })
            // const [req_type, script_type] = calculate_types({values:{}, med_code, med_data:d, medbuy_only, cat_nm, cat_route, prop_req_type,prop_script_type, PARTN_PHARM})
    
            if (script_type=='pathoreq') {
                setPathoRequest(await patho_model.request(d))
            }
            
            const extra_Qs = {
                    "cosm-vac":
                        {
                            "num": "",
                            "txt": "Have you had a COVID vaccine in the past 3 days or do you intend to have one in the next 3 days?",
                            // "l": "ASC-Temples/q",
                            "a":
                            {
                                "X-vac-yes":
                                {
                                    "txt": "Yes",
                                    "res": "f",
                                    "err_txt": "You must wait 3 days either side of your COVID vaccine before having this treatment",
                                    // "l": "ASC-Temples/q/ASC-Temples-yes/a",
                                },
                                "X-vac--no":
                                {
                                    "txt": "No",
                                    "res": "p",
                                    // "l": "ASC-Temples/q/ASC-Temples-no/a",
                                }
                            },
                        },
    
                    "ASC-Temples": 
                        {
                            "num": "",
                            "txt": "Do you intend today to inject filler into the patient's glabella or nose?",
                            // "l": "ASC-Temples/q",
                            "a":
                            {
                                "ASC-Temples-yes":
                                {
                                    "txt": "Yes",
                                    "res": "f",
                                    "err_txt": "ASC nurses should not be conducting treatments involving filler to nose or glabella",
                                    // "l": "ASC-Temples/q/ASC-Temples-yes/a",
                                },
                                "ASC-Temples-no":
                                {
                                    "txt": "No",
                                    "res": "p",
                                    // "l": "ASC-Temples/q/ASC-Temples-no/a",
                                }
                            },
                        },
    
                    "understand":
                        {
                            "num": "",
                            "txt": "Do you understand everything that is written above or do you require assistance or language interpretation?",
                            // "l": "ASC-Temples/q",
                            "a":
                            {
                                "understand-yes":
                                {
                                    "txt": "Yes, I understand everything",
                                    "res": "p",
                                    // "l": "ASC-Temples/q/ASC-Temples-yes/a",
                                },
                                "understand-no":
                                {
                                    "txt": "No, I need help",
                                    "res": "f",
                                    "err_txt": "Please request doctor consultation to discuss your needs",
                                    // "l": "ASC-Temples/q/ASC-Temples-no/a",
                                }
                            },
                        },
					
					"in_australia":
					{
						"num": "",
						"txt": "Are you currently located in Australia?",
						"a":
						{
							"in_australia-yes":
							{
								"txt": "Yes",
								"res": "p",
							},
							"in_australia-no":
							{
								"no_rc": true,
								"no_xc": true,
								"txt": "No",
								"res": "f",
								"err_txt": "Our doctors can only consult patients located in Australia.",
								// "l": "ASC-Temples/q/ASC-Temples-no/a",
							}
						},
					},

					"k10": {

					"k10_1":
						{
							"ord": "3",
							"txt": "During the last 30 days, about how often did you feel tired out for no good reason?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_2":
						{
							"ord": "4",
							"txt": "During the last 30 days, about how often did you feel nervous?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_3":
						{
							"ord": "5",
							"txt": "During the last 30 days, about how often did you feel so nervous that nothing could calm you down?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_4":
						{
							"ord": "6",
							"txt": "During the last 30 days, about how often did you feel hopeless?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_5":
						{
							"ord": "7",
							"txt": "During the last 30 days, about how often did you feel restless or fidgety?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_6":
						{
							"ord": "8",
							"txt": "During the last 30 days, about how often did you feel so restless you could not sit still?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_7":
						{
							"ord": "9",
							"txt": "During the last 30 days, about how often did you feel depressed?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_8":
						{
							"ord": "10",
							"txt": "During the last 30 days, about how often did you feel that everything was an effort?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_9":
						{
							"ord": "11",
							"txt": "During the last 30 days, about how often did you feel so sad that nothing could cheer you up?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					"k10_10":
						{
							"ord": "12",
							"txt": "During the last 30 days, about how often did you feel worthless?",
							"c": "a-5",
							"a":
							{
								"1":	{	"txt": "None of the time",	},
								"2":	{	"txt": "A little of the time",	},
								"3":	{	"txt": "Some of the time",	},
								"4":	{	"txt": "Most of the time",	},
								"5":	{	"txt": "All of the time",	},
							},
						},

					},

                }
    
            // if (["-LOIw2bbLtoWgsSjvbks", "-LOIycusPtvIVFCbWUQc"].includes(cat_route))
            //     d.q = { "cosm-vac": extra_Qs["cosm-vac"] , ...d.q }

			if (d?.xtra?.use_k10) {
				// Allows use_k10 to include config that will be applied to each k10 question
				// Useful to conditionally include k10 based on a previous answer, e.g. MHCP Review
				// (There isn't a UI to do this, the easiest way is to create a dummy question configured how you want,
				// then copy the config from the dummy question to the use_k10 config)
				d.q = { ...obj_map(extra_Qs["k10"], val => ({ ...val, ...d.xtra.use_k10 })), ...d.q }
			}


            // app.dvc.org&&app.dvc.org.conf&&app.dvc.org.conf.extra_spec_Qs&&app.dvc.org.conf.extra_spec_Qs.map( e => d.q = extra_Qs[e] && { [e]:extra_Qs[e], ...d.q } || d.q )
            script_type!='pthl-needlestick' && app.dvc.org && org_model.org_conf_arr(app.dvc.org, 'extra_spec_Qs').map( e => d.q = extra_Qs[e] && { [e]:extra_Qs[e], ...d.q } || d.q )
    
    
            if (!['doccons','subcons'].includes(script_type) && req_type!='paas')
                d.q = { ...d.q, "extra_Qs-understand": extra_Qs["understand"] }

			if (req_type!=='cosm') {
				d.q["extra_Qs-in_australia"] = extra_Qs["in_australia"];
			}

            if (d.q) Object.keys(d.q).map( qk => d.q[qk].ord=='hide' && delete d.q[qk] )

            setMed(d)


    },[med_code, medbuy_only, cat_nm, cat_route, prop_req_type,prop_script_type])

    useEffect(()=>{
		setMed(null)
        return med_code!='prescription' ? med_model.watch_med(med_code, set_data ) : setMed(app.app_data.ws_data?.cat_data?.[cat_nm]?.cat_tree_list?.[cat_route])
    },[med_code])
    


    return children?.({ 
        med_data:med || med_code === 'prescription' && {n: 'Online Prescription'}, 
        med_mb, 
        loading:!med && !med_error && med_code !== 'prescription', med_code, 
        cat_nm, cat_route, 
        prop_req_type, prop_script_type, 
        medbuy_only,
        free_consult,
        patho_request,
        allow_set_usr,
        dataProp : data,
        sid,
		med_error
    })
}
 
export default MedData;