import firebaseApp, { firebase_conf } from './firebase.js';
import { initializeAuth, 
		getAuth, 
		onIdTokenChanged, 
		signInWithCustomToken,
		signInWithCredential,
		signInWithEmailAndPassword,
		signInWithPopup, 
		setPersistence,
		browserLocalPersistence,
		browserSessionPersistence,
		sendPasswordResetEmail,
		updatePassword, 
		EmailAuthProvider, 
		reauthenticateWithCredential, 
		signOut, 
		GoogleAuthProvider,
		FacebookAuthProvider,
		OAuthProvider,
		sendEmailVerification,
		debugErrorMap,
		prodErrorMap,
		browserPopupRedirectResolver,
		indexedDBLocalPersistence,
		PhoneAuthProvider,
		RecaptchaVerifier, 
		getMultiFactorResolver,
		multiFactor,
		PhoneMultiFactorGenerator, 
        signInWithRedirect,
        getRedirectResult} from 'firebase/auth';

import moment from 'moment'

const DEBUG = false;

import { FirebaseX } from "@ionic-native/firebase-x";
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@ionic-native/sign-in-with-apple';

import orgs_model from 'models/orgs_model'
import user_model from 'models/user_model'

import logger from '../libs/logger'
import gtm from 'xAppLib/providers/gtm'

import AppUser from 'models/app_user';

function handle_mfa(err) {
	if (err.code === 'auth/multi-factor-auth-required') {
		if (app.settings.is_ionic && app.settings.is_ionic_ios) {
			alert("Two-factor authentication is not currently supported on iOS mobile app. Please sign in using our web app.")
			return true
		}
		var resolver = getMultiFactorResolver(auth, err);
		DEBUG && console.log("Got MFA err",err,resolver);
		return app.mfa_confirm(resolver)
	  } else {
		return false
	  }
	
}

function facebook_logout() {
	Facebook.getLoginStatus()
			.then(res => {
				res.status === 'connected' 
				&& Facebook.api(`/${res.authResponse.userID}/permissions`, [] , 'DELETE')
							.then(res => {
									console.log('Revoking Facebook Login', res)
									Facebook.logout()
											.then(res => console.log('Logged out Facebook!', res))
											.catch(e => console.log('Error logout from Facebook', e))
							}).catch(e => console.log('Error revoking', e))
			}).catch(e => console.log(e))
}

const authConfig = __IONIC__ ? {
	errorMap: debugErrorMap, 
	persistence : indexedDBLocalPersistence
} : {
	errorMap: debugErrorMap, 
	popupRedirectResolver: browserPopupRedirectResolver, 
	persistence : [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence]
}
const auth = initializeAuth(firebaseApp, authConfig ) ;

export default class firebase_auth_web {

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static init() {

		this.check_user()

		// app.on(app.events.USER_UPD, this.user_updated.bind(this))
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async check_user() {

		return new Promise( (resolve,reject) =>	
		
		onIdTokenChanged(auth, async user => {	

				DEBUG && console.log('firebase_auth :: check_user :: onIdTokenChanged', user /*user.ra,*/ /*JSON.stringify(user)*/);

				if (user) {
					
					// firebase
					// 	.auth()
					// 	.currentUser
					//app.user.user_obj != user &&
						user.getIdTokenResult()
							.then( async idTokenResult => {
								if (idTokenResult.token === app.user?.token) {

									app.user.refresh(user,idTokenResult)
									app.trigger(app.events.USER_UPD, 'AuthStateRefresh')
									return;

								}

								user.enrolledFactors = multiFactor(user).enrolledFactors
									DEBUG && console.log('firebase_auth :: check_user :: claims :', idTokenResult.claims, ' :: user :', user, user.uid /*idTokenResult.token,*/ /*JSON.stringify(idTokenResult),*/ );

									if (app.user.uid != user.uid) {
										app.user.cleanup()
										app.user = new AppUser()
										app.trigger( app.events.CLEAR_MESSAGE )	
									}
										
									await app.user.init(user, idTokenResult)
									
									DEBUG && console.log('app.user.prof', app.user.prof);
									
									app.trigger(app.events.USER_UPD, 'AuthStateChanged')

									if (document.location.pathname.startsWith('/login') || (document.location.pathname.startsWith('/navig') && !app.state.in_sgup_st2) ) {

										if (app.goto_after_login) {
											app.history.push(app.goto_after_login);
											app.goto_after_login = null;
										} else {
											app.history.push('/');
										}
									}
									
									resolve(user)
								})

							.catch( err => {
								/**
								 * Ignoring auth/user-token-expired https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
								 * 
								 * auth/user-token-expired - Thrown if the user's credential has expired. This could also be thrown if a user has been deleted. Prompting the user to sign in again should resolve this for either case.
								 */
								if(err.code !== 'auth/user-token-expired'){
									logger.report_except(err, {err, msg:'CATCH :: firebase_auth . user . getIdTokenResult'});
								}
									
								// Sentry.captureException(err);
								console.log('CATCH :: firebase_auth . user . getIdTokenResult', err);
								resolve(err.body && err.body.error || err.statusText || err)
							});

				} else {

					DEBUG && console.log('firebase_auth :: check_user :: NOT USER :');
					
					if (app.state.goback_token) {
						this.signInWithCustomToken(app.state.goback_token)
						app.state.goback_token=null
						return 
					}

					app.user.cleanup()
					app.user = new AppUser()
					await app.user.init()

					app.trigger(app.events.USER_UPD, 'AuthStateChanged')

					resolve()
	
				}

			}) )


	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static async frb_login (e, p, persist) {

		let ret
		try {
			await setPersistence(auth, persist && browserLocalPersistence || browserSessionPersistence)
			const user = await signInWithEmailAndPassword(auth, e.trim(), p)
			ret = true
		} catch (err) {
			if (!handle_mfa(err)) {
				console.log('Error logging in.', err);
				/**
				 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
				 */
				if(!['auth/user-not-found', 'auth/user-disabled', 'auth/invalid-email', 'auth/wrong-password'].includes(err.code)){
					logger.report_except(err, {err, msg:'CATCH :: firebase_auth . user . getIdTokenResult'});
				}
				ret = err
			} else {
				ret = true
			}
		}
		gtm.auth('login',{success:ret===true})
		return ret
	}
	
	static async signInWithCustomToken(token) {
		try {
			const user = await signInWithCustomToken(auth, token)
			return true
		} catch (err) {
			if(err.code !== 'auth/invalid-custom-token'){
				logger.report_except(err, {token, msg:`CATCH :: firebase_auth . signInWithCustomToken -- ${token} - ${err}`});
			}
			// Sentry.captureException(`CATCH in frb_login - ${err} -- ${e}:${p}`,{extra:{e,p}});
			console.log('Error logging in.', err)
			return err
		}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	// static async frb_signup (e, p, n) {

	// 	try {
	// 		const new_user = await firebase.auth().createUserWithEmailAndPassword(e, p)
	// 		const user = new_user.user
	// 		try {
	// 			await user.updateProfile({
	// 						displayName: n,
	// 					})
	// 		} catch (err) {
	// 			console.log("ERROR:: can't updateProfile", err);
	// 			Sentry.captureException(err)
	// 		}

	// 		const r = await user.sendEmailVerification()
	// 		console.log('User sendEmailVerification done ', e, n, r)
	// 		return true

	// 	} catch (err) {
	// 		Sentry.captureException(err,{extra:{e,p,n}})
	// 		console.log('Error signing up.', err)
	// 		return err
	// 	}

	// }

	static async frb_reset (e) {
		let ret
		try {
			const user = await sendPasswordResetEmail(auth, e.trim())
			ret = true
		} catch (err) {
			/**
			 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#sendpasswordresetemail
			 * 
			 */
			if(!['auth/user-not-found', 'auth/invalid-email'].includes(err.code)){
				logger.report_except(err, {e, msg:`CATCH :: firebase_auth . sendPasswordResetEmail - ${err} -- ${e}`});
			}

			// Sentry.captureException(err,{extra:{e}});
			console.log('Error sending reset instructions.', err)
			ret = err
		}

		gtm.auth('reset',{success:ret===true})
		
		return ret

		

	}
	
	static async frb_password (p) {
		console.log("frb_password(%o)",p)
		await updatePassword(auth.currentUser ,p)
	}
	
	static async frb_reauthenticate(p) {
		
		var user = auth.currentUser;
		var credential = EmailAuthProvider.credential(user.providerData[0].uid,p)
		console.log("credential %o",credential)
		await reauthenticateWithCredential(user, credential).catch(err => handle_mfa(err) || Promise.reject(err))


	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static logout () {

		signOut(auth).then(() => {

				gtm.auth('logout')

				app.history.push('/')
				
				// if (app.settings.is_ionic) {
				// 	FirebaseX?.signOutUser()
				// 	facebook_logout()
				// }

				// app.user.curr_user = null
				// app.trigger(app.events.user_upd, 'login', false)

				// this.setState({
				// 	init_loaded: true,
				// 	user: null,
				// 	user_det: null
				// })

			});

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	static g_login() {

		return new Promise((resolve)=>{

			if (app.settings.is_ionic) {
				FirebaseX.authenticateUserWithGoogle(firebase_conf.web_client_id,res=>{
					console.log({res});
					const { idToken } = res
					try{
						const credential = GoogleAuthProvider.credential(idToken);
						signInWithCredential(auth, credential)
							.then(result=>{
								console.log("Successfully signed in");
								resolve(true)
							}).catch(err=>{
								if (!handle_mfa(err)) {
									console.log("** google plus – failed %o",err);
									/**
									 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithcredential
									 */
									if( !['auth/account-exists-with-different-credential', 'auth/invalid-credential', 'auth/user-disabled', 'auth/user-not-found', 'auth/wrong-password', 'auth/invalid-verification-code', 'auth/invalid-verification-id'].includes(err.code) ){
										logger.report_except(err, {err, msg:`CATCH :: firebase_auth . g_login . ionic:357 - ${err.code}`});
									}
									resolve(err)
								} else {
									resolve(true)
								}
							})
					}
					catch(e){
						/**
						 * auth/argument-error https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error
						 * 
						 * This occurs when idToken is not available. https://sentry.io/organizations/instantscripts/issues/3514716082/?project=1286240&query=firebase_auth&statsPeriod=30d
						 */
						logger.report_except(e, {msg:`firebase_auth.g_login():339`, error: e});
						// Resolving promise with error
						resolve(e);
					}
				},err=>{
					if( (err.code && !['auth/account-exists-with-different-credential', 'auth/invalid-credential', 'auth/user-disabled', 'auth/user-not-found', 'auth/wrong-password', 'auth/invalid-verification-code', 'auth/invalid-verification-id'].includes(err.code)) || (typeof err === 'string' && !err.toLowerCase().includes('user canceled the sign-in flow')) ){
						logger.report_except(err, {err, msg:`CATCH :: firebase_auth . g_login . ionic:377 - ${err.code}`});
					}
					console.log("got error",err);
					// Resolving promise with error
					resolve(err);
				})
				
			} else {
				const g_auth_provider = new GoogleAuthProvider();
				g_auth_provider.addScope('profile');
				g_auth_provider.addScope('email');
				g_auth_provider.addScope('openid');
				
				signInWithPopup(auth, g_auth_provider)
					.then((result) => {
						console.log('google login result', result);
						const user = result.user;
						resolve(user)
					}).catch(err => {
						if (!handle_mfa(err)) {
							console.log("** signInWithPopup(firebase.auth.GoogleAuthProvider) – failed %o", err);
							if( !['auth/account-exists-with-different-credential', 'auth/cancelled-popup-request', 'auth/popup-blocked', 'auth/popup-closed-by-user'].includes(err.code) ){
								logger.report_except(err, {err, msg:`CATCH :: firebase_auth . g_login - ${err.code}`});
							}
							resolve(err)
						} else {
							resolve(true)
						}
						
					});
			
			}
		}).then(result=>{
			gtm.auth('g_login',{success:!(result instanceof Error)})
			return result
		})
		
		

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	static async fb_login() {

		return new Promise(resolve=>{
			if (app.settings.is_ionic) {
				console.log("** facebook – login")
				Facebook.login(['email'])
					.then((res) => {
						console.log('Logged into Facebook!', res)
						const { accessToken } = res.authResponse
						console.log("accessToken=%o",accessToken)
						var credential = FacebookAuthProvider.credential(accessToken);
						console.log("** facebook - credential=%o",credential)		
						return signInWithCredential(auth, credential).then((result) => {
							console.log('** facebook - result', result);
							resolve(result.user)
							
						}).catch(function(error) {
							facebook_logout()
							if (!handle_mfa(error)) {
								console.log("** facebook – got error "+error.toString());
								/**
								 * https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth
								 * 
								 * Not logging user errors like,
								 * 1. auth/account-exists-with-different-credential User logs in with same email on google & facebook
								 * 
								 * 
								 * Also ignoring auth/multi-factor-auth-required via !handle_mfa()
								 */
								if( (error.code && !['auth/account-exists-with-different-credential', 'auth/invalid-credential', 'auth/user-disabled', 'auth/user-not-found', 'auth/wrong-password', 'auth/invalid-verification-code', 'auth/invalid-verification-id'].includes(error.code)) || (typeof error === 'string' && !error.toLowerCase().includes('auth/account-exists-with-different-credential')) ){
									logger.report_except(error, {res, msg:`CATCH :: firebase_auth . fb_login - ${error}`});
								}
								resolve(error)
							} else {
								resolve(true)
							}
							// Sentry.captureException(error);
						})
					})
					.catch(e => {
						console.log('Error logging into Facebook', e)
						if( !['auth/account-exists-with-different-credential', 'auth/invalid-credential', 'auth/user-disabled', 'auth/user-not-found', 'auth/wrong-password', 'auth/invalid-verification-code', 'auth/invalid-verification-id'].includes(e.code) ){
							logger.report_except(e, {msg:`CATCH :: firebase_auth . fb_login - 'Error logging into Facebook' - ${e}`});
						}
						// Sentry.captureException(e);
						resolve(e)
					});
			  } else {
				const provider = new FacebookAuthProvider();
				// provider.addScope('profile');
				provider.addScope('email');
				
				signInWithPopup(auth, provider).then((result) => {
					// This gives you a Facebook Access Token.
					const token = FacebookAuthProvider.credentialFromResult(result).accessToken;
					// The signed-in user info.
					const user = result.user;
					
					resolve(user)

				}).catch(error=>{
					if (!handle_mfa(error)) {
						console.error(error)
						if( !['auth/account-exists-with-different-credential', 'auth/cancelled-popup-request', 'auth/popup-blocked', 'auth/popup-closed-by-user'].includes(error.code) ){
							logger.report_except(error, {error, msg:`CATCH :: firebase_auth . fb_login - ${error.code}`});
						}
						resolve(error)
					} else {
						resolve(true)
					}
				});
	
			  }
		}).then(result=>{
			gtm.auth('fb_login',{success:!(result instanceof Error)})
			return result
		})
		
		  
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------
	
	static async apple_login() {

		return new Promise(resolve=>{
			if (app.settings.is_ionic_ios) {
				SignInWithApple.signin({
					requestedScopes: [
							ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
							ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
					]
				})
				.then((res) => {
					// https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
					console.log('Send token to apple for verification: ' + res.identityToken);
					console.log(res);
					var provider = new OAuthProvider('apple.com');
					var credential = provider.credential({
						idToken: res.identityToken,
					});
					signInWithCredential(auth, credential).then((result) => {
						console.log('** apple results - result', result);
						resolve(result.user)
					}).catch(function(error) {
						if (!handle_mfa(error)) {
							console.log("** apple – got error "+error.toString())
							/**
							 * Ignoring MFA errors 
							 * auth/multi-factor-auth-required
							 */
							logger.report_except(error, {res, msg:`CATCH :: firebase_auth . apple_login - ${error}`});
							resolve(error)
						} else {
							resolve(true)
						}
					})
					
					
				})
				.catch((error) => {
					if (error.code == "1001")
						return null
					console.error(error);
					resolve(error)
				});
			} else {
				var provider = new OAuthProvider('apple.com')
				provider.addScope('email');
				provider.addScope('name');
				signInWithPopup(auth, provider).then(result => {
					const token = provider.credentialFromResult(auth, result).accessToken;
					const user = result.user;
					resolve(user)
				  })
				  .catch(function(error) {
					if (!handle_mfa(error)) {
						console.error(error);
						if( !['auth/account-exists-with-different-credential', 'auth/cancelled-popup-request', 'auth/popup-blocked', 'auth/popup-closed-by-user'].includes(error.code) ){
							logger.report_except(error, {error, msg:`CATCH :: firebase_auth . fb_login - ${error.code}`});
						}
						resolve(error)
					} else {
						resolve(true)
					}
				  });
			}
		}).then(result=>{
			gtm.auth('apple_login',{success:!(result instanceof Error)})
			return result
		})
		
		
		
	}

	static async onepass_login(redirect = false) {
		return new Promise(resolve => {
			if (app.settings.is_ionic) {
				console.log("** Onepass – login")
				// TODO https://ionic.io/docs/auth-connect/framework-guides/react
			} else {
				const provider = new OAuthProvider('oidc.onepass');
				provider.setCustomParameters({ audience: "https://secure.api.onepass.com.au" });
				const auth = getAuth();

                const handleOidcResult = async result => {
                    const { idToken } = OAuthProvider.credentialFromResult(result);
                    app.user.reload_profile({ onepass: idToken, redirect });
                    app.trigger(app.events.SHOW_POPUP, {
                        pt: "cx_txt", txt: '', pn: 'onepass-linked', noHeader: true, noCloseBtn: true, size: 'tiny'
                    })
                }

                redirect ? getRedirectResult(auth)
                        .then(result => result === null ? signInWithRedirect(auth, provider) : handleOidcResult(result))
                        .catch((error) => console.log(error)) 
                    : signInWithPopup(auth, provider)
					    .then(handleOidcResult)
                        .catch((error) => {
                            console.log(error);
                        });
			}
		}).then(result => {
			gtm.auth('onepass_login', { success: !(result instanceof Error) })
			return result
		})
	
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------


	static async send_verification_email() {
		
		return sendEmailVerification(auth.currentUser).then(_=>({res:'ok'})).catch(error=>({res:'err',err:error.message}))
		
	}

	static async reload() {
		if (!auth.currentUser)
			return null
		await auth.currentUser.reload()
		return auth.currentUser
	}


	static async verifyPhone(phoneInfoOptions, recaptcha) {
		const phoneAuthProvider = new PhoneAuthProvider(auth)
		const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha)
		return verificationId
								
	}

	static async initiatePhoneEnroll(phoneNumber, recaptcha) {
		const session = await multiFactor(auth.currentUser).getSession()
		var phoneInfoOptions = {
			phoneNumber,
			session
		};
		console.log({phoneInfoOptions});
		const verificationId = await this.verifyPhone(phoneInfoOptions, recaptcha)
		console.log({verificationId});
		return verificationId
	}

	static async verifyPhoneCode(resolver, verificationId, verificationCode) {
		var cred = PhoneAuthProvider.credential(verificationId, verificationCode);
		var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
		const userCredential = await resolver.resolveSignIn(multiFactorAssertion)
		return userCredential
	}

	static async enrollPhoneFactor(phoneNumber, verificationId,verificationCode) {
		const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
		var multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
		const result = await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'Phone number');
		return result
	}

	static async unenrollFactor(factor) {
		await multiFactor(auth.currentUser).unenroll(factor)
	}

	static enrolledFactors() {
		return auth.currentUser && multiFactor(auth.currentUser).enrolledFactors || [] 
	}

	static get_captcha(ref,config) {
		console.log("get_captcha",ref, config, auth)
		return new RecaptchaVerifier(ref, config, auth)
	}
	
}
