import React, { useState } from 'react';
import { Button, Card, Container, Form, Grid, Input, Loader } from 'semantic-ui-react';
import { useUser } from '../../xAppLib/Hooks';
import LoginBanner from '../../xAppLib/UIelems/LoginBanner';
import logger from 'xAppLib/libs/logger'
import { Results } from "./MedSearch.Results";
import cat_model from '../../models/cat_model';
import { useNavigate } from 'react-router-dom';

export default React.memo(function () {
    const { user_in } = useUser();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [didYouMean, setDidYouMean] = useState(null);
    const [prompt, setPrompt] = useState();
	const navigate = useNavigate();
    
    const prompts = { 
        RESTRICTED: <p data-testid="restricted-med-warning">InstantScripts does not prescribe this medication. Please visit your regular GP.</p>,
        NO_RESULTS: (
            <>
                <h3 className="mt-[2rem] text-is-black text-lg md:text-md text-center" data-testid="med-search-no-results">We couldn't find that medication</h3>
				<img src="/images/med-box-sample.png" style={{ margin: '0 auto', width: 220 }} />
                <h3 className="mt-[2rem] text-is-black text-lg md:text-md text-center mb-6 font-bold">
					If you have your medication packaging, please use this to spell the name exactly as it appears on the label
				</h3>
				<Card style={{ width: '80%', margin: '72px auto 0', padding: 16 }}>
					<Card.Content className='text-center'>
						<Card.Header>Still can't find your medication?</Card.Header>
						<Card.Description>
							<p>If you don't know the name of your medication or it cannot be found, you will need an initial Telehealth Consultation</p>
						</Card.Description>
						<Button primary style={{ marginTop: 16 }} onClick={() => navigate('/m/pharm/DocCons/DocConsCov')}>
							Request Consultation
						</Button>
					</Card.Content>
				</Card>
            </>
        ),
        WRONG: (
            <>
                <h3 className="mt-[2rem] text-is-black text-lg md:text-md text-center">Something went wrong</h3>
                <p>Please try again, if the problem continues contact support.</p>
            </>
        )
    };

    const getSuggestions = async search => {
        setResults([]);
        setPrompt('');
        setDidYouMean(null);
        setLoading(true);
        cat_model.med_search(search)
            .then((data) => {
                if (data.res === 'ok') {

                    if(data.restricted){
                        setPrompt(prompts.RESTRICTED);
                    } else {
                        setResults(data.results);
                        setDidYouMean({...data.didYouMean, alt_name: data.didYouMean?.results?.[0]?.alt_name});
                        setPrompt(data.results?.length ? '' : prompts.NO_RESULTS);
                    }
        
                } else {
                    setPrompt(prompts.WRONG)
                }
                logger.usg_log('med_search', window.location.pathname, search);
            })
            .catch((err) => {
                logger.usg_log('med_search', window.location.pathname, search, {err});
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const searchDisabled = search?.trim()?.length < 3 || loading;

    return  <>
                {user_in && <h4 className='p-0 mb-8 text-center'>Welcome to InstantScripts, <span className='capitalize'>{app.user.prof?.first_name?.toLowerCase()}</span></h4>}
                <h1 className='p-0 mb-3 text-center'>Search for your medication</h1>
                
                {(user_in || app.dvc?.org?.type === 'pharm') ? <div className="cat-search text-center mt-6 md:mt-0 max-w-[784px] mx-auto">
                    <Container>

                    <Form onSubmit={() => search.trim() && getSuggestions(search.trim().toLowerCase())}>
                        <div className="relative max-w-[620px] mx-auto">
                            <Input
                                value={search}
                                icon={
                                    <div className='search-icon'>{
                                        loading 
                                            ? <Loader size='tiny' active inline style={{ marginTop: "-4px" }} />
                                            : <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.59842 2.80002C4.94746 2.80002 2.79843 4.94906 2.79843 7.60002C2.79843 10.251 4.94746 12.4 7.59842 12.4C10.2494 12.4 12.3984 10.251 12.3984 7.60002C12.3984 4.94906 10.2494 2.80002 7.59842 2.80002ZM0.398438 7.60002C0.398438 3.62357 3.62198 0.400024 7.59842 0.400024C11.5749 0.400024 14.7984 3.62357 14.7984 7.60002C14.7984 9.15502 14.3054 10.5949 13.4673 11.7719L19.2469 17.5515C19.7155 18.0201 19.7155 18.7799 19.2469 19.2486C18.7783 19.7172 18.0185 19.7172 17.5499 19.2486L11.7702 13.4689C10.5933 14.3071 9.15341 14.8 7.59842 14.8C3.62198 14.8 0.398438 11.5765 0.398438 7.60002Z" fill="#111827" /></svg>
                                    }</div>
                                }
                                iconPosition='left'
                                data-testid='input-search'
                                className="text-is-black-50 input-search"
                                style={{ margin: '0 auto', minHeight: "44px", width: '100%', borderRadius: '32px' }}
                                placeholder='Enter your medication name'
                                onChange={({ nativeEvent: { target: { value } } }) => setSearch(value)}
                            />
                            <Button primary
								className="absolute right-1 bottom-1 top-1 !hidden sm:!block"
								style={{ borderRadius:'2.25rem', height:'36px', padding:'8px 16px' }}
								type="submit"
								data-testid="button-med-search"
                                disabled={searchDisabled}
                            >
							    Find medication
						    </Button>
                        </div>
						<Button primary
							className='sm:!hidden'
							style={{ borderRadius:'4px', height:'36px', padding:'8px 16px', width: '100%', marginTop: 16 }}
							type="submit"
							data-testid="button-med-search"
                            disabled={searchDisabled}
                        >
						    Find medication
						</Button>
                    </Form>

					{!didYouMean && <p className='p-0 mt-3 text-is-black-50 mb-0'>Medication should be entered exactly as it appears on the label.</p>}

                    {results?.length ? (
                        <div className="p-4" data-testid="med-search-results">
                            <Results results={results} />
                        </div>
                    ) : didYouMean?.target ? (
                        <div className='p-4'>
                            <div className="pt-4 pb-2">
                                <h4 className='text-is-black mb-4 text-left'>Did you mean</h4>
                            </div>
                            <Grid divided="vertically">
                                <Grid.Row style={{paddingVertical: 0}} verticalAlign='middle'>
                                    <Grid.Column data-testid="did-you-mean-label" tablet={10} computer={10} mobile={11} textAlign="left">
                                        <strong>{didYouMean.target}</strong><br/>
						                {didYouMean?.alt_name && `Generic name: ${didYouMean?.alt_name}`}
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} mobile={5} textAlign="right">
                                        <Button
                                            compact
                                            basic
                                            type="button"
                                            data-testid="did-you-mean-button"
                                            onClick={() => {
                                                setResults(didYouMean.results);
                                                setDidYouMean({ ...didYouMean, target: null });
                                                didYouMean.restricted && setPrompt(prompts.RESTRICTED);
                                                logger.usg_log('med_confirm', window.location.pathname, `${didYouMean.target}_${search}`);
                                            }}
                                        >Confirm</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    ) : didYouMean?.suggest && !results?.length ? (
                        <div className='p-4'>
                            <div className="pt-4">
                                <h4 className='text-is-black mb-4 text-left'>Same medication, another brand</h4>
                            </div>
                            <Grid divided="vertically">
                                <Grid.Row style={{paddingVertical: 0}} verticalAlign='middle'>
                                    <Grid.Column data-testid="did-you-mean-label" tablet={10} computer={10} mobile={11} textAlign="left">
                                        {didYouMean.suggest}
                                    </Grid.Column>
                                    <Grid.Column tablet={6} computer={6} mobile={5} textAlign="right">
                                        <Button
                                            compact
                                            basic
                                            type="button"
                                            onClick={() => {
                                                setResults(didYouMean.suggestedResults);
                                                setDidYouMean(null);
                                                didYouMean.restricted && setPrompt(prompts.RESTRICTED);
                                                logger.usg_log('med_confirm', window.location.pathname, `${didYouMean.target}_${search}`);
                                            }}
                                        >Confirm</Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    ) : (
                        prompt && <div className="pt-8">{prompt}</div>
                    )}
                    </Container>
                </div> :   
                <>
                    <p className='p-0 mt-0 text-is-black-50 mb-6 text-center'>Do you have an InstantScripts account?</p>
                    <LoginBanner type="btn" />
                </>}
            </>
});