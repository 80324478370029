import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, } from 'semantic-ui-react';

export const ModalBtns = ({ close, info, hdr_warn }) => {
    const navigate = useNavigate();
    return <div className="absolute bottom-0 shrink-0 flex justify-between items-center p-4 w-full border-t border-[#E6E6E6]">
        <Link data-testid = "link-back" className="text-is-blue underline" onClick={close}>Back to search</Link>
        <Button data-testid = "button-continue" primary className="button h-fit" onClick={() => {
            close();
            navigate(info?.m, { state: { search: info?.srch, hdr_warn } })
        }}
        >Continue</Button>
    </div>
}

const MedConsultInfo = ({ cost }) => {
    return <div className='flex flex-col bg-white'>
        <div className='bg-white pt-4 pb-[28px] lg:px-4 min-h-[280px]'>
            <p>This medication requires you to speak to one of our doctors to establish whether it is right for you.</p>
            <p>The cost for this consultation will be ${app.site_status?.prs_mc[cost]}.</p>
            <br />
            <p>Prescriptions will only be issued if deemed appropriate by our doctors.</p>
        </div>
    </div>
}

export default MedConsultInfo;

