import React, { useState } from 'react';
import {
		Button,
		Modal,
		Icon
	} from 'semantic-ui-react'

import user_model from 'models/user_model'
import API_service from 'xAppLib/providers/API_service'
import ShareDownloadButton from 'xAppLib/UIelems/ShareDownloadButton'
import {supportsPDFs} from 'xAppLib/helpers/pdf'
import {Email, SMS, Print, Status} from 'views/erx/Tools';

const PDFs = ({row, index}) => {
		const [document,setDocument] = useState(null)
		const [popup,setPopup] = useState(null)


		function openDocument(el) {
			el.preventDefault()
			const { ctrlKey:ctrl=false, altKey:alt=false, metaKey:meta=false, shiftKey:shift=false } = (el || {})
			const mod = {ctrl,alt,meta,shift};
			const url = new URL(this.href)
			Object.keys(mod).filter(k=>mod[k]).forEach(k=>url.searchParams.set(k,'y'))
			setDocument({title:this.title||this.content,url:url.toString()})
		}

		const url = (app.dev_env ? `https://egw-etp-int-qrcode-web-au-se.azurewebsites.net/Scripts/`: `https://ausscripts.erx.com.au/Scripts/`) + (row.epresc__scid||row.epresc?.scid)

			return	<div className='script-buttons'>

					{((row.epresc__scid && !row.epresc__escr )  || (row.epresc?.scid && !row.epresc?.escript))&& <>
						<p>Paper Script</p>
					</>}

					
					{(row.epresc__scid && row.epresc__escr  || row.epresc?.scid && row.epresc?.escript) && <Button 
																		icon='file'
																		content={`eRX #${(row.epresc__scid||row.epresc.scid).slice(-7)}`}
																 		title={url} 
																		as='a'
																		onClick={openDocument}
																 		href={url} 
																		target='escr'
																		compact
																		color="blue" basic
																		size='mini'/>
																}
					
					{!(row.epresc__scid && row.epresc__escr || row.epresc?.scid && row.epresc?.escript) && (row.is_script!==false && !["doccons"].includes(row.script_type)) && 
					<Button content="Script"
						icon='file'
						as='a'
						onClick={openDocument}
						href={API_service.API_BASE_URL+'viewScript?s='+row.sid+`${app.dev_env&&`&ad=_dev`||''}&ignoreUsedBlock=true`}
						target={'script'+index}
						compact
						labelPosition="left"

						size='mini'
					 />
					}

					

					{(row.epresc__scid && row.epresc__escr  || row.epresc?.scid && row.epresc?.escript) && (row.is_script!==false && !["doccons"].includes(row.script_type)) && 
					<>
						<Button 
							size='mini'
							labelPosition="left"
							icon
							onClick={() => setPopup('status')}
							compact>
							<Icon name="zoom" />
							Check Status
						</Button>
						<Button content="View eScript"
							icon='qrcode'
							as='a'
							onClick={openDocument}
							href={API_service.API_BASE_URL+'escripts/view/'+(row.epresc__scid || row.epresc?.scid)}
							target={'escript'+index}
							compact
							labelPosition="left"
							size='mini'
						/>
						{!( row.epresc?.item?.EmergencySupply || row.epresc?.send_to_pharmacy) && <>
							<Button
								size='mini'
								labelPosition="left"
								icon
								onClick={() => setPopup('email')}
								compact>
								<Icon name="mail" />
								Email eScript
							</Button>
							<Button
								size='mini'
								labelPosition="left"
								icon
								onClick={() => setPopup('sms')}
								compact>
								<Icon name="mobile" />
								SMS eScript
							</Button>
						</>}
						

						<Email script={row} open={popup=='email'} onClose={()=>setPopup()}/>
						<SMS script={row} open={popup=='sms'} onClose={()=>setPopup()}/>
						<Status script={row} open={popup=='status'} onClose={()=>setPopup()}/>
					
						<Button
							size='mini'
							labelPosition="left"
							icon
							onClick={() => setPopup('print')}
							compact>
							<Icon name="file" />
							Print Evidence
						</Button>
						 <Print script={row} open={popup=='print'} onClose={()=>setPopup()} ev/>
					</>


					}

					

					{(app.user.claims.admin||app.user.claims.supp) && row.paym?.charge &&
					<Button content="Invoice"
						icon='file alternate outline'
						as='a'
						labelPosition="left"
						onClick={openDocument}
						href={API_service.API_BASE_URL+'viewScript?s='+row.sid+`${app.dev_env&&`&ad=_dev`||''}&format=inv`}
						target={'inv'+index}
						compact
						size='mini'
					 />
					}

					{false && row.is_script && ['pharm', 'docscr', 'medcons', 'exprcons', 'qualcons'].includes(row.script_type) &&
						<Button
							content="PBS"
							icon='print'
							labelPosition="left"
							onClick={openDocument}
							as='a'
							href={API_service.API_BASE_URL+'viewScript?s='+row.sid+'&format=pbs'}
							target={'pbs_script'+index}
							compact

							size='mini' />
					}


					{/* {row.prescPDF && user_model.check_access('root') && 
						<Button
							content="PDF"
							icon='download'
							onClick={openDocument}
							as='a'
							href={API_service.API_BASE_URL+'showPDF?fn='+row.prescPDF}
							target={'file_script'+index}
							compact
							size='mini' />
					} */}
					
					{document &&  <Modal open={true} 
								onClose={_=>setDocument(null)}
								closeIcon>
									<Modal.Header style={{display:'flex'}}>
										<div>{document.title}
										</div>
										<Button size='small'
												as='a'
												style={{marginLeft:'auto'}} 
												href={document.url} 
												icon="external alternate"
												target='_blank' />
									</Modal.Header>
									<Modal.Content>
									<iframe src={!supportsPDFs() && `/pdf-view/viewer.html?file=`+encodeURIComponent(document.url) || document.url} style={{width:'100%',height:'70vh'}} frameBorder="0"></iframe>
									</Modal.Content>
									<Modal.Actions>
										<ShareDownloadButton url={document.url} filename={`InstantScripts ${document.title}.pdf`}/>
									</Modal.Actions>
								</Modal>}
						
					
				</div>
	}
;

export default PDFs
