import React, { Component } from 'react';

import { Modal, Icon, Button, Header } from 'semantic-ui-react'


class Confirm extends Component {
	
	state = {
		showConfirm:false
	}

	componentDidMount() {
		// console.log("componentDidMount()")
		app.on(app.events.CONFIRM, this.showConfirm)
	}

	componentWillUnmount() {
		app.off(app.events.CONFIRM, this.showConfirm)
	}
	
	showConfirm = ({title,content},callback) => {
		this.setState({title,content,showConfirm:true})
		this.callback = callback
	}
	
	confirm = (answer) => {
		this.setState({showConfirm:false})
		this.callback && this.callback(answer)
	}
	
	render() {
		let { title = 'Confirm Operation',
				content = 'Are you sure?'
			} = this.state

			if (typeof content === 'string')
				content = <p className="text-md">{content}</p>
		return (
			<Modal size='small'
				 open={this.state.showConfirm}
				>
			    <Modal.Header as='h1' content={title} />
			    <Modal.Content>
					{content}
			    </Modal.Content>
			    <Modal.Actions>
			      <Button basic color='red' size='large' onClick={_=>{this.confirm('no')}}>
			        <Icon name='remove' /> No
			      </Button>
			      <Button color='green' size='large' onClick={_=>{this.confirm('yes')}}>
			        <Icon name='checkmark' /> Yes
			      </Button>
			    </Modal.Actions>
			  </Modal>
		);
	}

}

export default Confirm;
