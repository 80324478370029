import React, { useState } from 'react';
import { Button, Container, Form, Input, Loader } from 'semantic-ui-react';
import { useUser } from '../../xAppLib/Hooks';
import LoginBanner from '../../xAppLib/UIelems/LoginBanner';
import logger from 'xAppLib/libs/logger'
import { Results } from "./MedSearch.Results";
import cat_model from '../../models/cat_model';
import { Link } from 'react-router-dom';
import FormDivider from '../../xAppLib/UIelems/FormDivider';

export default React.memo(function () {
    const { user_in } = useUser();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [didYouMean, setDidYouMean] = useState(null);
    const [prompt, setPrompt] = useState();
    const [showSearch, setShowSearch] = useState(true);
    const [hint, setHint] = useState(true);

    const restricted_meds = {
        black_list: {
            meds: ['valium', 'ozempic', 'duromine', 'diazepam', 'semaglutide', 'endone', 'panadeine forte', 'codeine', 'quetiapine', 'tramadol', 'seroquel', 'ritalin', 'tretinoin oral', 'phentermine', 'panadeine', 'oxycodone', 'vyvanse', 'lyrica', 'mersyndol', 'temazepam', 'xanax', 'olanzapine', 'prep', 'panadine forte', 'palexia', 'tret oral', 'pregabalin', 'rikodeine', 'dexamphetamine', 'modafinil', 'zopiclone', 'codine', 'accutane', 'duramine', 'nurofen plus', 'panadine', 'tapentadol', 'diazapam', 'depo', 'panadene forte',
                'stilnox', 'clonazepam', 'isotretinoin', 'norgesic', 'naltrexone', 'panadiene forte', 'wellbutrin', 'ativan', 'endona', 'oxazepam', 'dex', 'otodex', 'roaccutane', 'vape', 'metermine', 'oxy', 'mersyndol forte', 'concerta', 'reaptan 10/10', 'clomid', 'vallium', 'amitriptyline viatris', 'comfarol forte', 'oxycontin', 'restavit', 'orphenadrine', 'doxylamine', 'pseudoephedrine', 'palexia ir']
        },
        DocConsCov: {
            meds: ['amoxicillin', 'keflex', 'cephalexin', 'antibiotics', 'doxycycline', 'cefalexin', 'amoxycillin', 'amoxil', 'penicillin', 'antibiotic', 'prednisolone', 'metronidazole', 'azithromycin', 'augmentin', 'prednisone', 'amox', 'levothyroxine', 'bactrim', 'propranolol', 'endep', 'augmentin duo forte', 'nitrofurantoin', 'mupirocin', 'thyroxine', 'ceftriaxone', 'bactroban', 'redipred', 'amitriptyline', 'flagyl', 'clindamycin', 'paxlovid', 'ivermectin', 'fluconazole', 'primolut',
                'novorapid', 'acyclovir', 'insulin', 'ciprofloxacin', 'spironolactone', 'eutroxsig', 'keflex 500mg', 'sulfamethoxazole', 'chlorsig', 'famciclovir', 'tamiflu', 'amoxicillin 500mg', 'motilium', 'cefalexin sandoz', 'lamotrigine', 'domperidone', 'doxy', 'apo-cephalexin', 'fosfomycin', 'betamethasone', 'erythromycin', 'clonidine', 'augmentin duo', 'fluvoxamine', 'ibilex', 'cephalex', 'sifrol', 'levoxine', 'flucloxacillin', 'ibilex', 'cephalex', 'zyban', 'bupropion', 'aciclovir', 'elocon',
                'predmix', 'amoxicillin 500', 'oroxine', 'catapres', 'epipen', 'amoxicillan', 'arthrexin', 'clarithromycin', 'ceph', 'lamictal', 'ceflex', 'famvir', 'levothyroxine sodium', 'testosterone', 'terbinafine', 'asthma', 'eltroxin', 'tranexamic acid', 'phenoxymethylpenicillin', 'cefalexin 500mg', 'keppra', 'oseltamivir', 'cephalexin 500mg', 'kef', 'stromectol', 'curam duo forte', 'triprim', 'budesonide oral', 'dutasteride', 'flu', 'amoxy', 'kenacomb', 'staphylex', 'enstilar', 'solone',
                'flixotide junior', 'epilim', 'anti viral', 'indocid', 'eliquis', 'topiramate', 'primolut n', 'truvada', 'levetiracetam', 'anti biotics', 'thrush', 'minocycline', 'apo-amoxycillin', 'daivobet', 'xarelto', 'amoxi', 'anti biotic', 'tonsillitis', 'alphamox', 'hydrochloroquinone', 'propranolol 40mg', 'pramipexole', 'propanolol', 'zovirax', 'pred', 'monurol', 'cilicaine vk', 'cephlex', 'ibilex 500', 'elidel', 'amoxycillan', 'lithium', 'dexamethasone', 'aldactone', 'jardiamet', 'amiloxyn',
                'panafcortelone', 'acnatac', 'chloramphenicol', 'macrodantin', 'soolantra', 'roxithromycin', 'cefalex', 'amoxicilin', 'sodium valproate', 'cephlexin', 'tegretol', 'rozex', 'frusemide', 'amoxycilin', 'amoxicillian', 'amoxycillin sandoz', 'amoxcillin', 'letrozole', 'cilicaine', 'trelegy', 'betahistine', 'cef', 'keflex 500mg', 'jardiance', 'cephalosporin', 'alphamox 500', 'montelukast', 'luvox', 'amoxcil', 'optisulin', 'furosemide', 'akamin', 'molnupiravir', 'adapalene', 'mesalazine',
                'epinephrine', 'itraconazole', 'bactrim ds', 'trelegy ellipta'],
            pr: 'dc_std'
        },
        DocConsUTI: {
            meds: ['trimethoprim', 'uti', 'alprim', 'trimethoprim viatris', 'trimethoprim 300mg', 'trim', 'urinary tract infection', 'trimeth', 'macrobid', 'tri', 'trimethoprin'],
            pr: 'dc_uti_pr'
        },
        DocConsWL: { meds: ['saxenda', 'contrave', 'tirzepatide', 'weight loss'], pr: 'dc_wlinj_pr' },
        DocConsHairIni: { meds: ['minoxidil'], pr: 'dc_hair' },
        DocConsAntiVir: { meds: ['antiviral', 'lagevrio', 'covid antiviral'], pr: 'dc_antivir_pr' },
        DocConsICann: { meds: ['cannabis', 'thc', 'marijuana'], pr: 'dc_icann_pr' },
        DocConsSC: { meds: ['champix', 'varenicline'], pr: 'dc_std' },
        DocConsSTI: { meds: ['sti', 'chlamydia'], pr: 'dc_sti' },
        DocConsAcne: { meds: ['acne', 'oratane'], pr: 'dc_std' }
    };

    const hint_msg = <div className="flex lg:items-center bg-is-yellow py-3 px-4 lg:px-6 w-full mb-[24px] lg:min-h-[98px] mx-auto rounded text-is-black gap-4">
        <p className="text-left text-base mb-0"><b>Hint:</b> find your medication packaging and type the name.</p>
        <img className="h-full w-[110px]" src="https://storage.googleapis.com/instant-med-public/med-label.png" alt="med label" />
    </div>

    const prompts = {
        RESTRICTED: <><h4 data-testid="restricted-med-warning" className="text-left">We cannot prescribe that medication.</h4><p className="text-left">The medication you have requested cannot be prescribed through InstantScripts.</p><p className="text-left">Please speak with your regular GP.</p></>,
        CONSULT_ONLY: <>Consult</>,
        NO_RESULTS: (
            <>
                <h3 data-testid="med-search-no-results" className="mb-4 text-is-black-100 font-bold text-left">Have you spelt that right?</h3>
                <p className="mb-5 text-is-black text-left">If you believe your spelling is correct, begin a medication consultation for medications we may not have listed:</p>
                <Button as={Link} data-testid="button-doccons" primary className="mx-auto text-base lg:!text-[1.125rem] lg:!leading-[1.625rem]" to="/m/pharm/DocCons/DocConsCov" fluid onClick={() => { }}>Begin medication consultation</Button>
                <FormDivider> or </FormDivider>
                {hint_msg}
            </>
        ),
        WRONG: (
            <>
                <h3 className="mt-[2rem] text-is-black text-lg md:text-md text-center">Something went wrong</h3>
                <p>Please try again, if the problem continues contact support.</p>
            </>
        )
    };

    const getSuggestions = async search => {
        setResults([]);
        setPrompt('');
        setDidYouMean(null);
        setLoading(true);
        cat_model.med_search(search)
            .then((data) => {
                if (data.res === 'ok') {
                    setHint(false)
                    const cons = Object.keys(restricted_meds).find(key => restricted_meds[key]?.meds?.includes(search))

                    if (data.restricted || restricted_meds.black_list.meds.includes(search)) {
                        setPrompt(prompts.RESTRICTED);
                        setShowSearch(false);
                    }
                    else if (cons?.length) {
                        setResults([{
                            "name": search,
                            "m": `/m/pharm/DocCons/${cons}`,
                            "t": "con",
                            "price": restricted_meds[cons].pr
                        }])
                    }
                    else {
                        setResults(data.results);
                        setDidYouMean({...data.didYouMean, alt_name: data.didYouMean?.results?.[0]?.alt_name});
                        setPrompt(data.results?.length ? '' : prompts.NO_RESULTS);
                        if (!data.results?.length && !Object.keys(data?.didYouMean).length) setShowSearch(false);
                    }

                } else {
                    setPrompt(prompts.WRONG)
                    setShowSearch(false);
                }

                logger.usg_log('med_search', window.location.pathname, search);
            })
            .catch((err) => {
                logger.usg_log('med_search', window.location.pathname, search, { err });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return <>
        <header className={"bg-is-blue px-6 py-[40px] lg:pt-[56px] lg:pb-[52px] flex flex-col justify-between h-full"}>
            <Container>
                <h1 className="font-bold text-left lg:text-center !text-white mt-2 mb-[40px]">Search for your medication</h1>
            </Container>
        </header>

        {(user_in || app.dvc?.org?.type === 'pharm') ? <div className="cat-search text-center mt-10 max-w-[628px] mx-auto">
            <Container>

                {showSearch &&
                    <>
                        {hint && hint_msg}
                        <Form onSubmit={() => search.trim() && getSuggestions(search.trim().toLowerCase())}>
                            <div className="relative lg:w-[628px] mx-auto medsearch-test">
                                <Input
                                    value={search}
                                    icon={
                                        <div className='search-icon'>{
                                            loading
                                                ? <Loader size='tiny' active inline style={{ marginTop: "-4px" }} />
                                                :  <button type="submit" data-testid="button-med-search"><svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path d="M2.65173 11.5421C3.35502 13.1968 4.68687 14.5045 6.35431 15.1773C8.02174 15.8501 9.88816 15.833 11.543 15.1297C13.1978 14.4265 14.5055 13.0947 15.1783 11.4273C15.8512 9.7599 15.8341 7.89353 15.1308 6.23876C14.4275 4.58399 13.0956 3.27636 11.4282 2.60354C9.76077 1.93072 7.89435 1.94783 6.23952 2.65109C4.5847 3.35435 3.27703 4.68616 2.60419 6.35354C1.93135 8.02092 1.94845 9.88729 2.65173 11.5421V11.5421Z" stroke="#494E53" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /><path d="M13.6846 13.6836L18.9427 18.9423" stroke="#494E53" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></g></svg></button>

                                        }</div>
                                    }
                                    iconPosition='left'
                                    data-testid='input-search'
                                    className="text-is-black-50 input-search"
                                    style={{ margin: '0 auto', width: '100%', borderRadius: '32px' }}
                                    placeholder='Your medication name'
                                    onChange={({ nativeEvent: { target: { value } } }) => setSearch(value)}
                                />
                            </div>
                        </Form>
                    </>
                }

                {results?.length ? (
                    <div data-testid="med-search-results">
                        <Results results={results} medsearchExp srch={search} />
                        <Link className="ui button basic mx-auto w-full !mt-4 block text-center lg:!text-[1.125rem] lg:!leading-[1.625rem] !text-[#6E7377] !font-medium" to="/m/pharm/DocCons/DocConsCov">Not sure / medication isn’t listed</Link>
                    </div>
                ) : didYouMean?.target ? (
                    <div className="mt-4 rounded-lg border border-[#B0B0B0] divide-y divide-[#B0B0B0] my-4 text-black">
                        <div className="w-full p-3 flex flex-col lg:flex-row justify-between">
                            <p data-testid="did-you-mean-label" className="text-[#494E53] text-base text-left mb-6 lg:mb-0">Did you mean:<br className="lg:hidden" /> <strong>{didYouMean.target} {didYouMean.alt_name && `(${didYouMean.alt_name})`}</strong></p>
                            <Button
                                compact
                                basic
                                type="button"
                                data-testid="did-you-mean-button"
                                onClick={() => {
                                    setResults(didYouMean.results);
                                    setDidYouMean({ ...didYouMean, target: null });
                                    didYouMean.restricted && setPrompt(prompts.RESTRICTED);
                                    logger.usg_log('med_confirm', window.location.pathname, `${didYouMean.target}_${search}`);
                                }}
                            >Confirm</Button>
                        </div>
                    </div>

                ) : didYouMean?.suggest && !results?.length ? (
                    <div className="mt-4 rounded-lg border border-[#B0B0B0] divide-y divide-[#B0B0B0] my-4 text-black">
                        <div className="w-full p-3 flex flex-col lg:flex-row justify-between">
                            <p data-testid="did-you-mean-label" className="text-[#494E53] text-base text-left mb-6 lg:mb-0">Same medication another brand:<br/> <strong>{didYouMean.suggest}</strong></p>
                            <Button
                                compact
                                basic
                                type="button"
                                data-testid="did-you-mean-button"
                                onClick={() => {
                                    setResults(didYouMean.suggestedResults);
                                    setDidYouMean(null);
                                    didYouMean.restricted && setPrompt(prompts.RESTRICTED);
                                    logger.usg_log('med_confirm', window.location.pathname, `${didYouMean.target}_${search}`);
                                }}
                            >Confirm</Button>
                        </div>
                    </div>
                ) : (
                    prompt && <div className="lg:pt-8">
                        {prompt}
                        <Button data-testid="button-search-again" basic fluid className="mt-4 text-base lg:!text-[1.125rem] lg:!leading-[1.625rem] !text-[#6E7377] !font-medium" onClick={() => { setShowSearch(true); setHint(true); setPrompt(''); setSearch('') }}>Search again</Button>
                    </div>
                )}
            </Container>
        </div>
            :
            <>
                <p className='p-0 mt-12 text-is-black-50 mb-6 text-center'>Do you have an InstantScripts account?</p>
                <LoginBanner type="btn" />
            </>}

    </>

});