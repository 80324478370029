import React, { Component} from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet"

import { 
		Icon, 
		List, 
		Input, 
		Button, 
		Table, 
		Header, 
		Container, 
		Popup, 
		Loader, 
		Checkbox, 
		Modal,
		Accordion,
		 } from 'semantic-ui-react'


import UniForm from 'xAppLib/UniForm/UniForm'

import ShowUserName from 'xAppLib/UIelems/ShowUserName'

import TransList from 'views/trans/TransList'

import {ViewEditDets} from 'xAppLib/DataList'
import DataShow from 'xAppLib/DataTable/DataShow';

import API_service from 'xAppLib/providers/API_service'

import getNestedObject from 'xAppLib/helpers/getNestedObject'
import obj_filter_by_key from 'xAppLib/helpers/obj_filter_by_key'
import obj_sort_by_val from 'xAppLib/helpers/obj_sort_by_val'

import user_model from 'models/user_model'
import org_model from 'models/org_model'
import orgs_model from 'models/orgs_model'
import patients_model from 'models/patients_model'
import scripts_list_model from 'models/scripts_list_model'

import InnerScriptsList from 'views/scripts/modules/InnerScriptsList'
import Prescs from '../admin/prescs/Prescs'

import  JsonEditor  from 'xAppLib/UIelems/JsonEditor';


import Photos from 'xAppLib/Photos/Photos';
import Files from 'xAppLib/Files/Files';
import SendAttachments from 'xAppLib/Files/SendAttachments'
import Age from './Age'
import { isValidJSON } from '../../helpers/json_functions'
import GetMyHRPatientRecords from '../myhr/GetMyHRPatientRecords'
const phone_mask = '0x xxxx x';
const PTS_FLDS = {
					'first name':'spd_data.first_name', 
					'last name':'spd_data.last_name', 
					'dob':'spd_data.dob', 
					'sex':'spd_data.sex', 
					'email':'spd_data.email', 
					'mobile':'spd_data.mobile', 
					'medicare':'spd_data.medicare',
					'conc_card':'spd_data.conc_card',
					'address':'spd_data.address',
					'full_address':'spd_data.full_address',
					'atsi': 'spd_data.atsi',
					'emergency contact name': 'spd_data.em_con_name',
					'emergency contact mobile': 'spd_data.em_con_mobile',
					'relationship of emergency contact to patient': 'spd_data.em_con_rel',
				};

const SHOW_FLDS = {
					...PTS_FLDS,
					// 'org name':'org_data.name',
					'org name':'org__name',
					'user':'pts_uid', 
					// 'notes-':'meta.note', 
					'doc notes': {
							req:'meta.note',
							show:['add_tm', 'doc_db_data__name', 'meta.note']
						},
					// 'photos':'phts', 
				};

const WrapComponent = ({embedded, children}) => {
	return embedded ? <div>{children}</div> : 
						<Container className='wide-content' fluid>
							<Helmet>
								<title>Patient Details</title>
							</Helmet>
							{children}
						</Container>
}

const maskPhoneNumber = scr_list => scr_list.map(s => ({
	...s,
	...(s.spd_data?.mobile && ({
		spd_data: {
			...s.spd_data,
			mobile: phone_mask + String(s.spd_data.mobile).slice(-3)
		}
	})),
	...(s.form_data?.cons_phone && ({
		form_data: {
			...s.form_data,
			cons_phone: phone_mask + String(s.form_data.cons_phone).slice(-3)
		}
	}))
}));

export default class Patient extends Component {

	constructor (props) {
		super(props)

		user_model.limit_access('pts_view');

		this.state = {
			det_mode: 'view',
			mask_pts_phone: props.mask_pts_phone,
		}
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	componentDidMount () {

		this.load_rec()

	}

	// 		--------------------------------		--------------------------------		---------

	componentDidUpdate(prevProps, prevState) {
		const {by_id, by_ihi, by_uid} = this.props.match?.params||this.props

		const {by_id:prev_id, by_ihi:prev_ihi, by_uid:prev_uid} = prevProps.match?.params||prevProps

		if (by_id!=prev_id || by_ihi!=prev_ihi || by_uid!=prev_uid) {
			this.load_rec()
		}

		this.props.mask_pts_phone !== this.state.mask_pts_phone && this.setState({mask_pts_phone: this.props.mask_pts_phone})
	}

	// 		--------------------------------		--------------------------------		---------

	async load_rec (upd=false) {

		// const {oid, onum} = this.props.data
		const {by_par, show_list} = this.props.match?.params||this.props

		const data_hash_params = this.props?.match?.params?.data_hash && new URLSearchParams(atob(this.props.match.params.data_hash))

		const by_id = data_hash_params?.get('d') || this.props?.match?.params?.by_id || new URLSearchParams(window.location.search)?.get('d') || this.props?.by_id;
		const by_ihi = data_hash_params?.get('i') || this.props?.match?.params?.by_ihi || new URLSearchParams(window.location.search)?.get('i') || this.props?.by_ihi;
		const by_uid = data_hash_params?.get('u') || this.props?.match?.params?.by_uid || new URLSearchParams(window.location.search)?.get('u') || this.props?.by_uid;

		// console.log('Pt load', {by_par, by_id, by_ihi, by_uid});

		!upd && this.setState({
							det_mode: 'view',
							data_loaded: false,
					})

		// const org_data = await patients_model.get_pts({by_par, by_id});

		const result = await scripts_list_model.load_scripts_list({
																		...(show_list && {show_list}),
																		filters: {
																					// is_script: true,
																					pts_ui: by_id,
																					pts_ihi: by_ihi,
																					pts_uid: by_uid,
																				}, 
																		total_count: true,
																		// page, 
																		// pageSize, 
																		// limit: pageSize,
																		// offset: (page-1) * pageSize,
																		// get_stats: true
																	})


		this.props.onData?.(result?.listScripts ?? []);

		let pts_dets = {}									
		
		const keys = Object.keys(SHOW_FLDS)
		for (const s of result.listScripts) {
			for (const f of keys) {
				if (!pts_dets[f]) pts_dets[f] = {}

				// const o = Array.isArray(SHOW_FLDS[f]) && SHOW_FLDS[f].map( e => getNestedObject(s, e, '.') ).join(' - ')
				// 		|| typeof SHOW_FLDS[f] == 'object' && SHOW_FLDS[f].show && SHOW_FLDS[f].req && getNestedObject(s, SHOW_FLDS[f].req, '.') && SHOW_FLDS[f].show.map( e => getNestedObject(s, e, '.') ).join(' - ')
				// 		|| typeof SHOW_FLDS[f] == 'object' && JSON.stringify( DataShow.show_data_field(s, SHOW_FLDS[f], null, null, null, true) )
				// 		|| getNestedObject(s, SHOW_FLDS[f], '.');

				let o
				if (Array.isArray(SHOW_FLDS[f])) 
					o = SHOW_FLDS[f].map( e => getNestedObject(s, e, '.') ).join(' - ')

				else if (typeof SHOW_FLDS[f] == 'object' && SHOW_FLDS[f].show && SHOW_FLDS[f].req) 
					o = getNestedObject(s, SHOW_FLDS[f].req, '.') && SHOW_FLDS[f].show.map( e => getNestedObject(s, e, '.') ).join('   -   ')

				else if (typeof SHOW_FLDS[f] == 'object') 
					o = JSON.stringify( DataShow.show_data_field(s, SHOW_FLDS[f], null, null, null, true) )
				else 
					o = getNestedObject(s, SHOW_FLDS[f], '.');

				// f=='notes' && console.log(f, typeof SHOW_FLDS[f], o);
				// f=='notes' && console.log(f, pts_dets[f], o);
				f === 'atsi' && (o = user_model.get_atsi_label(o));
				o = typeof o === 'object' ? JSON.stringify(o) : o;
				pts_dets[f][o] = (pts_dets[f][o]||0)+1
			}
		}
		for (const f of keys) {
			pts_dets[f] = obj_sort_by_val ( obj_filter_by_key(pts_dets[f], (k => k&&k!='null') ), (a, b) => b*1-a*1 )
			// pts_dets[f] = obj_sort_by_val ( obj_filter_by_key(pts_dets[f], (k => k&&k!='null') ), (a, b) => b*1-a*1!=0 ? b*1-a*1 : b - a   )
		}

		// Don't do this:
		// Object.keys(SHOW_FLDS).map( f => pts_dets[f] = {} )
		// result.listScripts.map( s => Object.keys(SHOW_FLDS).map( f => pts_dets[f][ getNestedObject(s, SHOW_FLDS[f], '.') ] = (pts_dets[f][ getNestedObject(s, SHOW_FLDS[f], '.') ]||0) + 1 ) )
		// Object.keys(SHOW_FLDS).map( f => pts_dets[f] = obj_sort_by_val ( obj_filter_by_key(pts_dets[f], (k => k&&k!='null') ), (a, b) => b*1-a*1 ) )

		// console.table(result.listScripts);
		// console.log(pts_dets);

		// Always showing ATSI
		this.setState({
							data_loaded: true,
							scr_list: result && result.listScripts,
							pts_dets,
					})

		// console.log('org', org_data);

	}


	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	pts_presc_view_render() {
		if (!user_model.check_access('prescs')) {
			return null;
		}

		const data_hash_params = this.props?.match?.params?.data_hash && new URLSearchParams(atob(this.props.match.params.data_hash))

		const by_id = data_hash_params?.get('d') || this.props?.match?.params?.by_id || new URLSearchParams(window.location.search)?.get('d') || this.props?.by_id;
		const by_uid = data_hash_params?.get('u') || this.props?.match?.params?.by_uid || new URLSearchParams(window.location.search)?.get('u') || this.props?.by_uid;

		return <>
		<h3>Prescribed History</h3>
		<Prescs filters={{
							pts_ui: by_id,
							pts_uid: by_uid,
							}} 
			pageSize={10}
			embedded/>			
		</>
	}

	pts_scrs_view_render () {

		const { scr_list } = this.state

		// console.log('pts_scrs_view_render', org_db);

		return	<React.Fragment>

							<h3>Scripts History</h3>

							{scr_list && <InnerScriptsList 
									data={this.state.mask_pts_phone ? maskPhoneNumber(scr_list) : scr_list} 
									inverted={false}
									show_prescribe_again={this.props.show_prescribe_again}
									// from={} 
									// to={} 
									// otype={org_db.org.type}
								 />
							}

						</React.Fragment>

	}

	// 		--------------------------------		--------------------------------		---------

	pts_dets_view_render () {

		const { comparison_fields = {} } = this.props;

		const { pts_dets, scr_list } = this.state

		// console.log('pts_dets_view_render', pts_dets);
		// console.log('pts_dets_view_render', JSON.stringify(pts_dets));

		const pts_det_val = (k, v) => k=='user' && <ShowUserName uid = {v} 	noid	/> 
										|| (k === 'full_address' && isValidJSON(v) && JSON.parse(v).formatted)
										|| v

		const data_hash_params = this.props?.match?.params?.data_hash && new URLSearchParams(atob(this.props.match.params.data_hash))
		const by_uid = data_hash_params?.get('u') || this.props?.match?.params?.by_uid || new URLSearchParams(window.location.search)?.get('u') || this.props?.by_uid;							
		
		return	pts_dets && !!scr_list?.length &&
					<React.Fragment>
						<div className='flex flex-row justify-between'>
							<h4>Our history with { Object.keys(pts_dets['first name'])[0] } { Object.keys(pts_dets['last name'])[0] } <Age dob={Object.keys(pts_dets['dob'])[0]} /> </h4>
							<GetMyHRPatientRecords {...{by_uid}}/>
						</div>
						<h5 style={{marginTop:'.5em'}}>Details</h5>
							<Table fluid="true" data-testid="table-patient-history" striped padded>
								<Table.Body>
							{ 
								Object.keys(SHOW_FLDS)
									.map( (k, i) => Object.keys(pts_dets[k]).length>0 && <Table.Row
												key={'pts_det'+k} 
												className="field" 
												>
											<Table.Cell
												style={{textTransform:'capitalize' }}
												singleLine
												width={3} 
											 ><strong>{k}</strong></Table.Cell>

											{Object.keys(pts_dets[k]).length==1 && <Table.Cell
												style={{flex:13}}
											>
												{k === 'mobile' && this.state.mask_pts_phone ? phone_mask + String(pts_det_val( k, Object.keys(pts_dets[k])[0] )).slice(-3) : pts_det_val( k, Object.keys(pts_dets[k])[0] ) } {scr_list.length>Object.values(pts_dets[k])[0] && <React.Fragment>({ Object.values(pts_dets[k])[0] })</React.Fragment> }
												{	k === 'sex'
													&& comparison_fields.ihi_sex
													&& pts_dets.sex !== comparison_fields.ihi_sex
													&& (
														<div className="pt-2">
															<span
																className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-s font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20"
															>
																Our IHI records contain a different patient sex: "{comparison_fields.ihi_sex}"
															</span>
														</div>
													)
												}
											</Table.Cell>}
											{Object.keys(pts_dets[k]).length>1 && 
												<Table.Cell
												style={{flex:13}}
											>
												<ul className="ui list">
													{Object.keys(pts_dets[k]).map( v => <li value="-" key={k+v}>{k === 'mobile' && this.state.mask_pts_phone ? phone_mask+String(pts_det_val(k,v)).slice(-3) : pts_det_val(k,v)} {pts_dets[k][v]>1&&`(${pts_dets[k][v]})`}</li> )}
												</ul>
											</Table.Cell>}
										</Table.Row> 
									 )
							}

						</Table.Body>
						</Table>	
					</React.Fragment>

	}

	// 		--------------------------------		--------------------------------		---------

	handlePhotoAccordClick = (e, { index }) => {
		let { activeIndex=[] } = this.state
		if (activeIndex?.includes(index)) 
			activeIndex = activeIndex.filter(i => i !== index) 
		else
			activeIndex.push(index)

		this.setState({ activeIndex })
	}

	pts_phots_view_render () {

		const { scr_list, activeIndex=[] } = this.state

		// const notes_scrs = scr_list.filter( s => s.meta?.note )
		const photo_scrs = scr_list.filter( s => s.phts?.length )
		const attch_scrs = scr_list.filter( s => s.attch )

		return	<>
				{photo_scrs.length && <React.Fragment>

							<h4>Attached Photos</h4>

							{/*<Accordion
								defaultActiveIndex={[0]}
								panels={ photo_scrs.map( s=> ({
										key: `panel-${s.sid}`,
										title: s.tm,
										// title: <><Icon name='dropdown' />{s.tm}</>,
										content: <Photos
											size={'calc(100% / 8 - 2em)'}
											data = {s.phts || []}
											mode = 'view_only'
											 />,
									}) ) }
								exclusive={false}
								fluid
								styled
								 />*/}

							<Accordion
								exclusive={false}
								fluid
								styled
								 >
								{photo_scrs.map( (s, i) => <React.Fragment key={i}>
										<Accordion.Title
												active={activeIndex?.includes(i)}
												index={i}

												onClick={this.handlePhotoAccordClick}
												 >
											<Icon name='dropdown' />
											{s.add_tm}
										</Accordion.Title>
										<Accordion.Content active={activeIndex?.includes(i)}>
											<Photos
												size={'calc(100% / 8 - 2em)'}
												data = {s.phts || []}
												mode = 'view_only'
												 />
										</Accordion.Content>
									</React.Fragment>
									)
								}
							</Accordion>

						</React.Fragment>
						|| null
				}

				{attch_scrs.length && <React.Fragment>

							<h3>Attached Files</h3>

							<Accordion
								exclusive={false}
								fluid
								styled
								 >
								{attch_scrs.map( (s, i) => <React.Fragment key={i}>
										<Accordion.Title
												active={activeIndex?.includes(i)}
												index={i}

												onClick={this.handlePhotoAccordClick}
												 >
											<Icon name='dropdown' />
											{s.add_tm}
										</Accordion.Title>
										<Accordion.Content active={activeIndex?.includes(i)}>
											<Files
												size={'calc(100% / 8 - 2em)'}
												data = {s.attch || []}
												mode = 'view_only'
												scr_obj={s}
												 />

											<SendAttachments
												show_send_all={true}
												show_send_individual={true}
												scr_obj={s}
												hide_privy={true}
											/>

										</Accordion.Content>
									</React.Fragment>
									)
								}
							</Accordion>

						</React.Fragment>
						|| null
				}
			</>
	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	req_scr_ptn (t='') {

		// console.log('req_scr_ptn', this.form_data);
		const { pts_dets } = this.state
		const { full_address, ...flds } = Object.keys(PTS_FLDS);

		app.save_pt_form_data = {}
		Object.values(flds).map( f => f!== 'full_address' && (app.save_pt_form_data[f.replace(' ', '_')] = Object.keys(pts_dets[f])[0])  )

		// app.save_pt_form_data = {
		// 						'first_name' : Object.keys(pts_dets['first name'])[0], 
		// 						'last_name' : Object.keys(pts_dets['last name'])[0], 
		// 						'dob' : Object.keys(pts_dets['dob'])[0], 
		// 						'email' : Object.keys(pts_dets['email'])[0], 
		// 						'mobile' : Object.keys(pts_dets['mobile'])[0], 
		// 						'address' : Object.keys(pts_dets['address'])[0],
		// 					}

		clearTimeout(app.save_pt_form_data_tmr);
		app.save_pt_form_data_tmr = setTimeout(_=>delete app.save_pt_form_data, 3*60*1000)

		app.history.push(app.consts.routes.cat_route+'/' + org_model.org_conf_itm(app.user.org, 'meds'));
		// app.history.push('/cat'+t);

	}

	// 		--------------------------------		--------------------------------		---------
	
	pts_funcs_view_render () {

		return			<React.Fragment>

							<h3>Functions</h3>
							{user_model.check_access('pg_cat') &&
								<Button 
									icon='add' 
									content='Request new script for the patient'
									onClick={ _=> this.req_scr_ptn() }
								 />
							}

							{/*
							<br /><br />

							{user_model.check_access('pg_cat', '_cosm') &&
								<Button 
									icon='add' 
									content='Request new cosmetic script for the patient'
									onClick={ _=> this.req_scr_ptn('_cosm') }
								 />
							}
							*/}

						</React.Fragment>

	}

	// 		--------------------------------		--------------------------------		---------
	// 		--------------------------------		--------------------------------		---------

	render () {

		const { data_loaded } = this.state

		const { scr_list, pts_dets } = this.state

		const { hide_act_btns, embedded = false} = this.props
		

		return (
			<WrapComponent embedded={embedded}>
			

					{!data_loaded &&
						<h5>
							Loading Patient Data ...
						</h5>
					}

					{pts_dets && this.pts_dets_view_render()}

					{pts_dets && this.pts_phots_view_render()}

					{!hide_act_btns && pts_dets && this.pts_funcs_view_render()}

					{pts_dets && this.pts_presc_view_render()}

					{scr_list && this.pts_scrs_view_render()}

			
			</WrapComponent>
		)
	}

}
