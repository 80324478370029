import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Message, Form, Input, Divider } from 'semantic-ui-react'
import UserArea from '../Users/UserArea';

import navig_model from 'models/navig_model'
import gtm from '../providers/gtm';
import { cls } from '../../views/NUI/utils';

/*const steps = {
    "0start": {
        "Q": "What do you need today?",
        "sections": [
            {
                "A": [
                    {
                        "link": "true",
                        "text": "UTI",
                        "value": "/m/pharm/DocCons/DocConsUTI"
                    },
                    {
                        "text": "Mental Health/Depression/Anxiety",
                        "value": "mhc"
                    },
                    {
                        "link": "true",
                        "text": "Weight Loss",
                        "value": "/weight-loss"
                    },
                    {
                        "text": "Reflux/Heartburn",
                        "value": "condition"
                    },
                    {
                        "text": "High Blood Pressure",
                        "value": "condition"
                    },
                    {
                        "text": "Asthma",
                        "value": "condition"
                    },
                    {
                        "link": "true",
                        "text": "Other Conditions",
                        "value": "/c/pharm/"
                    }
                ],
                "title": "Everyday health:"
            },
            {
                "A": [
                    {
                        "text": "Contraception",
                        "value": "condition"
                    },
                    {
                        "text": "Erectile Dysfunction",
                        "value": "condition"
                    },
                    {
                        "link": "true",
                        "text": "STI Test",
                        "value": "/m/pharm/patho/-MPGYe8beUnVZZ3A-10-"
                    }
                ],
                "title": "Sexual health:"
            },
            {
                "A": [
                    {
                        "link": "true",
                        "text": "Medical Certificate",
                        "value": "/c/pharm/certs"
                    },
                    {
                        "link": "true",
                        "text": "Speak to a doctor",
                        "value": "/m/pharm/DocCons/DocConsCov"
                    },
                    {
                        "text": "Prescription",
                        "value": "condition"
                    },
                    {
                        "link": "true",
                        "text": "Blood test",
                        "value": "/c/pharm/patho"
                    },
                    {
                        "link": "true",
                        "text": "Specialist Referrals",
                        "value": "/c/pharm/express-specialist-referrals"
                    }
                ],
                "title": "Other services:"
            }
        ]
    },
    "condition": {
        "Q": "Do you know which medication you need?",
        "sections": [
            {
                "A": [
                    {
                        "text": "Yes",
                        "value": "medication"
                    },
                    {
                        "link": "true",
                        "text": "No, I need to speak to a doctor",
                        "value": "/m/pharm/DocCons/DocConsCov"
                    }
                ],
                "title": ""
            }
        ]
    },
    "medication": {
        "Q": "Are you currently taking this medication or have you taken it in the past?",
        "sections": [
            {
                "A": [
                    {
                        "link": "true",
                        "text": "Yes",
                        "value": "/m/pharm/online/prescription"
                    },
                    {
                        "link": "true",
                        "text": "No, I need to speak to a doctor",
                        "value": "/m/pharm/DocCons/DocConsCov"
                    }
                ],
                "title": ""
            }
        ]
    },
    "mhc": {
        "Q": "What Mental Health Care are you looking for?",
        "sections": [
            {
                "A": [
                    {
                        "link": "true",
                        "text": "Mental Health Care Plan",
                        "value": "/m/pharm/DocCons/DocConsMHCPCopay"
                    },
                    {
                        "link": "true",
                        "text": "A prescription",
                        "value": "/m/pharm/online/prescription"
                    },
                    {
                        "link": "true",
                        "text": "Not sure, speak to a doctor",
                        "value": "/m/pharm/DocCons/DocConsCov"
                    }
                ],
                "title": ""
            }
        ]
    }
}*/

//	------------------------	------------------------	------------------------

const ProdsNavig = () => {
	const [step, setStep] = useState('0start');
	const [name, setName] = useState();
	const [prev, setPrev] = useState([]);
	const [navig_data, setNavig_data] = useState();
	const [condName, setCondName] = useState();
	// const [srch, setSrch]= useState();

	const start = step === '0start', login = step === 'login', search = step === 'search', condition = step === 'condition';

	//	------------------------	------------------------	------------------------

	const handleClick = (val, l, n, s) => {
		const next = l && 'login' || s && 'search' || val;
		if(l) {
			app.goto_after_login = val;
			setName(n);
		}
		setPrev([...prev, step]);
		setStep(next);
		window.scrollTo(0, 0);
	}

	const handlePrev = () => {
		setStep(prev[prev.length -1]);
		setPrev(prev.slice(0, -1));
	}
	
	//	------------------------	------------------------	------------------------

	useEffect(()=>{
		const handKeyUp = (evt) => (evt.keyCode === 27) && app.history.push("/")
		window.addEventListener ("keyup", handKeyUp);
		return () => window.removeEventListener ("keyup", handKeyUp)
	},[])

	// 		--------------------------------		--------------------------------		---------

	useEffect(()=>{
		navig_model.watch_record( '', d => setNavig_data(d) )
		// setNavig_data(steps)
	},[])

	const displayBtn = (el, i) => {
		return <Button
				basic
				color='blue'
				as={el.ext && 'a' || (el.link || el.login && app.user?.user_in) && Link || ''}
				key={i+el.text}
				{...(el.ext ? {href: el.value} : {to: el.value})}
				onClick={() => {gtm.log('wizard', 'navig', el.text); start && setCondName(el.text); handleClick(el.value, el?.login, el?.name, el.search)}}
				className={`${step === el.value ? 'bg-is-blue-150' : 'bg-white'} hover:bg-is-blue-20 flex items-center cursor-pointer rounded-md text-is-blue text-center my-4 w-full break-words`}>
				{el?.text}
			</Button>
	}

	//	------------------------	------------------------	------------------------

	return <div className={`quiz-body bg-is-blue h-full w-full pb-24 flex flex-col justify-center`}>
		
		<Message className="quiz-header flex justify-between items-center py-1 px-2 h-auto"
			onDismiss={() => {app.history.push("/"); gtm.log('wizard', 'navig', 'close')}}
			header={<div className="flex items-center h-full">{!start && <Button className="!mt-0" onClick={() => handlePrev()}>Previous</Button>}</div>}
			/>

		<h1 className="text-white text-center !mb-0 md:mb-2">{navig_data?.[step]?.Q || ""} </h1>
		{login && <p className="text-white text-center mt-4 sm:mb-10 md:mb-0 mx-4 text-[1.37rem]">A user account is required to request a {name}</p>}

		<div className='quiz-content flex items-start justify-center min-h-[250px] sm:mt-12 mb-12 sm:mb-20'>
			<Form.Group widths='equal' className={`${search && 'lg:w-[1074px] mx-4 lg:mx-auto' || ' mx-4 sm:mx-auto', step == '0start' ? `grid grid-cols-1 lg:grid-cols-${navig_data?.[step]?.sections.length}` : "flex flex-col w-full"}`}>
			{   login && <UserArea quiz={true}/>
				||
					<>
						{navig_data?.[step]?.sections?.map((el, i) => 
							<div key={i+el?.title} className={cls("grid-col-1 flex flex-col md:max-w-[500px] mx-auto py-8 px-4 lg:pb-4 lg:pt-0 lg:px-8 w-full", navig_data?.[step]?.sections.length > 1 && "border-b lg:border-b-0 border-r-0 lg:border-r border-solid border-is-gray-50 last:border-0")}>
								<h3 className="text-white mt-0 mb-4 text-center empty:mb-0">{el?.title}</h3> 
								{el.A?.map((el, i) => displayBtn(el))}
							</div>
						)}
					</>
					
				}
			</Form.Group>
		</div>

		{!start && <button onClick={() => setStep('0start')} className="text-white text-center underline underline-offset-4">Start again</button>}

	</div>
}

export default ProdsNavig;



//	------------------------	------------------------	------------------------

	/*search && <Input
	value={srch||''}
	icon='search'  
	iconPosition='left'
	className='w-full lg:max-w-[1074px]'
	placeholder={'Search by medicine name…'}
	onChange={ (e, s) => setSrch(s.value)}
	/>*/